import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SVGIcon, cancelIcon, checkOutlineIcon, exclamationCircleIcon, filterClearIcon, xOutlineIcon } from '@progress/kendo-svg-icons';
import { ColumnMenuSettings, GridComponent, GridSize } from '@progress/kendo-angular-grid';
import { arrowRotateCwIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

import { QualityCheckDto } from 'app/_models/quality-check.model';
import { ButtonThemeColor } from '@progress/kendo-angular-buttons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { DateTimeService } from 'app/_services/date-time.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ApiQualityCheckService } from 'app/_services/StareApiMaintenance/quality-check.service';


@Component({
	selector: 'app-quality-check',
	templateUrl: './quality-check.component.html',
	styleUrls: ['./quality-check.component.scss'],
	standalone: false
})
export class QualityCheckComponent implements OnInit {


	public smallSize: GridSize = "small";
	public tableTasksDataRaw = [] as QualityCheckDto[];
	public tableTasksDataFiltered = [] as QualityCheckDto[];


	// ===================================================================

	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private dateTimeService: DateTimeService,
		private apiQualityCheckService: ApiQualityCheckService,
		private notificationService: WrapperNotificationService
	) { }

	ngOnInit(): void {
		this.translateService.get('APP.QUALITY_CHECK.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});

		this.loadTasks();
	}

	// == Resize the Grid ========================================================
	public pageHeight: number = window.innerHeight - 125;

	@HostListener("window:resize", ["$event"])
	onResize(): void {
		this.pageHeight = window.innerHeight - 125;

	}

	// == Load Grid Data ========================================================

	loadTasks(): void {
		this.finishedLoading = false;
		// this.tableSearch = '';
		this.apiQualityCheckService.getFromLastRun()
			.subscribe((response: QualityCheckDto[]) => {
				response.forEach((item: QualityCheckDto) => {
					if (item.createdDate) {
						//console.log(item.createdDate);
						item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate);

					}
				});
				this.tableTasksDataRaw = response;
				this.tableTasksDataFiltered = this.tableTasksDataRaw;
				this.finishedLoading = true;
			},
				(error: HttpErrorResponse) => {
					console.error(error);
				});
	}

	// == Grid Settings =================================================

	// Pagination 

	public pageSize = 20;



	public finishedLoading = false;

	// Icons
	public reloadSVG: SVGIcon = arrowRotateCwIcon;
	public updateSVG: SVGIcon = plusIcon;

	public filterClearSVG: SVGIcon = filterClearIcon;

	//Settings for individual columns 
	public menuSettings: ColumnMenuSettings = {
		lock: false,
		stick: false,
		view: 'tabbed',
		filter: true
	};

	// == Filter ======================================================================================================================================

	@ViewChild("grid", { static: false })
	grid!: GridComponent;



	public currentFilter: CompositeFilterDescriptor = {
		logic: "or",
		filters: [],
	}


	/** Filter all displayed columns */
	public onFilter(value: string): void {

		const filter: CompositeFilterDescriptor = {
			logic: "or",
			filters: [],
		}

		// Check if value is empty
		if (value == "") {

			filter.filters = [];
		} else {



			filter.filters.push({
				field: "id",
				operator: "contains",
				value: value,
			});
			filter.filters.push({
				field: "qualityCheckTypeDisplayName",
				operator: "contains",
				value: value,
			});
			filter.filters.push({
				field: "message",
				operator: "contains",
				value: value,
			});

		}

		//this.tableTasksDataFiltered = filterBy(this.tableTasksDataRaw, filter);
		this.currentFilter = filter;
	}

	removeFilter(): void {
		this.currentFilter = {
			logic: "or",
			filters: [],
		}
	}


	// == Quality Check Result Image ===============================

	// Default Quality Check Icon
	public qualityCheckButtonSVG: SVGIcon = cancelIcon;
	// Undefiniert und Nicht Anwendbar
	public undefinedQCButtonSVG: SVGIcon = cancelIcon;
	// Erfolgreich
	public okQCButtonSVG: SVGIcon = checkOutlineIcon;
	// Warnung
	public warningQCButtonSVG: SVGIcon = exclamationCircleIcon;
	// Fehler
	public errorQCButtonSVG: SVGIcon = xOutlineIcon;
	// Default Quality Check Icon Color
	public qualityCheckButtonColor: ButtonThemeColor = "light";

	getQualityCheckButtonSVG(result: number): SVGIcon {

		let output: SVGIcon = this.undefinedQCButtonSVG;
		switch (result) {
			case 0:
				output = this.undefinedQCButtonSVG;
				break;
			case 1:
				output = this.undefinedQCButtonSVG;
				break;
			case 2:
				output = this.okQCButtonSVG;
				break;
			case 3:
				output = this.warningQCButtonSVG;
				break;
			case 4:
				output = this.errorQCButtonSVG;
				break;
			default:
				output = this.undefinedQCButtonSVG;
				break;
		}
		return output;
	}

	getQualityCheckButtonColor(result: number): ButtonThemeColor {
		let output: ButtonThemeColor = "light";

		switch (result) {
			case 0:
				output = "light";
				break;
			case 1:
				output = "light";
				break;
			case 2:
				output = "success";
				break;
			case 3:
				output = "warning";
				break;
			case 4:
				output = "error";
				break;
			default:
				output = "light";
				break;
		}

		return output;
	}

	getQualityCheckTooltip(result: number): string {

		let output = "";
		switch (result) {
			case 0:
				this.translateService.get('APP.QUALITY_CHECK.STATES.UNDEFINED').subscribe((title: string) => {
					output = title;
				});
				break;
			case 1:
				this.translateService.get('APP.QUALITY_CHECK.STATES.NOT_APPLICABLE').subscribe((title: string) => {
					output = title;
				});
				break;
			case 2:
				this.translateService.get('APP.QUALITY_CHECK.STATES.OK').subscribe((title: string) => {
					output = title;
				});
				break;
			case 3:
				this.translateService.get('APP.QUALITY_CHECK.STATES.WARNING').subscribe((title: string) => {
					output = title;
				});
				break;
			case 4:
				this.translateService.get('APP.QUALITY_CHECK.STATES.ERROR').subscribe((title: string) => {
					output = title;
				});
				break;
			default:
				this.translateService.get('APP.QUALITY_CHECK.STATES.UNDEFINED').subscribe((title: string) => {
					output = title;
				});
				break;
		}
		return output;
	}


	// == Beauftragung einer Aktualisierung =====================================
	requestNewQualityCheck(): void {
		this.apiQualityCheckService.updateQualityCheck()
			.subscribe(() => {
				this.notificationService.showSuccess('Eine Aktualisierung des Qualitätschecks erfolgreich beauftragt. Die Aktualisierung wird einige Minuten benötigen');
				this.finishedLoading = true;
			},
				(err: HttpErrorResponse) => {
					this.notificationService.showError('Eine Aktualisierung des Qualitätschecks fehlgeschlagen.');

					console.error(err);
				});
		// this.loadTasks();
	}

	// == ToolTips ===========================================================
	@ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

	public showTooltip(e: MouseEvent): void {

		const element = e.target as HTMLElement;
		if ((element.nodeName === 'TD' || element.className === 'k-column-title')
			&& element.offsetWidth < element.scrollWidth) {
			this.tooltipDir.toggle(element);
		} else {
			this.tooltipDir.hide();
		}
	}

}