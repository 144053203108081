import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SVGIcon, dataSqlIcon, filterClearIcon, gearIcon } from '@progress/kendo-svg-icons';
import { SortDescriptor } from "@progress/kendo-data-query";
import { process, State } from "@progress/kendo-data-query";
import { arrowRotateCwIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import {
	ColumnMenuSettings,
	NavigationRow,
	CellClickEvent,
	GridComponent,
	MultipleSortSettings,
	PageChangeEvent,
	GridSize,
	ColumnBase,

} from '@progress/kendo-angular-grid';
import { UserLocal } from 'app/_models/user.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { MyTaskItem } from 'app/_models/task.model';
import { WorkflowComment } from 'app/_models/workflow-comment.model';
import { formatDate } from '@angular/common';
import { GridSettings } from 'app/_models/kendo-grid-settings.model';
import { StatePersistingService } from 'app/_services/state-persisting.service';
import { ColumnSettings } from 'app/_models/kendo-column-settings.model';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { ListsService } from 'app/_services/lists.service';
import { DataTypeItem } from 'app/_models/data-type.model';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
	selector: 'app-archive',
	templateUrl: './archive.component.html',
	styleUrls: ['./archive.component.scss'],
	standalone: false
})
export class ArchiveComponent implements OnInit {

	//== Table Data ==============================================================================
	public tableTasksDataRaw = [] as MyTaskItem[];
	public tableTasksDataFiltered = [] as MyTaskItem[];
	public smallSize: GridSize = "small";
	public finishedLoading = false;
	public currentDateAsString = (new Date()).toISOString();
	public currentFilterVal: string = '';
	public datatypes = [] as DataTypeItem[];


	// == ToolTips ===========================================================
	@ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

	// == Base grid Settings for Persist State ===================================================
	public gridSettings: GridSettings<MyTaskItem> = {
		creationDate: new Date(this.currentDateAsString),
		items: 5000,
		backendFilterValue: null,
		state: {
			skip: 0,
			take: 20,
			sort: [
				{
					dir: "desc",
					field: "documentDate"
				}]
			,
			filter: {
				logic: 'and',
				filters: []
			},

		},
		gridData: process(this.tableTasksDataFiltered, {
			skip: 0,
			take: 20,
			filter: {
				logic: 'and',
				filters: []
			},
		}).data,
		columnsConfig: [
			{
				field: 'workflowId',
				title: "APP.TASKS.TABLE.HEADER.WORKFLOW_ID",
				titleToDisplay: '',
				filterable: true,
				filter: "text",
				format: "",
				hidden: false,

				sortable: true,
				width: 80,
				orderIndex: 1,

			},
			{
				field: 'filename',
				title: "APP.TASKS.TABLE.HEADER.FILE_NAME",
				filterable: true,
				filter: "text",
				format: "",
				hidden: false,

				sortable: true,
				width: 141,
				orderIndex: 2
			},
			{
				field: 'documentDate',
				title: 'APP.TASKS.TABLE.HEADER.DOCUMENT_DATE',
				filterable: true,

				sortable: true,
				titleToDisplay: '',
				filter: "date",
				format: "dd.MM.yyyy",
				width: 176,
				hidden: false,
				orderIndex: 3
			},
			{
				field: 'tradeTaxCompanyIdentifier',
				title: "APP.TASKS.TABLE.HEADER.COMPANY_IDENTIFIER",
				filterable: true,
				titleToDisplay: '',
				filter: "text",

				sortable: true,
				format: "",
				hidden: false,
				width: 173,
				orderIndex: 4
			},
			// {
			// 	field: 'deadlineNumber',
			// 	title: "APP.TASKS.TABLE.HEADER.DEADLINE_NUMBER",
			// 	filterable: true,
			// 	titleToDisplay: '',

			// 	sortable: true,
			// 	filter: "text",
			// 	format: "",
			// 	hidden: false,
			// 	width: 173,
			// 	orderIndex: 5
			// },

			{
				field: 'auditResultName',
				title: 'APP.ARCHIVE.TABLE.HEADER.AUDIT_RESULT',
				filterable: true,
				titleToDisplay: '',
				filter: "text",
				format: "{0:c}",

				sortable: true,
				width: 171,
				hidden: false,
				orderIndex: 5
			},
			{
				field: 'comment',
				title: "APP.TASKS.TABLE.HEADER.COMMENT",
				filterable: false,
				titleToDisplay: '',
				filter: "boolean",
				format: "",
				width: 157,
				hidden: false,
				sortable: false,
				orderIndex: 6,
			},

			{
				field: 'typeOfTaxAssessmentForDisplay',
				title: 'APP.TASKS.TABLE.HEADER.TYPE_OF_TAX_ASSESSMENT',
				filterable: true,
				filter: "text",

				sortable: true,
				hidden: true,
				width: 149,
				orderIndex: 7
			},
			{
				field: 'legalEntityName',
				title: "APP.TASKS.TABLE.HEADER.LEGAL_ENTITY_NAME",
				filterable: true,

				sortable: true,
				titleToDisplay: '',
				filter: "text",
				format: "{0:c}",
				hidden: true,
				width: 161,
				orderIndex: 8
			},
			{
				field: 'createDate',
				title: 'APP.TASKS.TABLE.HEADER.CREATE_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",

				sortable: true,
				format: "dd.MM.yyyy",
				hidden: true,
				width: 196,
				orderIndex: 9
			},
			{
				field: 'updateDate',
				title: 'APP.TASKS.TABLE.HEADER.UPDATE_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",

				sortable: true,
				format: "dd.MM.yyyy",
				width: 212,
				hidden: true,
				orderIndex: 10
			},
			{
				field: 'deadlineDate',
				title: 'APP.TASKS.TABLE.HEADER.DEADLINE_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",
				format: "dd.MM.yyyy",

				sortable: true,
				width: 212,
				hidden: true,
				orderIndex: 11
			},
			{
				field: 'commentsCount',
				title: 'APP.TASKS.TABLE.HEADER.COMMENTS_COUNT',
				filterable: false,
				titleToDisplay: '',
				filter: "text",

				sortable: true,
				format: "",
				hidden: true,
				width: 100,
				orderIndex: 12
			},

		]
	};

	// ========================================================================================
	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private dateTimeService: DateTimeService,
		private persistingService: StatePersistingService,
		private router: Router,
		private apiUIViewerService: UIViewerService,
		public tooltipService: TooltipService,
		public apiListsService: ListsService
	) {
		// For excel (not in use)
		//this.allData = this.allData.bind(this);

		this.persistingService.checkAndUpdateIfAvailable('archiveGridSettings', this.gridSettings);

		const gridSettings: GridSettings<MyTaskItem> =
			this.persistingService.get("archiveGridSettings");
		//after checkAndUpdateIfAvailable, gridSettings is never null
		this.gridSettings = this.mapGridSettings(gridSettings);
		// if (gridSettings !== null) {
		// 	this.gridSettings = this.mapGridSettings(gridSettings);
		// } else {
		// 	this.persistingService.set('archiveGridSettings', this.gridSettings);
		// }
		//Setting translated column headers

		this.gridSettings.columnsConfig.forEach(column => {

			// only if column.title not null and not empty
			if ((column.title) && (column.title !== '')) {

				this.translateService.get(column.title as string).subscribe((title: string) => {
					column.titleToDisplay = title;
				});
			}
		})
	}

	ngOnInit(): void {
		this.translateService.get('APP.ARCHIVE.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});
		this.gridSettings.columnsConfig.forEach(column => {

			// only if column.title not null and not empty
			if ((column.title) && (column.title !== '')) {

				this.translateService.get(column.title as string).subscribe((title: string) => {
					column.titleToDisplay = title;
				});
			}
		})
		this.items = this.gridSettings.items ? this.gridSettings.items : 5000;
		//to display label for assessment Type
		this.apiListsService.GetDataTypes().subscribe(res => this.datatypes = res);

		// BUG Fix 24084: filter value on page reload
		if (this.gridSettings.state.filter != undefined) {
			if ((<FilterDescriptor>(this.gridSettings.state.filter)?.filters[0]) != undefined) {
				const newVariable = (<FilterDescriptor>(this.gridSettings.state.filter)?.filters[0]).value;
				if (newVariable != undefined)
					this.currentFilterVal = newVariable;
			}
		}
		this.loadTasks();
	}

	// == Resize Listener =============================================================================================================

	@HostListener("window:resize", ["$event"])
	onResize(): void {
		this.pageHeight = window.innerHeight - 125;

	}

	public pageHeight: number = window.innerHeight - 125;

	// == Load grid data ===================================================================================



	loadTasks(): void {
		this.finishedLoading = false;
		// this.tableSearch = '';
		// FIX
		this.apiUIViewerService.getAllArchivedTasksAsync(this.items, 1, this.currentFilterVal == '' ? null : this.currentFilterVal)
			.subscribe((response: MyTaskItem[]) => {
				// console.log(response);  // Debug
				response.forEach((element: MyTaskItem) => {
					if (element.documentDate) {

						element.documentDate = this.dateTimeService.convertUTCTimeToLocalTime(element.documentDate);
					}
					if (element.actualStateDate) {
						element.actualStateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.actualStateDate);
					}
					if (element.createDate) {
						element.createDate = this.dateTimeService.convertUTCTimeToLocalTime(element.createDate);
					}
					if (element.updateDate) {
						element.updateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.updateDate);
					}
					if (element.deadlineDate) {
						element.deadlineDate = this.dateTimeService.convertUTCTimeToLocalTime(element.deadlineDate);
					}

					if (element.workflowCommentList) {
						element.workflowCommentList.forEach((comment: WorkflowComment) => {
							if (comment.displayDate) {
								const date = this.dateTimeService.convertUTCTimeToLocalTime(comment.displayDate);
								comment.displayDate = date == null ? new Date() : date;
							}

						});
					}
					element.commentsCount = element.workflowCommentList?.length || 0;
					if (element.legalRemedyObjection) {
						element.legalRemedyObjectionForDisplay = this.legalRemedyObjectionToString(element.legalRemedyObjection);
					}
					// BUG 24219 : Label for type Bescheid Art
					if (element.typeOfTaxAssessment) {
						const item = this.datatypes.find(entity => entity.name === element.typeOfTaxAssessment);
						if (item?.labelDE) {
							element.typeOfTaxAssessmentForDisplay = item ? item.labelDE : 'Label not found: ' + element.typeOfTaxAssessment;
						}
					}
				});

				this.tableTasksDataRaw = response;
				this.tableTasksDataRaw.forEach((task: MyTaskItem) => {
					if (task.workflowCommentList) {
						task.displayComments = this.createDisplayComment(task.workflowCommentList);
					}
				});
				this.tableTasksDataFiltered = this.tableTasksDataRaw;
				const skip = this.gridSettings.state.skip ? this.gridSettings.state.skip : -1;
				if (this.tableTasksDataFiltered.length < skip || skip == -1) {
					this.persistingService.updateSetting('archiveGridSettings');
				}

				this.gridSettings.gridData = process(this.tableTasksDataFiltered, this.gridSettings.state).data;

				this.finishedLoading = true;
			},
				(error: HttpErrorResponse) => {
					console.error(error);
				});
	}


	// == Grid Settings =======================================================================================================================================

	//Pagination



	public pageSize = 20;

	public pageChange(event: PageChangeEvent): void {
		this.gridSettings.state.skip = event.skip;
		//this.loadTasks();		// not neccessary
	}

	//Settings for individual columns 
	public menuSettings: ColumnMenuSettings = {
		lock: false,
		stick: false,
		view: 'tabbed',
		filter: true
	};

	// Icons
	public reloadSVG: SVGIcon = arrowRotateCwIcon;
	public settingsClearSVG: SVGIcon = gearIcon;
	public filterClearSVG: SVGIcon = filterClearIcon;
	public saveItemsSVG: SVGIcon = dataSqlIcon;


	// == Sorting ===================================
	public currentSort: SortDescriptor[] = [
		{
			field: "documentDate",
			dir: "desc",
		},
	];

	public sortSettings: MultipleSortSettings = {
		mode: "multiple",
		initialDirection: "asc",
		allowUnsort: true,
		showIndexes: true,
	};

	public sortChange(sort: SortDescriptor[]): void {
		this.currentSort = sort;
		//this.loadTasks();
	}

	// == Excel export ===================================================

	// Compounding data for Export to Excel
	// public allData(): ExcelExportData {
	// 	const result: ExcelExportData = {
	// 		data: process(this.tableTasksDataFiltered, {
	// 			filter: this.gridSettings.state.filter,
	// 			sort: this.gridSettings.state.sort,
	// 		}).data,
	// 	};

	// 	return result;
	// }

	// public exportToExcel(grid: GridComponent): void {
	// 	grid.saveAsExcel();
	// }

	// == Filter ======================================================================================================================================
	@ViewChild("grid", { static: false })
	grid!: GridComponent;


	public currentFilter: CompositeFilterDescriptor = {
		logic: "or",
		filters: [],
	}

	/** Filter all displayed columns */
	public onFilter(value: string, grid: GridComponent): void {

		this.currentFilterVal = value;
		const filter: CompositeFilterDescriptor = {
			logic: "or",
			filters: [],
		}

		// Check if value is empty
		if (value == "") {

			filter.filters = [];
		} else {

			// Get all displayed columns from kendo grid
			let columns = [];
			columns = this.grid.columns.toArray();

			// Convert columns to filter object
			columns.forEach((column: { title: string; }) => {
				if ((column.title != undefined)) {
					const setting = this.gridSettings.columnsConfig.find((item: ColumnSettings) => item.titleToDisplay === column.title);
					// Exclude isAdmin (boolean) column from global filter
					if ((setting && (setting.field != "documentDate") &&
						(setting.field != "comment") &&
						(setting.field != "createDate") &&
						(setting.field != "updateDate") &&
						(setting.field != "deadlineDate") &&
						(setting.field != "deadlineDate"))) {

						filter.filters.push({
							field: setting.field,
							operator: "contains",
							value: value,
						});
					}
				}
			});
		}

		this.gridSettings.state.filter = filter;
		this.gridSettings.state.skip = 0;
		//this.tableTasksDataFiltered = filterBy(this.tableTasksDataRaw, filter);
		//this.currentFilter = filter;
		this.saveGridSettings(grid);
	}
	removeFilter(): void {
		this.gridSettings.state.filter = {
			logic: 'and',
			filters: []
		};
		this.persistingService.set('archiveGridSettings', this.gridSettings);
		this.currentFilterVal = "";
		if (this.backendSearchFlag) {
			this.loadTasks();
		}
		this.backendSearchFlag = false;
	}

	// == Backend Filtering ============================================================================================================================
	// == Item Change handling ================================================================
	//number of values returned from the backend
	//5000 as default
	public items = this.gridSettings.items ? this.gridSettings.items : 5000;

	//flag to determine is a Tasks reload is needed after removing of the filters
	public backendSearchFlag = false;

	public changeItemNumber(value: number): void {
		this.items = value;
	}

	saveItemNumber(grid: GridComponent): void {

		this.saveGridSettings(grid);
		this.loadTasks();
	}

	public currentPage: number = 1;


	saveFilter(grid: GridComponent): void {
		// if ((this.regex.test(this.clientEngagementFilter!) || this.clientEngagementFilter == null) && (this.regex.test(this.idFilter!) || this.idFilter == null)) {
		// 	this.loadData();
		// } else if (!this.regex.test(this.clientEngagementFilter!)) {
		// 	this.wrapperNotificationService.showWarning("Please enter a valid GUID for Client Engagement");
		// } else if (!this.regex.test(this.idFilter!)) {
		// 	this.wrapperNotificationService.showWarning("Please enter a valid GUID for Job Identifier");
		// }
		this.backendSearchFlag = true;
		this.saveGridSettings(grid);
		this.loadTasks();
	}


	// == Persisting service ======================================================

	removeSettings(): void {
		localStorage.removeItem('archiveGridSettings');
		window.location.reload();
	}

	public dataStateChange(state: State, grid: GridComponent): void {
		this.gridSettings.state = state;
		this.gridSettings.gridData = process(this.tableTasksDataFiltered, state).data;
		this.saveGridSettings(grid);
	}

	public get savedStateExists(): boolean {
		return !!this.persistingService.get("archiveGridSettings");
	}

	public saveGridSettings(grid: GridComponent): void {

		const columns = grid.columns;
		const colSettings = this.gridSettings.columnsConfig;
		//add only the required column properties to save local storage space
		colSettings.forEach((col: ColumnSettings) => {
			const column = columns.toArray().find((item: ColumnBase) => item.title === col.titleToDisplay);
			if (column) {
				col.width = column.width;
				col._width = column['_width'];
				col.hidden = column.hidden;
				col.orderIndex = column.orderIndex;
			}
		});
		const gridConfig = {
			creationDate: new Date(this.currentDateAsString),
			items: this.items,
			backendFilterValue: this.currentFilterVal,
			state: this.gridSettings.state,
			gridData: this.gridSettings.gridData,
			columnsConfig: colSettings
		};
		this.persistingService.set('archiveGridSettings', gridConfig);
	}


	public mapGridSettings(gridSettings: GridSettings<MyTaskItem>): GridSettings<MyTaskItem> {
		const state = gridSettings.state;
		if (state.filter) {
			this.mapDateFilter(state.filter);
		}

		return {

			creationDate: gridSettings.creationDate,
			items: gridSettings.items,
			backendFilterValue: gridSettings.backendFilterValue,
			state,
			columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
			gridData: process(this.tableTasksDataFiltered, state).data,
		};
	}

	private mapDateFilter = (descriptor: CompositeFilterDescriptor) => {
		const filters = descriptor.filters || [];

		filters.forEach((filter: FilterDescriptor | CompositeFilterDescriptor) => {
			if ((filter as CompositeFilterDescriptor) && (filter as CompositeFilterDescriptor).filters) {
				this.mapDateFilter((filter as CompositeFilterDescriptor));
			} else if ((filter as FilterDescriptor).field === "FirstOrderedOn" && (filter as FilterDescriptor).value) {
				(filter as FilterDescriptor).value = this.dateTimeService.convertUTCTimeToLocalTime(new Date((filter as FilterDescriptor).value))!;
			}
		});
	}

	// == PDF-Viewer =========================================================================
	openFile(event: NavigationRow): void {

		// Get ClientEngagementKey from localStorage of the user
		const jsonStr = localStorage.getItem('user') || '';
		let clientEngagementKey = '';
		if (jsonStr.length > 0) {
			const jsonObj = JSON.parse(jsonStr);
			clientEngagementKey = (jsonObj as UserLocal).clientEngagementKey
		}
		if (event?.dataItem) {
			//this.router.navigate(['/assessment-review', event.selectedRows[0].dataItem.workflowId, event.selectedRows[0].dataItem.actualStateId, 'sdfdsf']);
			if (event.dataItem.workflowType == 12) {
				this.router.navigate(
					['/mail-editor'],
					{
						queryParams: {
							'w': event.dataItem.workflowId,
							's': event.dataItem.actualStateId,
							'c': clientEngagementKey
						}
					}
				);
			}
			else {
				this.router.navigate(
					['/assessment-review'],
					{
						queryParams: {
							'w': event.dataItem.workflowId,
							's': event.dataItem.actualStateId,
							'c': clientEngagementKey
						}
					}
				);
			}
		}

	}


	// == Handlers ===============================================================
	public cellClickHandler(args: CellClickEvent): void {
		args.sender.focus();
	}

	// == Helpers =================================================================

	/** Array for tenantSource with name and ids. Id -> Backend TenantSourceEnum */
	public legalRemedyObjectionSourceData = [
		{ id: 1, name: 'Stattgegeben' },
		{ id: 2, name: 'Nicht stattgegeben' },
		{ id: 4, name: 'Zurückgenommen' },
	];

	//Contains the id?
	public legalRemedyObjectionToString(id: number): string {
		if (this.legalRemedyObjectionSourceData.find((item: { id: number; }) => item.id == id) == undefined) {
			return 'Unbekannt';
		}
		else {
			return this.legalRemedyObjectionSourceData.find((item: { id: number; }) => item.id == id)?.name ?? "";
		}
	}

	public createDisplayComment(workflowCommentList: WorkflowComment[]): string {
		let displayComments = "";

		// filter the comments

		workflowCommentList = workflowCommentList.filter((x) => x.isDeleted === false);

		// sort the comments
		workflowCommentList.sort((a, b) => (a.displayDate < b.displayDate) ? 1 : -1)

		const maxComments = 3;

		// compound the comments
		for (let i = 0; i < maxComments; i++) {
			if (workflowCommentList[i] != null) {
				displayComments = displayComments + workflowCommentList[i].displayUsername + " - " + formatDate(workflowCommentList[i].displayDate, 'dd.MM.yyyy, HH:mm', 'de') + "" + ":\n\r" + workflowCommentList[i].comment + "\n\r\n\r";

			}
		}

		// add the number of hidden comments
		if (workflowCommentList.length > maxComments) {
			if (workflowCommentList.length - maxComments === 1) {
				displayComments = displayComments + "... und " + (workflowCommentList.length - maxComments) + " weiterer Kommentar.";
			}
			else {
				displayComments = displayComments + "... und " + (workflowCommentList.length - maxComments) + " weitere Kommentare.";
			}
		}

		return displayComments; //.slice(0, -4);
	}

}