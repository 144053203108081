<div class="stareTopic">
    {{ 'APP.ADMINISTRATION.USER_RIGHTS.TITLE' | translate }}
</div>

<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
            filterable="menu" [loading]="!finishedLoading" (cellClick)="cellClickHandler($event)"
            (dblclick)="editHandler(grid.activeRow)" (add)="addHandler()" [sort]="currentSort"
            (sortChange)="sortChange($event)" [filter]="currentFilter || {logic: 'and', filters: []}" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [selectable]="{ checkboxOnly: false, mode: 'single' }" [columnMenu]="menuSettings"
            [navigable]="true" [sortable]="true" [resizable]="true">

            <ng-template kendoGridToolbarTemplate>
                <kendo-textbox [placeholder]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.SEARCH' | translate"
                    (valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

                </button>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                    title="{{ 'BUTTON.ADD' | translate }}">
                </button>
                &nbsp;|&nbsp;



                <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>

                <button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
                    title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

                </button>

            </ng-template>

            <kendo-grid-messages [pagerPage]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.PAGER.PAGE' | translate"
                [pagerOf]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.PAGER.OF' | translate"
                [pagerItems]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.PAGER.ITEM' | translate"
                [pagerItemsPerPage]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.PAGER.TASK_PER_PAGE' | translate"
                [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
                [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
                [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
                [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
                [setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
                [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
            </kendo-grid-messages>

            <kendo-grid-column field="id" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.ID' | translate"
                [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="userId" title="User ID" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="mail" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.MAIL' | translate"></kendo-grid-column>
            <kendo-grid-column field="clientEngagementKey" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="clientEngagementName" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.CLIENT_ENGAGEMENT_NAME' | translate"></kendo-grid-column>
            <kendo-grid-column field="isPreparer" filter="boolean" type="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.IS_PREPARER' | translate"><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.isPreparer ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column>
            <kendo-grid-column field="isReviewer" filter="boolean" type="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.IS_REVIEWER' | translate"><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.isReviewer ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column>
            <!-- <kendo-grid-column field="isPreparerDeadline" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.IS_PREPARER_DEADLINE' | translate"><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.isPreparerDeadline ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column>
            <kendo-grid-column field="isReviewerDeadline" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.IS_REVIEWER_DEADLINE' | translate"><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.isReviewerDeadline ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column> -->
            <kendo-grid-column field="isCITTeam" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.IS_CIT_TEAM' | translate">><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.isCITTeam ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column>

            <kendo-grid-column field="canDeleteWorkflows" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.CAN_DELETE_WORKFLOWS' | translate"><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.canDeleteWorkflows ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column>
            <kendo-grid-column field="canReopenWorkflows" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.REOPEN_WORKFLOW' | translate"><ng-template
                    kendoGridCellTemplate let-dataItem>{{ dataItem.canReopenWorkflows ? "ja" : "nein"
                    }}</ng-template></kendo-grid-column>
            <kendo-grid-column field="isDeactivated" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.IS_DEACTIVATED' | translate"
                [hidden]="true"><ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isDeactivated ? "ja" :
                    "nein"
                    }}</ng-template></kendo-grid-column>

            <kendo-grid-column field="canManageOwnUser" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.MANAGE_OWN_USER' | translate"
                [hidden]="false"><ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.canManageOwnUser ? "ja" :
                    "nein"
                    }}</ng-template></kendo-grid-column>

            <kendo-grid-column field="canDataExport" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.CAN_DATA_EXPORT' | translate"
                [hidden]="false"><ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.canDataExport ? "ja" :
                    "nein"
                    }}</ng-template></kendo-grid-column>
            <kendo-grid-column field="canDataImport" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.CAN_DATA_IMPORT' | translate"
                [hidden]="false"><ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.canDataImport ? "ja" :
                    "nein"
                    }}</ng-template></kendo-grid-column>
            <kendo-grid-column field="canConfigureWorkflows" filter="boolean" kendoTooltip
                [title]="'APP.ADMINISTRATION.USER_RIGHTS.TABLE.HEADER.CAN_CONFIGURE_WORKFLOWS' | translate"
                [hidden]="false"><ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.canConfigureWorkflows ?
                    "ja" :
                    "nein"
                    }}</ng-template></kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>

                <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
            </ng-template>

            <kendo-grid-excel fileName="Benutzerberechtigungen.xlsx" [fetchData]="allData"></kendo-grid-excel>

        </kendo-grid>
    </div>
</div>


<kendo-dialog *ngIf="active" [width]="700" [height]="500" (close)="closeForm()">
    <kendo-dialog-titlebar *ngIf="isNew">
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
        <kendo-formfield>
            <kendo-label [for]="userId"
                [text]="'APP.ADMINISTRATION.USER_RIGHTS.MODAL.MAIL.LABEL' | translate"></kendo-label>
            <kendo-multicolumncombobox #userId formControlName="userId" [data]="allUsersDropDownFiltered"
                textField="text" valueField="value"
                [placeholder]="'APP.ADMINISTRATION.USER_RIGHTS.MODAL.SELECT_USER' | translate" [valuePrimitive]="true"
                [filterable]="true" (filterChange)="handleFilterChange($event)">
                <kendo-combobox-column field="value" title="ID" [hidden]="true">
                </kendo-combobox-column>
                <kendo-combobox-column field="employeeNumber" title="Mitarbeiter ID" [width]="250">
                </kendo-combobox-column>
                <kendo-combobox-column field="text" title="E-Mail" [width]="200">
                </kendo-combobox-column>


            </kendo-multicolumncombobox>
            <!-- <kendo-dropdownlist #userId formControlName="userId" [data]="allUsersDropDown"
                [defaultItem]="{ text: 'APP.ADMINISTRATION.USER_RIGHTS.MODAL.SELECT_USER' | translate, value: null }"
                textField="text" valueField="value" [valuePrimitive]="true" required></kendo-dropdownlist> -->
            <kendo-formerror>{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.MAIL.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="clientEngagementKey"
                [text]="'APP.ADMINISTRATION.USER_RIGHTS.MODAL.CLIENT_ENGAGEMENT_KEY.LABEL' | translate"></kendo-label>
            <kendo-dropdownlist #clientEngagementKey formControlName="clientEngagementKey"
                [data]="allClientEngagementsDropDown"
                [defaultItem]="{ text: 'APP.ADMINISTRATION.USER_RIGHTS.MODAL.SELECT_ENGAGEMENT' | translate, value: null }"
                textField="text" valueField="value" [valuePrimitive]="true" required></kendo-dropdownlist>
            <kendo-formerror>{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.CLIENT_ENGAGEMENT_KEY.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

        <div class="form-row">
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="isPreparer" type="checkbox" kendoCheckBox formControlName="isPreparer" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="isPreparer">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.IS_PREPARER.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="isReviewer" type="checkbox" kendoCheckBox formControlName="isReviewer" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="isReviewer">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.IS_REVIEWER.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>
        </div>

        <div class="form-row">
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="isCITTeam" type="checkbox" kendoCheckBox formControlName="isCITTeam" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="isCITTeam">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.IS_CIT_TEAM.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="canDeleteWorkflows" type="checkbox" kendoCheckBox
                            formControlName="canDeleteWorkflows" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="canDeleteWorkflows">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.CAN_DELETE_WORKFLOWS.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>

        </div>
        <div class="form-row">
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="canReopenWorkflows" type="checkbox" kendoCheckBox
                            formControlName="canReopenWorkflows" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="canReopenWorkflows">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.REOPEN_WORKFLOW.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="canManageOwnUser" type="checkbox" kendoCheckBox formControlName="canManageOwnUser" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="canManageOwnUser">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.MANAGE_OWN_USER.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>

        </div>
        <div class="form-row">
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="canDataExport" type="checkbox" kendoCheckBox formControlName="canDataExport" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="canDataExport">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.CAN_DATA_EXPORT.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>
            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="canDataImport" type="checkbox" kendoCheckBox formControlName="canDataImport" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="canDataImport">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.CAN_DATA_IMPORT.LABEL' |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>

        </div>
        <div class="form-row">

            <kendo-formfield>
                <ng-container>
                    <span class="k-checkbox-wrap">
                        <input id="canConfigureWorkflows" type="checkbox" kendoCheckBox
                            formControlName="canConfigureWorkflows" />
                    </span>
                    <label [labelClass]="false" class="k-checkbox-label"
                        for="canConfigureWorkflows">{{'APP.ADMINISTRATION.USER_RIGHTS.MODAL.CAN_CONFIGURE_WORKFLOWS.LABEL'
                        |
                        translate}}</label>
                </ng-container>
            </kendo-formfield>

        </div>


    </form>

    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
            {{ 'BUTTON.DELETE' | translate }}
        </button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{ 'BUTTON.SAVE' | translate }}
        </button>
        <button kendoButton (click)="onCancel($event)">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>