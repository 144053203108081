<form [formGroup]="corporateIncomeTaxSeparateAndUniformDeterminationForm1300" class="k-form k-form-md"
    style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " overrideLabel="Begründung Rechtsbehelf für Mandant (extern):" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_assessmentYear"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType" [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxGuE_incomeCommercial" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxGuE_incomeCommercial" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxGuE_incomeCurrent" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxGuE_incomeCurrent" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxGuE_profitTotalTradeBalance" [datasetId]="headerDatasetId"
                        [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxGuE_profitTotalTradeBalance" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxGuE_profitSpecialBusinessAssets"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxGuE_profitSpecialBusinessAssets" [datasetId]="headerDatasetId"
                        [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxGuE_tradeTaxBase" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxGuE_tradeTaxBase" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxGuE_shares" [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="corporateIncomeTaxGuE_shares"
                        [datasetId]="headerDatasetId" [form]="
                            corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                        " [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Gesellschaft
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 672px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Gesellschaft
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-textbox (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_companyIdentifier" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    overrideLabel="Steuernummer">
                                </app-stare-textbox>
                            </div>
                            <div [class]="
                                    workflowType == 11
                                        ? 'column-for-with-plus'
                                        : 'column'
                                ">
                                <app-stare-dropdown-string (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_legalEntityKey" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    [dataOptionsSource]="legalEntities">
                                </app-stare-dropdown-string>
                            </div>
                            <div class="column-for-plus" *ngIf="workflowType == 11">
                                <app-legalentity-add-button></app-legalentity-add-button>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="column">
                                <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                                    xbrlName="document_corporateIncomeTaxGuE_company_typeOfParticipation"
                                    [datasetId]="payload.controls['datasetId'].value" [form]="payload"
                                    [isReadOnly]="false" [workflowType]='workflowType'>
                                </app-stare-textbox>
                            </div>
                            <div class="column">
                                <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                                    xbrlName="corporateIncomeTaxGuE_company_typeOfParticipation"
                                    [datasetId]="payload.controls['datasetId'].value" [form]="payload"
                                    [isReadOnly]="true" [workflowType]='workflowType'>
                                </app-stare-textbox>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="column">
                                <!-- <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                                    xbrlName="document_corporateIncomeTaxGuE_company_typeOfParticipation"
                                    [datasetId]="payload.controls['datasetId'].value" [form]="payload"
                                    [isReadOnly]="false" [workflowType]='workflowType'>
                                </app-stare-textbox>  // Task 24630-->
                                <app-stare-dropdown (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_typeOfParticipationInteger"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    [dataNumberOptionsSource]="
                                        typeOfParticipation
                                    ">
                                </app-stare-dropdown>
                            </div>
                            <div class="column">
                                <!-- <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                                    xbrlName="corporateIncomeTaxGuE_company_typeOfParticipation"
                                    [datasetId]="payload.controls['datasetId'].value" [form]="payload"
                                    [isReadOnly]="true" [workflowType]='workflowType'>
                                </app-stare-textbox> -->
                                <app-stare-dropdown (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_typeOfParticipationInteger" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType"
                                    [dataNumberOptionsSource]="
                                        typeOfParticipation
                                    ">
                                </app-stare-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_distribution" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_distribution" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_incomeCommercial" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_incomeCommercial" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_incomeCurrent" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_incomeCurrent" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_profitTotalTradeBalance"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_profitTotalTradeBalance" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_profitSpecialBusinessAssets" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_sharesTradeTaxBase" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_sharesTradeTaxBase" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_tradeTaxBase" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_tradeTaxBase" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <!-- Task 27080 -->
                        <!-- <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_billableLossPreviousYear"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_billableLossPreviousYear" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div> -->
                        <!-- <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_nonDeductibleLoss" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_nonDeductibleLoss" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_billableLoss" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_billableLoss" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_overallBalanceSheet"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_overallBalanceSheet" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_supplementaryBalanceSheet" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_totalCapital" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_totalCapital" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_corporateIncomeTaxGuE_company_commercialEquity" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_commercialEquity" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <!-- <div class="row"> -->
                        <!-- <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div> -->
                        <!-- <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div> -->
                        <!-- <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div> -->
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany'
                                        )
                                    ">
                                    Lösche Gesellschafter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" (click)="
                            addPayload(
                                'DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany'
                            )
                        ">
                        Neuen Gesellschafter hinzufügen
                    </button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSeparateAndUniformDeterminationForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
                <div class="column-for-text-area"
                    *ngIf="corporateIncomeTaxSeparateAndUniformDeterminationForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSeparateAndUniformDeterminationForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSeparateAndUniformDeterminationForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row-for-comment-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSeparateAndUniformDeterminationForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType" [form]="
                    corporateIncomeTaxSeparateAndUniformDeterminationForm1300
                " [auditResults]="auditResults" [workflowId]="workflowId"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 

<form [formGroup]="corporateIncomeTaxSeparateAndUniformDeterminationForm1300" class="k-form k-form-md"
    style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 120 },
        { height: 130 },
        { height: 50 },
						{ height: 112 },
						{ height: 112 },
                        { height: 90 },
						{ height: 20 },
						{ height: 112},
						{ height: 112 },
						{ height: 112 },
						{ height: 112},
						{ height: 112 },
						{ height: 112 },
						{ height: 20},
						{ height: documentCITSaUCompanyPayloadLength},
						{ height: 50 },
						{ height: 20 },
		                { height: 90 },
		                { height: withoutReferenceValidationExplanationRowHeight},
                        { height: sdcFieldsRowHeight },
                        { height: sdcFieldsRowHeight }]" [cols]="[{ width: 270 }, { width: 310 }, { width:130 }]">

                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">Begründung Rechtsbehelf für
                            Mandant (extern):</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text" placeholder="Steuernummer" formControlName="id_companyIdentifier"
                            autocomplete="off"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="legalentityLabel">{{getXbrlLabel('id_legalEntityKey')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_assessmentYear-'+headerDatasetId"
                            id="assessmentYearLabel">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false" [decimals]="0"
                            [maxlength]="4" [changeValueOnScroll]="false" format="##"
                            [id]="'id_assessmentYear-'+headerDatasetId" formControlName="id_assessmentYear"
                            autocomplete="off"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_assessmentYear')">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_vdn-'+headerDatasetId"
                            id="{{'document_corporateIncomeTax_vdnLabel'}}">
                            {{getXbrlLabel('document_corporateIncomeTax_vdn')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTax_vdn-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_vdn')"
                            [defaultItem]="defaultItem" formControlName="document_corporateIncomeTax_vdn"
                            [data]="vdnOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_vdn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxGuE_incomeCommercial-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTaxGuE_incomeCommercial')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxGuE_incomeCommercial-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_incomeCommercial')}}"
                            formControlName="document_corporateIncomeTaxGuE_incomeCommercial" autocomplete="off"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxGuE_incomeCommercial')">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_incomeCommercial'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxGuE_incomeCommercial-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxGuE_incomeCommercial')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [id]="'corporateIncomeTaxGuE_incomeCommercial-'+headerDatasetId" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxGuE_incomeCommercial')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_incomeCommercial')}}"
                            formControlName="corporateIncomeTaxGuE_incomeCommercial" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxGuE_incomeCommercial'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxGuE_incomeCurrent-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTaxGuE_incomeCurrent')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxGuE_incomeCurrent-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxGuE_incomeCurrent')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_incomeCurrent')}}"
                            formControlName="document_corporateIncomeTaxGuE_incomeCurrent" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_incomeCurrent'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxGuE_incomeCurrent-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxGuE_incomeCurrent')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [id]="'corporateIncomeTaxGuE_incomeCurrent-'+headerDatasetId" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxGuE_incomeCurrent')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_incomeCurrent')}}"
                            formControlName="corporateIncomeTaxGuE_incomeCurrent" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxGuE_incomeCurrent'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxGuE_profitTotalTradeBalance-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTaxGuE_profitTotalTradeBalance')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxGuE_profitTotalTradeBalance-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxGuE_profitTotalTradeBalance')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_profitTotalTradeBalance')}}"
                            formControlName="document_corporateIncomeTaxGuE_profitTotalTradeBalance" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_profitTotalTradeBalance'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxGuE_profitTotalTradeBalance-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxGuE_profitTotalTradeBalance')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [id]="'corporateIncomeTaxGuE_profitTotalTradeBalance-'+headerDatasetId" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxGuE_profitTotalTradeBalance')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_profitTotalTradeBalance')}}"
                            formControlName="corporateIncomeTaxGuE_profitTotalTradeBalance" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxGuE_profitTotalTradeBalance'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxGuE_profitSpecialBusinessAssets-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxGuE_profitSpecialBusinessAssets')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxGuE_profitSpecialBusinessAssets-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxGuE_profitSpecialBusinessAssets')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_profitSpecialBusinessAssets')}}"
                            formControlName="document_corporateIncomeTaxGuE_profitSpecialBusinessAssets"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_profitSpecialBusinessAssets'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'corporateIncomeTaxGuE_profitSpecialBusinessAssets-'+headerDatasetId">
                            {{getXbrlLabel('corporateIncomeTaxGuE_profitSpecialBusinessAssets')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [id]="'corporateIncomeTaxGuE_profitSpecialBusinessAssets-'+headerDatasetId" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxGuE_profitSpecialBusinessAssets')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_profitSpecialBusinessAssets')}}"
                            formControlName="corporateIncomeTaxGuE_profitSpecialBusinessAssets" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxGuE_profitSpecialBusinessAssets'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'document_corporateIncomeTaxGuE_tradeTaxBase-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxGuE_tradeTaxBase')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxGuE_tradeTaxBase-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxGuE_tradeTaxBase')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_tradeTaxBase')}}"
                            formControlName="document_corporateIncomeTaxGuE_tradeTaxBase" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_tradeTaxBase'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'corporateIncomeTaxGuE_tradeTaxBase-'+headerDatasetId">
                            {{getXbrlLabel('corporateIncomeTaxGuE_tradeTaxBase')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [id]="'corporateIncomeTaxGuE_tradeTaxBase-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxGuE_tradeTaxBase')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_tradeTaxBase')}}" [readonly]="true"
                            class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            formControlName="corporateIncomeTaxGuE_tradeTaxBase" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxGuE_tradeTaxBase'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'document_corporateIncomeTaxGuE_shares-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxGuE_shares')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxGuE_shares-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxGuE_shares')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_shares')}}"
                            formControlName="document_corporateIncomeTaxGuE_shares" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_shares'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'corporateIncomeTaxGuE_shares-'+headerDatasetId">
                            {{getXbrlLabel('corporateIncomeTaxGuE_shares')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [id]="'corporateIncomeTaxGuE_shares-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxGuE_shares')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_shares')}}" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none"
                            formControlName="corporateIncomeTaxGuE_shares" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxGuE_shares'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Gesellschaft</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="2">
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 },
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 },  
												{ height: 50 }]" [cols]="[{ width: 320 }, { width: 370 }]" class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label [for]="'id_companyIdentifier-'+payload.controls['datasetId'].value"
                                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'id_companyIdentifier-'+payload.controls['datasetId'].value"
                                            type="text"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_companyIdentifier')"
                                            placeholder="Steuernummer" formControlName="id_companyIdentifier"
                                            autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_legalEntityKey-'+payload.controls['datasetId'].value"
                                            id="legalentityPayloadLabel">{{getXbrlLabel('id_legalEntityKey')}}</kendo-label>
                                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'"
                                            [valueField]="'value'"
                                            [id]="'id_legalEntityKey-'+payload.controls['datasetId'].value"
                                            [filterable]="true" [valuePrimitive]="true" [defaultItem]="defaultItem"
                                            formControlName="id_legalEntityKey"
                                            (filterChange)="handleLegalEntitiesFilter($event)"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_legalEntityKey')"
                                            [data]="legalEntities"
                                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                                        <app-legalentity-add-button
                                            *ngIf="workflowType==11"></app-legalentity-add-button>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_typeOfParticipation-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_typeOfParticipation')}}
                                        </kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'document_corporateIncomeTaxGuE_company_typeOfParticipation-'+payload.controls['datasetId'].value"
                                            type="text"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_typeOfParticipation')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_typeOfParticipation')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_typeOfParticipation"
                                            autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_typeOfParticipation'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_typeOfParticipation-'+payload.controls['datasetId'].value"
                                            id="{{'name'+i}}">{{getXbrlLabel('corporateIncomeTaxGuE_company_typeOfParticipation')}}</kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'corporateIncomeTaxGuE_company_typeOfParticipation-'+payload.controls['datasetId'].value"
                                            type="text" [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_typeOfParticipation')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_typeOfParticipation')}}"
                                            formControlName="corporateIncomeTaxGuE_company_typeOfParticipation"
                                            autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_typeOfParticipation'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_distribution-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_distribution')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_distribution-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_distribution')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_distribution')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_distribution"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_distribution'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_distribution-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_distribution')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [readonly]="true"
                                            class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_distribution-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_distribution')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_distribution')}}"
                                            formControlName="corporateIncomeTaxGuE_company_distribution"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_distribution'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_incomeCommercial-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_incomeCommercial')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_incomeCommercial-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_incomeCommercial')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_incomeCommercial')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_incomeCommercial"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_incomeCommercial'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_incomeCommercial-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_incomeCommercial')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_incomeCommercial-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_incomeCommercial')}}"
                                            formControlName="corporateIncomeTaxGuE_company_incomeCommercial"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_incomeCommercial')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_incomeCommercial'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_incomeCurrent-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_incomeCurrent')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_incomeCurrent-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_incomeCurrent')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_incomeCurrent')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_incomeCurrent"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_incomeCurrent'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_incomeCurrent-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_incomeCurrent')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [readonly]="true"
                                            class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_incomeCommercial-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_incomeCurrent')}}"
                                            formControlName="corporateIncomeTaxGuE_company_incomeCurrent"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_incomeCurrent')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_incomeCurrent'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_profitTotalTradeBalance-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_profitTotalTradeBalance')}}
                                        </kendo-label>

                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_profitTotalTradeBalance-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_profitTotalTradeBalance')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_profitTotalTradeBalance"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_profitTotalTradeBalance')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_profitTotalTradeBalance'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_profitTotalTradeBalance-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_profitTotalTradeBalance')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_profitTotalTradeBalance-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_profitTotalTradeBalance')}}"
                                            formControlName="corporateIncomeTaxGuE_company_profitTotalTradeBalance"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_profitTotalTradeBalance')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_profitTotalTradeBalance'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets')}}
                                        </kendo-label>

                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_profitSpecialBusinessAssets-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_profitSpecialBusinessAssets')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_profitSpecialBusinessAssets-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_profitSpecialBusinessAssets')}}"
                                            formControlName="corporateIncomeTaxGuE_company_profitSpecialBusinessAssets"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_profitSpecialBusinessAssets')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_profitSpecialBusinessAssets'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_tradeTaxBase-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_tradeTaxBase')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_tradeTaxBase-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_tradeTaxBase')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_tradeTaxBase"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_tradeTaxBase')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_tradeTaxBase'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_tradeTaxBase-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_tradeTaxBase')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_tradeTaxBase-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_tradeTaxBase')}}"
                                            formControlName="corporateIncomeTaxGuE_company_tradeTaxBase"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_tradeTaxBase')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_tradeTaxBase'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_sharesTradeTaxBase-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_sharesTradeTaxBase')}}
                                        </kendo-label>

                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_sharesTradeTaxBase-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_sharesTradeTaxBase')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_sharesTradeTaxBase"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_sharesTradeTaxBase')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_sharesTradeTaxBase'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_sharesTradeTaxBase-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_sharesTradeTaxBase')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_sharesTradeTaxBase-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_sharesTradeTaxBase')}}"
                                            formControlName="corporateIncomeTaxGuE_company_sharesTradeTaxBase"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_sharesTradeTaxBase')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_sharesTradeTaxBase'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_billableLossPreviousYear-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_billableLossPreviousYear')}}
                                        </kendo-label>

                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_billableLossPreviousYear-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_billableLossPreviousYear')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_billableLossPreviousYear"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_billableLossPreviousYear')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_billableLossPreviousYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="10" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_billableLossPreviousYear-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_billableLossPreviousYear')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_billableLossPreviousYear-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_billableLossPreviousYear')}}"
                                            formControlName="corporateIncomeTaxGuE_company_billableLossPreviousYear"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_billableLossPreviousYear')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_billableLossPreviousYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_nonDeductibleLoss-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_nonDeductibleLoss')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_nonDeductibleLoss-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_nonDeductibleLoss')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_nonDeductibleLoss"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_nonDeductibleLoss')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_nonDeductibleLoss'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="11" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_nonDeductibleLoss-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_nonDeductibleLoss')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_nonDeductibleLoss-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_nonDeductibleLoss')}}"
                                            formControlName="corporateIncomeTaxGuE_company_nonDeductibleLoss"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_nonDeductibleLoss')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_nonDeductibleLoss'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_billableLoss-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_billableLoss')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_billableLoss-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_billableLoss')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_billableLoss"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_billableLoss')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_billableLoss'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="12" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_billableLoss-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_billableLoss')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_billableLoss-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_billableLoss')}}"
                                            formControlName="corporateIncomeTaxGuE_company_billableLoss"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_billableLoss')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_billableLoss'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_overallBalanceSheet-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_overallBalanceSheet')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_overallBalanceSheet-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_overallBalanceSheet')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_overallBalanceSheet"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_overallBalanceSheet')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_overallBalanceSheet'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="13" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_overallBalanceSheet-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_overallBalanceSheet')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_overallBalanceSheet-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_overallBalanceSheet')}}"
                                            formControlName="corporateIncomeTaxGuE_company_overallBalanceSheet"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_overallBalanceSheet')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_overallBalanceSheet'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="14" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_supplementaryBalanceSheet-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_supplementaryBalanceSheet')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_supplementaryBalanceSheet-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_supplementaryBalanceSheet')}}"
                                            formControlName="corporateIncomeTaxGuE_company_supplementaryBalanceSheet"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_supplementaryBalanceSheet')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_supplementaryBalanceSheet'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_totalCapital-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_totalCapital')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [decimals]="0" format="##################" [min]="0"
                                            [changeValueOnScroll]="false"
                                            [id]="'document_corporateIncomeTaxGuE_company_totalCapital-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_totalCapital')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_totalCapital"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_totalCapital')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_totalCapital'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="15" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_totalCapital-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_totalCapital')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [id]="'corporateIncomeTaxGuE_company_totalCapital-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_totalCapital')}}"
                                            formControlName="corporateIncomeTaxGuE_company_totalCapital"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_totalCapital')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_totalCapital'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_commercialEquity-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_commercialEquity')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_commercialEquity-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_commercialEquity')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_commercialEquity"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_commercialEquity')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_commercialEquity'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="16" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_commercialEquity-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_commercialEquity')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [id]="'corporateIncomeTaxGuE_company_commercialEquity-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_commercialEquity')}}"
                                            formControlName="corporateIncomeTaxGuE_company_commercialEquity"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_commercialEquity')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_commercialEquity'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="17" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability')}}"
                                            formControlName="corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice')}}"
                                            formControlName="document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="18" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice-'+payload.controls['datasetId'].value"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice')}}"
                                            formControlName="corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice"
                                            autocomplete="off"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice')">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany")'>Lösche
                                        Gesellschafter</button>
                                </kendo-gridlayout-item>
                            </kendo-gridlayout>
                        </div>
                    </div>

                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany")'>Neuen
                            Gesellschafter hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="2"
                    *ngIf="formGroup.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->