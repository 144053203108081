import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StareInputFieldsService } from 'app/_services/stare-input-fields.service';
import { UntypedFormGroup } from '@angular/forms';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { ValidationDeactivationTemporaryItem } from 'app/_models/validation-deactivation.model';
import { TaxNoticeNameItem } from 'app/_models/tax-notice-name.model';
import { EnumDataTypeStrings, ListsService } from 'app/_services/lists.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-stare-dropdown-notice-name',
  templateUrl: './stare-dropdown-notice-name.component.html',
  styleUrl: './stare-dropdown-notice-name.component.scss',
  standalone: false
})

export class StareDropdownNoticeNameComponent {
  @Output() onFieldFocus = new EventEmitter();
  //Sends value to disable to doc-base for saving
  @Output() onReferenceDisable = new EventEmitter();


  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() xbrlName: string = '';
  @Input() datasetId: number = -1;
  @Input() isReadOnly: boolean = false;

  //(DMS vs. SDC Workflow)
  @Input() workflowType: number = -1;
  @Input() documentType: string = "";
  //ex. Kassenzeicht und Steuernummer
  @Input() overrideLabel: string = '';
  @Input() isArchiv: boolean = false;
  public label: string = '';
  public class: string = '';

  public fieldClass: string = '';

  public filterable: boolean = true;
  public listHeight: number = 500;

  public dataForDisplay: TaxNoticeNameItem[] = [];
  public dataOriginal: TaxNoticeNameItem[] = [];
  //public decimals: number = 0;
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;


  public defaultItem: TaxNoticeNameItem = {
    id: 0,
    baseName: 'Select item',
    nameEN: 'Select item',
    nameDE: 'Bitte auswählen...',
    isDefault: false,
    isDeleted: false,

    withTaxationBase: false,
    withAnnex: false,
    withSeparationDivisions: false,
    isControllingCompany: false,
    isControlledCompany: false,
    isKStVV_P8: false,
    isKStVZ_AssessmentYear: false,
    isGewSTMB_WithAddCarryForward: false,
    isPrePayment: false,
    isUSt_AsNotification: false,
    isUSt_Interest: false,
    isUSt_Delayed: false

  };



  constructor(

    public stareInputFieldsService: StareInputFieldsService,
    public listService: ListsService,
    private dataService: DataService,
    public tooltipService: TooltipService

  ) {

  }

  onDestroy$: Subject<boolean> = new Subject();

  ngOnInit(): void {

    // this.dataForDisplay = [];
    this.class = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';
    this.fieldClass = this.isReadOnly ? 'field-disabled' : 'field-enabled';
    this.dataService.formLoaded$.subscribe(formLoaded => {
      if (formLoaded) {
        this.label = this.overrideLabel ? this.overrideLabel : this.stareInputFieldsService.getXbrlLabel(this.xbrlName);
        this.dataOriginal = [];

        // this.listService.GetDocumentNames(<EnumDataTypeStrings>this.documentType).subscribe((data) => {
        //   console.log(data);
        //   data.forEach(item => {
        //     this.dataOriginal.push(this.onDocumentNameParameterChange(item));
        //   });
        //   // this.dataForDisplay = this.dataOriginal;
        //   this.prepareDataForDisplay();
        //   // if (this.form.controls[this.xbrlName].value === "" || this.form.controls[this.xbrlName].value == null || this.form.controls[this.xbrlName].value == undefined) {
        //   //   this.form.controls[this.xbrlName].setValue(this.defaultItem.id);
        //   // }
        // });
        this.listService.GetDocumentNames(<EnumDataTypeStrings>this.documentType).pipe(takeUntil(this.onDestroy$)).subscribe((data) => {

          data.forEach(item => {
            this.dataOriginal.push(this.onDocumentNameParameterChange(item));
          });
          // this.dataForDisplay = this.dataOriginal;
          this.prepareDataForDisplay();
          if (this.form.controls[this.xbrlName].value === "" || this.form.controls[this.xbrlName].value == null || this.form.controls[this.xbrlName].value == undefined) {
            this.form.controls[this.xbrlName].setValue(this.defaultItem.id);
          }
        })

      }
      // }


    });

    if (this.xbrlName === 'document_auditResult' || this.xbrlName === 'id_notificationName') {
      this.filterable = false;
    }

  }

  ngOnDestroy(): void {
    // this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  public prepareDataForDisplay(): void {
    this.dataForDisplay = this.dataOriginal;
    this.defaultItem = this.dataForDisplay.some(x => x.isDefault) ? (this.dataForDisplay.filter(x => x.isDefault))[0] : this.dataForDisplay[0];
    this.dataForDisplay = this.dataForDisplay.filter(x => x != this.defaultItem);
    if (this.form.controls[this.xbrlName].value === "" || this.form.controls[this.xbrlName].value == null || this.form.controls[this.xbrlName].value == undefined) {
      this.form.controls[this.xbrlName].setValue(this.defaultItem.id);
    }
  }

  public requestedByAdmin(): boolean {
    return this.dataService.requestedByAdmin();
  }
  public isUnlocked(): boolean {
    return this.dataService.GetAdminArchivUnlockFlag();
  }
  /** User click on input field --> highlight PDF red rectangle */
  public onFocusInputXbrl(): void {
    //console.log(this.xbrlName);
    //console.log(this.datasetId);
    this.onFieldFocus.emit(document.activeElement);
  }



  // handleFilter(value: string) {
  //   // this.dataOptions = this.dataOptionsSource.filter(
  //   //   (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   // );
  //   // this.dataForDisplay = this.dataNumberOptionsSource.filter(
  //   //   (s) => s.nameDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   // );
  // }

  /** Triggered when the form fields are clicked on and should be focused on
*/
  public onReferenceDisableReciever(activation: boolean): void {
    this.onReferenceDisable.emit({ datasetId: this.datasetId, xbrl: this.xbrlName, activation: activation } as ValidationDeactivationTemporaryItem);
  }

  public onDocumentNameParameterChange(item: TaxNoticeNameItem): TaxNoticeNameItem {
    // console.log(item);
    const output = JSON.parse(JSON.stringify(item));
    if (output.nameDE.includes('{assessmentYear}')) {
      if (this.form.controls['id_assessmentYear'].value) {
        output.nameDE = output.nameDE.replace('{assessmentYear}', this.form.controls['id_assessmentYear'].value);
        output.nameEN = output.nameEN.replace('{assessmentYear}', this.form.controls['id_assessmentYear'].value);
      }
    }
    if (output.nameDE.includes('{cutOffDate}')) {
      if (this.form.controls['document_cutOffDate'].value) {
        output.nameDE = output.nameDE.replace('{cutOffDate}', this.form.controls['document_cutOffDate'].value.toLocaleDateString('de-DE'));
        output.nameEN = output.nameEN.replace('{cutOffDate}', this.form.controls['document_cutOffDate'].value.toLocaleDateString('de-DE'));
      }
    }
    if (output.nameDE.includes('{cutOffDateYear}')) {
      if (this.form.controls['document_cutOffDate'].value) {
        output.nameDE = output.nameDE.replace('{cutOffDateYear}', this.form.controls['document_cutOffDate'].value.getFullYear());
        output.nameEN = output.nameEN.replace('{cutOffDateYear}', this.form.controls['document_cutOffDate'].value.getFullYear());
      }
    }
    if (output.nameDE.includes('{legalEntityName}')) {
      output.nameDE = output.nameDE.replace('{legalEntityName}', '');
      output.nameEN = output.nameEN.replace('{legalEntityName}', '');
    }

    this.form.patchValue({
      ['id_notificationName']: this.form.controls['id_notificationName'].value,
    });

    return output;
  }



}
