
import { Injectable } from '@angular/core';

import { ListsService } from './lists.service';

import { DataService } from 'app/_services/StareApi/data.service';



@Injectable({
  providedIn: 'root'
})

export class StareInputFieldsService {



  constructor(
    private listsService: ListsService,
    private apidataService: DataService
  ) {

  }

  /** Return the german label of a xbrl name */
  public getXbrlLabel(xbrlName: string): string {
    const xbrlType = this.listsService.xbrlTypes.find(x => x.name == xbrlName);
    if (xbrlType) {
      return xbrlType.labelDE ?? 'Empty labelDE: ' + xbrlType.name;
    }
    return 'Not found: ' + xbrlName;
  }



  public assignClassByDatasetIDandXbrlName(datasetId: number, xbrl: string): string {


    const msg = this.apidataService.GetValidationErrorListForFields().values().find((item) => (item.datasetId == datasetId && item.xbrlName == xbrl));

    if (msg != undefined)
      return 'red-border-class';
    else
      return ' ';
  }

  /** Unselect for Reference fields*/
  public handleUnselect(): void {

    let selectedForms = document.querySelectorAll('.shrink-border-active');
    selectedForms.forEach((form) => {
      form.classList.replace('shrink-border-active', 'shrink-border');
    });
    selectedForms = document.querySelectorAll('.shrink-border-active-warning');
    selectedForms.forEach((form) => {
      form.classList.replace('shrink-border-active-warning', 'shrink-border');
    });
    selectedForms = document.querySelectorAll('.shrink-border-active-info');
    selectedForms.forEach((form) => {
      form.classList.replace('shrink-border-active-info', 'shrink-border');
    });


  }

  // == Buttons ==================================================================

  // allow list for add legal entity button
  private allowedToAdd: string[] = ["id_legalEntityKey",
    "document_corporateIncomeTaxSaUControllingCompany_controlledCompany_legalEntity",
    "document_corporateIncomeTaxSaUControllingCompany_controllingCompany_legalEntity"];

  /** True if a legal entity can be added to the current xbrl */
  public isAllowedToAdd(xbrl: string): boolean {
    return this.allowedToAdd.includes(xbrl);
  }

  // block list for reference check disable button
  private blockedXbrls: string[] = ["id_legalEntityKeyReference",
    "reference_communeAGS",
    "reference_companyIdentifier",
    "reference_assessmentYear",
    "realEstate_effectiveDate",
    "document_realEstate_propertyTax_referenceValueBaseAmount",
    "document_realEstate_propertyTax_referenceValueCommuneRateFactor",
    "corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber",
    "document_tradeTax_commune_name",
    "corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber"];

  /** True if current xbrl is blocked from reference disabling */
  public isBlocked(xbrl: string): boolean {
    return this.blockedXbrls.includes(xbrl);
  }

}


