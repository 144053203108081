import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import {
  DialogRef, DialogContentBase
} from "@progress/kendo-angular-dialog";
import { LegalEntityForUpdateItem } from 'app/_models/legal-entity.model';

@Component({
  selector: 'app-legalentity-add-dialog',
  templateUrl: './legalentity-add-dialog.component.html',
  styleUrl: './legalentity-add-dialog.component.scss',
  standalone: false
})
export class LegalentityAddDialogComponent extends DialogContentBase implements OnInit {

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisLegalEntityKey: string = "";


  @Output() public submitClick: EventEmitter<LegalEntityForUpdateItem> = new EventEmitter();

  // ======================================================================
  constructor(

    public dialog: DialogRef,
  ) { super(dialog); }


  ngOnInit(): void { }

  // == Forms ==============================================================
  public editForm: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    userCode: new FormControl(""),
    userNumber: new FormControl(""),
    clientContact: new FormControl(""),
    mailTemplate: new FormControl(""),
    wbs: new FormControl([]),
    inScope: new FormControl(true)
  });

  submit(): void {

    let updateLegalEntityObject = {} as LegalEntityForUpdateItem;
    updateLegalEntityObject = {
      name: this.editForm.value.name,
      prId: this.editForm.value.prId,
      userNumber: this.editForm.value.userNumber == '' ? null : this.editForm.value.userNumber,
      userCode: this.editForm.value.userCode == '' ? null : this.editForm.value.userCode,
      inScope: this.editForm.value.inScope ? true : false
    };
    // console.log("name" + this.editForm.value.name)
    const dataToSend: LegalEntityForUpdateItem = updateLegalEntityObject;
    this.submitClick.emit(dataToSend);
    this.dialog.close();
  }


  // == Handlers ===========================================================
  public addHandler(): void {
    // Reset form fields
    this.editForm.reset();
    this.editForm.patchValue({
      inScope: true
    })
    this.isNew = true;
    this.active = true;
    this.thisLegalEntityKey = "";
  }

  public closeForm(): void {
    this.active = false;
    this.dialog.close();
  }

}
