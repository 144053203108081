import { MyTaskItem } from "app/_models/task.model";
import { GridSettings } from "../_models/kendo-grid-settings.model";
import { Injectable } from "@angular/core";

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: object | null) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            //for compiler (kendo code)
            key = key;
            seen.add(value);
        }
        return value;
    };
};

@Injectable()
export class StatePersistingService {
    // want to delete all settings prior to this date (if update on 30.07.2024 set the lastUpdateDate to 29.07.2024) 
    // update here if changes on the settings are implemented
    public lastUpdateDateForSettings = new Date("2025-01-12T14:00:00Z");

    public get<T>(token: string): T {
        const settings = localStorage.getItem(token);
        return settings ? JSON.parse(settings) : settings;
    }

    public set(token: string, gridConfig: GridSettings<MyTaskItem>): void {
        localStorage.setItem(
            token,
            JSON.stringify(gridConfig, getCircularReplacer())
        );
    }

    public updateAll(): void {
        this.updateSetting('tasksGridSettings');
        this.updateSetting('tasksAllGridSettings');

        this.updateSetting('tasksAllForSDCGridSettings');
        this.updateSetting('archiveGridSettings');
        this.updateSetting('adminArchivGridSettings');

    }

    public updateSetting(token: string): void {
        const currentSetting: GridSettings<MyTaskItem> = this.get(token);
        if (currentSetting) {
            currentSetting.creationDate = new Date();
            currentSetting.state.skip = 0;
            this.set(token, currentSetting);
        } else {
            // console.log("No Setting found ");
        }
    }

    public checkAndUpdateIfAvailable(token: string, gridConfig: GridSettings<MyTaskItem>): void {

        const currentSettings: GridSettings<MyTaskItem> = this.get(token);
        if (currentSettings) {
            const testDate = new Date(currentSettings.creationDate);
            if (testDate < this.lastUpdateDateForSettings) {

                this.set(token, gridConfig);
            } else {
                //do nothing
            }
        } else {
            this.set(token, gridConfig);
        }

    }

    public clearAll(): void {

        localStorage.removeItem('tasksGridSettings');
        localStorage.removeItem('tasksAllGridSettings');

        localStorage.removeItem('tasksAllForSDCGridSettings');
        localStorage.removeItem('archiveGridSettings');

        localStorage.removeItem('adminArchivGridSettings');

    }

    public clearAllFilters(): void {

        this.clearFilters('tasksGridSettings');
        this.clearFilters('tasksAllGridSettings');
        this.clearFilters('tasksAllForSDCGridSettings');
        this.clearFilters('archiveGridSettings');

        this.clearFilters('adminArchivGridSettings');

    }

    public clearFilters(token: string): void {

        const currentSetting: GridSettings<MyTaskItem> = this.get(token);
        if (currentSetting) {
            currentSetting.creationDate = new Date();
            currentSetting.state.skip = 0;
            currentSetting.state.filter = {
                logic: 'and',
                filters: []
            };
            this.set(token, currentSetting);
        } else {
            // console.log("No Setting found ");
        }

    }


}