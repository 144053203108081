import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SVGIcon, arrowsSwapIcon } from "@progress/kendo-svg-icons";
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';

@Component({
  selector: 'app-userpreferences',
  templateUrl: './userpreferences.component.html',
  styleUrls: ['./userpreferences.component.scss'],
  standalone: false
})
export class UserpreferencesComponent implements OnInit {

  public swapSVG: SVGIcon = arrowsSwapIcon;

  public isLanguageGerman: boolean = this.translateService.currentLang == 'de';
  public activeHiddenTasks: boolean = localStorage.getItem('show_hidden_tasks') === "false" || null ? false : true;


  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private notificationService: WrapperNotificationService,
  ) { }

  ngOnInit(): void {

    this.translateService.get('APP.USER_PREFERENCES.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });


  }


  toggleGerman(event: boolean): void {
    this.isLanguageGerman = event;
  }

  toggleHiddenTasks(event: boolean): void {
    this.activeHiddenTasks = event;
  }

  saveSettings(): void {
    // Speichern Sie die aktuellen Einstellungen

    localStorage.setItem('show_hidden_tasks', this.activeHiddenTasks ? 'true' : 'false');
    this.translateService.use(this.isLanguageGerman ? 'de' : 'en');
    localStorage.setItem('language', this.isLanguageGerman ? 'de' : 'en');

    // console.log('Settings saved:', settings);

    this.notificationService.showSuccess('Die Einstellungen wurden gespeichert.');
    // Implementieren Sie hier die Logik zum Speichern der Einstellungen
  }

}
