import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';

import { WorkflowCommentForCreationDto, WorkflowComment } from 'app/_models/workflow-comment.model';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiWorkflowCommentService {

  constructor(
    private http: HttpClient) { }

  /**
* API: Add a new comments for a workflow.
*/
  addComment(item: WorkflowCommentForCreationDto): Observable<WorkflowComment> {
    return this.http.post<WorkflowComment>(ENVIRONMENT.apiBaseUrl + 'WorkflowComment/AddAsync', item, HTTP_OPTIONS);
  }


  /**
   * API: Get all comments for a workflow.
   */
  getAllComments(workflowId: number): Observable<WorkflowComment[]> {
    return this.http.get<WorkflowComment[]>(ENVIRONMENT.apiBaseUrl + 'WorkflowComment/GetAllAsync/' + workflowId, HTTP_OPTIONS);
  }



}
