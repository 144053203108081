import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SortDescriptor } from "@progress/kendo-data-query";
import { ColumnMenuSettings, GridComponent, GridSize } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, checkIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { JobStatisticItem } from 'app/_models/job-statistic-item.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DateTimeService } from 'app/_services/date-time.service';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipService } from 'app/_services/tooltip.service';
import { ApiJobsService } from 'app/_services/StareApi/job.service';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';
import { ENVIRONMENT } from 'environments/environment';

@Component({
  selector: 'app-admin-jobs',
  templateUrl: './admin-jobs.component.html',
  styleUrls: ['./admin-jobs.component.scss'],
  standalone: false
})
export class AdminJobsComponent {

  public smallSize: GridSize = "small";
  /** Displayed (filtered) data */
  public tableData = [] as JobStatisticItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as JobStatisticItem[];

  public jobsCodeList = [] as DropDownListItem[];
  public hangfireBaseUrl = ENVIRONMENT.hangfireUrl + '/jobs/details/';

  /** API request is finished */
  public finishedLoading = false;
  public defaultItem: DropDownListItem = {
    labelEN: 'select item',
    labelDE: 'Bitte auswählen...',
    isDefault: false,
    isDeleted: false,
  };

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // =========================================================
  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiJobsService: ApiJobsService,
    private dateTimeService: DateTimeService,

    private wrapperNotificationService: WrapperNotificationService,
    public tooltipService: TooltipService
  ) { this.allData = this.allData.bind(this); }


  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.JOBS.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });

    this.loadData();

  }

  // == Resize Listener =============================================================================================================
  public pageHeight: number = window.innerHeight - 125;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  // == Item Change handling ================================================================
  //number of values returned from the backend
  //5000 as default
  public items = 5000;

  public changeItemNumber(value: number): void {
    this.items = value;
  }

  saveItemNumber(): void {
    this.loadData();
  }

  // == Load grid data ===================================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.apiJobsService.getAllJobsWithFilter(this.items, this.codeFilter, this.idFilter).subscribe({
      next: (res: JobStatisticItem[]) => {

        // Descripbe the jobCodeLabelDE from the jobCode
        if (res != null) {

          res.forEach((item: JobStatisticItem) => {
            if (item.createdDate) {
              //console.log(item.createdDate);
              item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;

            }
            if (item.updateDate) {
              item.updateDate = this.dateTimeService.convertUTCTimeToLocalTime(item.updateDate);
            }
            if (item.startedDate) {
              item.startedDate = this.dateTimeService.convertUTCTimeToLocalTime(item.startedDate);
            }
            if (item.finishedDate) {
              item.finishedDate = this.dateTimeService.convertUTCTimeToLocalTime(item.finishedDate);
            }
          });


          res.forEach((item: JobStatisticItem) => {
            item.jobCodeLabelDE = this.jobCodeToLabel(item.jobCode);

          });
        }


        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });

    this.jobsCodeList = this.jobsCodeListSource;


  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 20;




  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;
  public saveItemsSVG: SVGIcon = checkIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    //this.loadData();
  }


  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;

  public codeFilter: number | null = null;
  public idFilter: string | null = null;
  public clientEngagementFilter: string | null = null;
  //for Guid check
  public regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }


  public filterChange(filter: CompositeFilterDescriptor, column: string): void {
    this.currentFilter.filters.push(filter);
    if (column == "jobIdentifier") {
      this.idFilter = filter.filters.values().next().value.value;
    }
    if (column == "jobCodeLabelDE") {
      this.codeFilter = filter.filters.values().next().value.value;
    }
    if (column == "clientEngagementKey") {
      this.clientEngagementFilter = filter.filters.values().next().value.value;
    }
    this.loadData();
  }

  public handleJobCodeFilter(value: string): void {
    this.jobsCodeList = this.jobsCodeListSource.filter(
      (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onFilterID(value: string): void {

    this.idFilter = value;



  }

  public onFilterClientEngagement(value: string): void {

    this.clientEngagementFilter = value;


  }

  public onFilterCode(value: DropDownListItem): void {
    this.codeFilter = Number(value.value);

  }



  removeFilter(): void {
    // this.currentFilter = {
    //   logic: "or",
    //   filters: [],
    // }
    this.codeFilter = null;
    this.idFilter = null;

    this.clientEngagementFilter = null;
    this.loadData();
  }

  saveFilter(): void {
    if ((this.regex.test(this.clientEngagementFilter!) || this.clientEngagementFilter == null || this.clientEngagementFilter == "") && (this.regex.test(this.idFilter!) || this.idFilter == null || this.idFilter == "")) {
      this.loadData();
    } else if (!this.regex.test(this.clientEngagementFilter!)) {
      this.wrapperNotificationService.showWarning("Please enter a valid GUID for Client Engagement");
    } else if (!this.regex.test(this.idFilter!)) {
      this.wrapperNotificationService.showWarning("Please enter a valid GUID for Job Identifier");
    }

  }
  // == Helpers ======================================================================================================================================


  public jobsCodeListSource: DropDownListItem[] = [
    {
      labelEN: 'NA',
      labelDE: 'NA',
      value: 0,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Fristmanagement',
      labelDE: 'Fristmanagement',
      value: 1,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'PTA',
      labelDE: 'PTA',
      value: 2,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Fristmanagement + PTA',
      labelDE: 'Fristmanagement + PTA',
      value: 3,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Bescheidprüfung',
      labelDE: 'Bescheidprüfung',
      value: 4,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Fristmanagement + Bescheidprüfung',
      labelDE: 'Fristmanagement + Bescheidprüfung',
      value: 5,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'PTA + Bescheidprüfung',
      labelDE: 'PTA + Bescheidprüfung',
      value: 6,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Fristmanagement + PTA + Bescheidprüfung',
      labelDE: 'Fristmanagement + PTA + Bescheidprüfung',
      value: 7,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Daten-Import',
      labelDE: 'Daten-Import',
      value: 8,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Daten-Import (Hintergrund)',
      labelDE: 'Daten-Import (Hintergrund)',
      value: 16,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Daten-Export (Hintergrund)',
      labelDE: 'Daten-Export (Hintergrund)',
      value: 32,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Export Datalake',
      labelDE: 'Export Datalake',
      value: 33,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Mail senden',
      labelDE: 'Mail senden',
      value: 64,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Client Engagenment',
      labelDE: 'Client Engagenment',
      value: 128,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Update SDC User im CE',
      labelDE: 'Update SDC User im CE',
      value: 256,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'SDC Bescheidprüfung',
      labelDE: 'SDC Bescheidprüfung',
      value: 512,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'DATEV Update',
      labelDE: 'DATEV Update',
      value: 1024,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'DATEV Transaktion',
      labelDE: 'DATEV Transaktion',
      value: 1025,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Quality Check',
      labelDE: 'Quality Check',
      value: 2048,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Mail an Berufsträger',
      labelDE: 'Mail an Berufsträger',
      value: 4096,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Prüfungsprotokolle',
      labelDE: 'Prüfungsprotokolle',
      value: 8192,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Rebuild MyTaskMetadata',
      labelDE: 'Rebuild MyTaskMetadata',
      value: 16384,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Check DB migrations',
      labelDE: 'Check DB migrations',
      value: 32768,
      isDefault: false,
      isDeleted: false,
    },
    {
      labelEN: 'Rebuild Payments',
      labelDE: 'Rebuild Payments',
      value: 65536,
      isDefault: false,
      isDeleted: false,
    },
  ];

  public jobCodeToLabel(jobCode: number): string {
    if (jobCode == 0) {
      return "NA";
    } else if (jobCode == 1) {
      return "Fristmanagement";
    } else if (jobCode == 2) {
      return "PTA";
    } else if (jobCode == 3) {
      return "Fristmanagement + PTA";
    } else if (jobCode == 4) {
      return "Bescheidprüfung";
    } else if (jobCode == 5) {
      return "Fristmanagement + Bescheidprüfung";
    } else if (jobCode == 6) {
      return "PTA + Bescheidprüfung";
    } else if (jobCode == 7) {
      return "Fristmanagement + PTA + Bescheidprüfung";
    } else if (jobCode == 8) {
      return "Daten-Import";
    } else if (jobCode == 16) {
      return "Daten-Import (Hintergrund)";
    } else if (jobCode == 32) {
      return "Daten-Export (Hintergrund)";
    } else if (jobCode == 33) {
      return "Export Datalake";
    } else if (jobCode == 64) {
      return "Mail senden";
    } else if (jobCode == 128) {
      return "Client Engagenment";
    } else if (jobCode == 256) {
      return "Update SDC User im CE";
    } else if (jobCode == 512) {
      return "SDC Bescheidprüfung";
    } else if (jobCode == 1024) {
      return "DATEV Update";
    } else if (jobCode == 1025) {
      return "DATEV Transaktion";
    } else if (jobCode == 2048) {
      return "Quality Check";
    } else if (jobCode == 4096) {
      return "Mail an Berufsträger";
    } else if (jobCode == 8192) {
      return "Prüfungsprotokolle";
    } else if (jobCode == 16384) {
      return "Rebuild MyTaskMetadata";
    } else if (jobCode == 32768) {
      return "Check DB migrations";
    } else if (jobCode == 65536) {
      return "Rebuild Payments";
    }
    return "NA";
  }

  // public jobLabelToCode(jobLabel: string): number {
  //   if (jobLabel == "NA") {
  //     return 0;
  //   } else if (jobLabel == "Fristmanagement") {
  //     return 1;
  //   } else if (jobLabel == "PTA") {
  //     return 2;
  //   } else if (jobLabel == "Fristmanagement + PTA") {
  //     return 3;
  //   } else if (jobLabel == "Bescheidprüfung") {
  //     return 4;
  //   } else if (jobLabel == "Fristmanagement + Bescheidprüfung") {
  //     return 5;
  //   } else if (jobLabel == "PTA + Bescheidprüfung") {
  //     return 6;
  //   } else if (jobLabel == "Fristmanagement + PTA + Bescheidprüfung") {
  //     return 7;
  //   } else if (jobLabel == "Daten-Import") {
}
