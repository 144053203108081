import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT } from '../../environments/environment';

@Component({
	selector: 'app-access-denied',
	templateUrl: './access-denied.component.html',
	styleUrls: ['./access-denied.component.scss'],
	standalone: false
})
export class AccessDeniedComponent implements OnInit {


	constructor(
		private titleService: Title,
		private translateService: TranslateService) { }

	ngOnInit(): void {
		this.translateService.get('ERROR.ACCESS-DENIED.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});

		// No need to remove token from local storage as API does not redirect with fragment
	}

	// == Reload app and automatically retry auth process ============================
	reloadApp(): void {
		window.location.href = ENVIRONMENT.primaryDomain;
		//window.location.reload();
		//this.router.navigate([`${ENVIRONMENT.apiBaseUrl}/Auth/Login`]);
	}

}