import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DatasetItem } from 'app/_models/dataset.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-property-tax1300',
  templateUrl: './doc-property-tax1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocPropertyTax1300Component extends DocBaseComponent {


  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.propertyTaxForm1300;
  }


  // public documentPropertyTaxItemPaymentPayloadLength: number = 0;
  // public documentPropertyTaxItemPayloadLength: number = 0;
  // public documentPropertyDueDatePaymentPayloadLength: number = 0;



  //#region Form ======================================================================================================

  public propertyTaxForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    document_auditResult: new UntypedFormControl(""),
    documentPayload: new UntypedFormArray([]),
    // id_communeAGS : new UntypedFormControl(""),
    document_realEstate_futurePayment0215: new UntypedFormControl(""),

    document_realEstate_futurePayment0515: new UntypedFormControl(""),
    document_realEstate_futurePayment0815: new UntypedFormControl(""),
    document_realEstate_futurePayment1115: new UntypedFormControl(""),
    document_realEstate_futurePayment0701: new UntypedFormControl(""),

    document_realEstate_hasOtherRealEstateTaxes: new UntypedFormControl(""),
    document_realEstate_sumOfPropertyTaxValue: new UntypedFormControl(""),
    document_realEstate_sumOfAmountToPaid: new UntypedFormControl(""),
    document_hasThresholdLimit: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    id_legalEntityKeyReference: new UntypedFormControl(""), //referenz legalentity
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),

    document_realEstate_reminderFeeAmountToPaid: new UntypedFormControl(""),
    document_realEstate_reminderFeeAmountToPaidDate: new UntypedFormControl(""),
    document_realEstate_reminderFeeAssessmentYear: new UntypedFormControl(""),
    document_missingBankAccountDetails: new UntypedFormControl(""),
  });
  //#endregion

  /** creates initial payloads for Grundsteuer: Übersicht der Einträge Grundstücke (Payload): "DocumentPropertyTaxItem" */
  createPropertyTaxItemsPayload(datasetId: number, crossReference?: string): UntypedFormGroup {

    const communePayload = new UntypedFormGroup({
      id_companyIdentifier: new UntypedFormControl(""),
      id_notificationDate: new UntypedFormControl(""),
      id_communeAGS: new UntypedFormControl(""),
      documentNestedPayload: new UntypedFormArray([]),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),
      crossReference: new UntypedFormControl(crossReference, { initialValueIsDefault: true }),
      dataTypeName: new UntypedFormControl('DocumentPropertyTaxItem', { initialValueIsDefault: true }),
    });

    // console.log("Adding non empty payload with datasetId: " + datasetId);
    // console.log("Adding non empty payload with crossReference: " + crossReference);
    // console.log(communePayload);
    return communePayload;
  }

  /** creates initial payloads for Grundsteuer: Grundstücke (Payload) -> Grundsteuer (Payload): "DocumentPropertyTaxItemPayment" -> "child" Payload */
  createPropertyTaxItemsPaymentPayload(datasetId: number, crossReference?: string): UntypedFormGroup {
    // console.log("Debug for payload" + crossReference);
    const communePayload = new UntypedFormGroup({
      id_assessmentYear: new UntypedFormControl(""),
      document_isTermination: new UntypedFormControl(""),
      document_realEstate_propertyTax_value: new UntypedFormControl(""),
      document_realEstate_propertyTax_referenceValue: new UntypedFormControl(""),
      document_realEstate_propertyTax_referenceValueBaseAmount: new UntypedFormControl(""),
      document_realEstate_propertyTax_referenceValueCommuneRateFactor: new UntypedFormControl(""),
      document_hasThresholdLimit: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),
      crossReference: new UntypedFormControl(crossReference, { initialValueIsDefault: true }),
      dataTypeName: new UntypedFormControl('DocumentPropertyTaxItemPayment', { initialValueIsDefault: true }),
    });

    // console.log("Adding non empty payload");
    // console.log("Adding non empty payload with crossReference: " + crossReference);
    // console.log(datasetId);
    return communePayload;
  }

  /** creates initial payloads for  Grundsteuer: Fälligkeiten(Paylod): "DocumentPropertyDueDatePayment" */
  createPropertyDueDatePaymentPayload(datasetId: number): UntypedFormGroup {

    const communePayload = new UntypedFormGroup({
      id_companyIdentifier: new UntypedFormControl(""),
      id_notificationDate: new UntypedFormControl(""),
      document_realEstate_amountToPaid: new UntypedFormControl(""),
      document_realEstate_amountToPaidDate: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),
      dataTypeName: new UntypedFormControl('DocumentPropertyDueDatePayment', { initialValueIsDefault: true }),
    });

    // console.log("Adding non empty payload");
    // console.log(datasetId);
    return communePayload;
  }

  //#endregion

  //initializes the payload form array
  //switch case for diff datatypes
  addSingularPayload(payload: DatasetItem): void {

    switch (payload.dataTypeName) {

      case "DocumentPropertyTaxItem": {
        this.documentPayloadGetter().push(this.createPropertyTaxItemsPayload(payload.id, payload.crossReference));
        console.info("Add" + payload.dataTypeName);
        // this.documentPropertyTaxItemPayloadLength = this.documentPropertyTaxItemPayloadLength + 290;

        break;
      }
      case "DocumentPropertyTaxItemPayment": {
        const newPayloadArray = this.documentNestedPayloadGetter(this.documentPayloadGetter(), payload.crossReference);
        if (newPayloadArray) {
          newPayloadArray.push(this.createPropertyTaxItemsPaymentPayload(payload.id, payload.crossReference));
          console.info("Add " + payload.dataTypeName);

          // this.documentPropertyTaxItemPaymentPayloadLength = this.documentPropertyTaxItemPaymentPayloadLength + 503;
        }
        break;
      }
      case "DocumentPropertyDueDatePayment": {

        this.documentPayloadGetter().push(this.createPropertyDueDatePaymentPayload(payload.id));
        console.info("Add" + payload.dataTypeName);
        // this.documentPropertyDueDatePaymentPayloadLength = this.documentPropertyDueDatePaymentPayloadLength + 200;
        break;
      }
      default: {
        console.error('Unbekannt payload type for add payload');
        break;
      }
    }
  }


  addPayload(dataType: string, crossReference?: string): void {
    const payloadArray = this.documentPayloadGetter();
    switch (dataType) {


      case "DocumentPropertyTaxItem": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
          next: (data) => {
            const newPayload = data as FormFieldItem[];
            const newDatasetID = newPayload[0].datasetId;
            let newCrossRef;
            this.getDataSetByIdAsync(newDatasetID).subscribe({
              next: (dataset) => {
                const response = dataset as DatasetItem;
                newCrossRef = response.crossReference;
                console.info("newCrossRef in next" + newCrossRef);
                if (newDatasetID != null) {
                  this.documentPayloadGetter().push(this.createPropertyTaxItemsPayload(newDatasetID, newCrossRef));
                  //this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                  this.documentPayloadDatasetIdArray.push(newDatasetID);
                  // this.documentPropertyTaxItemPayloadLength = this.documentPropertyTaxItemPayloadLength + 290;
                }
              }, error: (err: HttpErrorResponse) => {
                //this.showError('Fehler beim Hinzufügen: ' + err.message)
                console.error(err);
              }
            })

          },
          error: (err: HttpErrorResponse) => {
            //this.showError('Fehler bei Hinzufügen: ' + err.message)
            console.error(err);
          }

        });

        break;
      }

      case "DocumentPropertyTaxItemPayment": {
        this.addPayloadAsync(dataType, this.workflowId, crossReference).subscribe({
          next: (data) => {
            const newPayload = data as FormFieldItem[];
            const newDatasetID = newPayload[0].datasetId;
            // console.log("Adding payment crossReference: " + crossReference);
            if (newDatasetID != null) {
              const newPayloadArray = this.documentNestedPayloadGetter(payloadArray, crossReference);
              if (newPayloadArray) {
                newPayloadArray.push(this.createPropertyTaxItemsPaymentPayload(newDatasetID, crossReference));

                //this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                this.documentPayloadDatasetIdArray.push(newDatasetID);

                // this.documentPropertyTaxItemPaymentPayloadLength = this.documentPropertyTaxItemPaymentPayloadLength + 503;
              }
            }
          },
          error: (err: HttpErrorResponse) => {
            //this.showError('Fehler bei Hinzufügen: ' + err.message)
            console.error(err);
          }

        });
        break;
      }
      case "DocumentPropertyDueDatePayment": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
          next: (data) => {
            // console.log("Adding payment");
            const newPayload = data as FormFieldItem[];
            const newDatasetID = newPayload[0].datasetId;
            if (newDatasetID != null) {
              this.documentPayloadGetter().push(this.createPropertyDueDatePaymentPayload(newDatasetID));
              //this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
              this.documentPayloadDatasetIdArray.push(newDatasetID);
              // this.documentPropertyDueDatePaymentPayloadLength = this.documentPropertyDueDatePaymentPayloadLength + 200;
            }
          },
          error: (err: HttpErrorResponse) => {
            //this.showError('Fehler bei Hinzufügen: ' + err.message)
            console.error(err);
          }

        });
        break;
      }
      default:
        console.error('Unbekannt payload type for add payload');
        break;
    }

  }


}
