import { ValidationDeactivationItem } from "./validation-deactivation.model";
import { ValidationMessageItem } from "./validation-message.model";

export interface ICollection<T> {
    add(key: string, value: T): void;
    containsKey(key: string): boolean;
    item(key: string): T;
    keys(): string[];
    values(): T[];
}

export class Collection<T> implements ICollection<T> {

    private items: { [index: string]: T } = {};

    public containsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    public add(key: string, value: T): void {
        this.items[key] = value;
    }
    public item(key: string): T {
        return this.items[key];
    }

    public keys(): string[] {
        const keySet: any[] = [];

        for (const item in this.items) {
            if (this.items.hasOwnProperty(item)) {
                keySet.push(item);
            }
        }

        return keySet;
    }

    public values(): T[] {
        const values: T[] = [];

        for (const prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }

        return values;
    }

}
export const ValidationErrorListForControl = new Collection<ValidationMessageItem>();
export const ValidationWarningListForControl = new Collection<ValidationMessageItem>();
export const ValidationInfoListForControl = new Collection<ValidationMessageItem>();


export const ReferenceDisabledListForControl = new Collection<ValidationDeactivationItem>();