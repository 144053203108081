
import { HttpClient, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MailForSendingItem, MailForSendingUIItem } from 'app/_models/mail-for-sending.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { XbrlParserService } from '../xbrl-parser.service';
import { map } from 'rxjs/operators';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class MailSendingService {


  constructor(
    private http: HttpClient,
    private xbrlParserService: XbrlParserService) { }

  /**
 * API: Get  Mail item by id.
 */
  getClientMailById(id: number): Observable<MailForSendingItem> {
    return this.http.get<MailForSendingItem>(ENVIRONMENT.apiBaseUrl + 'MailSending/GetByIdAsync/' + id, HTTP_OPTIONS).pipe(
      map((data: MailForSendingItem) => {
        if (data.workflowItems) {
          for (const item of data.workflowItems) {
            if (item.validationMessageList) {
              for (const validationMessage of item.validationMessageList) {
                validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
              }
            }
          }
        }
        return data;
      }));
  }

  /**
* API: Get all mails max 500.
*/
  getAllForUI(): Observable<MailForSendingUIItem[]> {
    return this.http.get<MailForSendingUIItem[]>(ENVIRONMENT.apiBaseUrl + 'MailSending/GetAllForUI', HTTP_OPTIONS);
  }
  /**
* API: Refresh the Client Mail item.
*/
  resendMail(id: number): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(ENVIRONMENT.apiBaseUrl + 'MailSending/Resend/' + id, HTTP_OPTIONS);
  }


}
