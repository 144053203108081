
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonThemeColor } from '@progress/kendo-angular-buttons';
import { SVGIcon, trackChangesAcceptIcon, trackChangesRejectIcon } from '@progress/kendo-svg-icons';

import { DataService } from 'app/_services/StareApi/data.service';

@Component({
  selector: 'app-reference-disable-button',
  templateUrl: './reference-disable-button.component.html',
  styleUrl: './reference-disable-button.component.scss',
  standalone: false
})
export class ReferenceDisableButtonComponent {

  @Output() onReferenceDisable = new EventEmitter();

  //state of the button, not the of the reference check
  /** datasetId hedaer or payload */
  @Input() datasetIdInput: number = 0;
  /** formcontrol name*/
  @Input() xbrlNameInput: string = '';
  @Input() disabled: boolean = false;

  // use when the check is disabled and user wants to enable it
  public enableSVG: SVGIcon = trackChangesAcceptIcon;
  // use when the check is enabled and user wants to disable it
  public disableSVG: SVGIcon = trackChangesRejectIcon;

  public buttonClass: string = '';

  constructor(
    private apiDataService: DataService
  ) { }

  ngOnInit(): void {

    this.apiDataService.referenceDisableButtonState$.subscribe(() => {
      const msg = this.apiDataService.GetReferenceDisabledListForFields().values().find((item) => (item.datasetId == this.datasetIdInput && item.xbrl == this.xbrlNameInput));
      // console.log(this.xbrlNameInput);
      // console.log(msg);
      // reference is active -> button is disabled
      this.stateChangeOnSave(msg == undefined);
      // console.log(this.currentState);
    });

  }
  // == State Changes =====================================================


  // button disabled - default - reference is being checked
  // (click) -> selected
  disabledIcon: SVGIcon = this.enableSVG;
  disabledColor: ButtonThemeColor = 'primary';
  disabledTooltip: string = 'Prüfung aktiviert. (Klicke, um die Prüfung als deaktiviert zu markieren)';

  disabledArchivTooltip: string = 'Prüfung aktiviert.';

  // button selected - reference check is disabled but not saved
  // (click) -> disabled; (save) -> enabled
  selectedIcon: SVGIcon = this.disableSVG;
  selectedColor: ButtonThemeColor = 'warning';
  selectedTooltip: string = 'Prüfung als deaktiviert markiert. (Nach dem Speichern wird die Referenzprüfung deaktiviert)';

  // button enabled - reference check is disabled and saved
  // (click) -> deselected
  enabledIcon: SVGIcon = this.disableSVG;
  enabledColor: ButtonThemeColor = 'success';
  enabledTooltip: string = 'Prüfung deaktiviert. (Klicke, um die Prüfung als aktiviert zu markieren)';

  enabledArchivTooltip: string = 'Prüfung deaktiviert.';

  // button deselected - reference check is enabled but not saved
  // (click) -> enabled; (save) -> disabled
  deselectedIcon: SVGIcon = this.enableSVG;
  deselectedColor: ButtonThemeColor = 'warning';
  deselectedTooltip: string = 'Prüfung als aktiviert markiert. (Nach dem Speichern wird die Referenzprüfung aktiviert)';

  currentState: 'disabled' | 'selected' | 'enabled' | 'deselected' = 'disabled';
  //currently selected state values
  currentIcon: SVGIcon = this.disableSVG;
  currentColor: ButtonThemeColor = this.disabledColor;
  currentTooltip: string = this.disabledTooltip;
  // == Handlers ===========================================================
  public stateChange(): void {
    switch (this.currentState) {
      case 'disabled': {
        this.currentState = 'selected';
        this.currentIcon = this.selectedIcon;
        this.currentColor = this.selectedColor;
        this.currentTooltip = this.selectedTooltip;
        //will be set as activation of the button -> disables the reference check
        this.onReferenceDisable.emit(true);
        break;
      }
      case 'selected': {
        this.currentState = 'disabled';
        this.currentIcon = this.disabledIcon;
        this.currentColor = this.disabledColor;
        this.currentTooltip = this.disabledTooltip;
        //Remove xbrl to the list of disabled xbrls
        //will be set as activation of the button -> enables the reference check
        this.onReferenceDisable.emit(false);
        break;
      }
      case 'enabled': {
        this.currentState = 'deselected';
        this.currentIcon = this.deselectedIcon;
        this.currentColor = this.deselectedColor;
        this.currentTooltip = this.deselectedTooltip;
        //Remove xbrl to the list of disabled xbrls
        //will be set as activation of the button -> enables the reference check
        this.onReferenceDisable.emit(false);
        break;
      }
      case 'deselected': {
        this.currentState = 'enabled';
        this.currentIcon = this.enabledIcon;
        this.currentColor = this.enabledColor;
        this.currentTooltip = this.enabledTooltip;
        //Add xbrl to the list of disabled xbrls
        //will be set as activation of the button -> disables the reference check
        this.onReferenceDisable.emit(true);
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }

  // public getDisabledXbrlFields(): void { }

  public stateChangeOnSave(disableButton: boolean): void {
    //catch a successful saving event


    // console.log('stateChangeOnSave');
    if (disableButton) {
      this.currentState = 'disabled';
      this.currentIcon = this.disabledIcon;
      this.currentColor = this.disabledColor;
      this.currentTooltip = this.disabledTooltip;
      this.buttonClass = 'disabledState';
      if (this.disabled) {
        this.currentTooltip = this.disabledArchivTooltip;
      }

    } else {
      this.currentState = 'enabled';
      this.currentIcon = this.enabledIcon;
      this.currentColor = this.enabledColor;
      this.currentTooltip = this.enabledTooltip;
      this.buttonClass = 'enabledState';
      if (this.disabled) {
        this.currentTooltip = this.enabledArchivTooltip;
      }


    }
  }


}
