import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { FileRestrictions, SelectEvent, ErrorEvent } from '@progress/kendo-angular-upload';
import {
    hyperlinkOpenIcon,
    SVGIcon,
    trashIcon,
} from '@progress/kendo-svg-icons';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { UserLocal } from 'app/_models/user.model';
import { WorkflowAttachmentItem } from 'app/_models/workflow-attachment.model';
import { DataService } from 'app/_services/StareApi/data.service';
import { ApiFileService } from 'app/_services/StareApi/file.service';
import { ApiWorkflowAttachmentService } from 'app/_services/StareApi/workflow-attachment.service';
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';
import { ENVIRONMENT } from 'environments/environment';

@Component({
    selector: 'app-assessment-result-section',
    templateUrl: './assessment-result-section.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false,
})
export class AssessmentResultSectionComponent implements OnInit {
    /** datasetId hedaer or payload */
    @Input() datasetId: number = -1;
    @Input() workflowType: number = -1;
    @Input() workflowId: number = -1;
    @Input() form: UntypedFormGroup = new UntypedFormGroup({});

    @Input() auditResults: DropDownListItem[] = [];
    @Input() is1300: boolean = false;
    @Input() is2000: boolean = false;

    @Input() isArchiv: boolean = false;

    @Output() onFieldFocus = new EventEmitter();

    constructor(
        private notificationService: WrapperNotificationService,
        private apiWorkflowAttachmentService: ApiWorkflowAttachmentService,
        private fileservice: ApiFileService,
        private dataService: DataService
    ) {
        this.comments.sort((a, b) => a.text.localeCompare(b.text));
    }

    public attachments: WorkflowAttachmentItem[] = [];
    public fileName: string = '';

    public finishedLoading = false;
    public active: boolean = false;

    public updateActive: boolean = false;

    public currentUser: UserLocal = {} as UserLocal;

    public hyperlinkOpenIcon: SVGIcon = hyperlinkOpenIcon;

    public trashIcon: SVGIcon = trashIcon;

    ngOnInit(): void {
        // this.isDebug = localStorage.getItem('debug') == '1' ? true : false;
        // console.log(this.is2000);
        this.loadAttachments();
    }

    /** Triggered when the form fields are clicked on and should be focused on
     */
    public onFieldFocusReceiver(): void {
        this.onFieldFocus.emit();
    }

    // == Attachment =============================================================================================================

    public loadAttachments(): void {
        this.apiWorkflowAttachmentService
            .getAllAttachmentsByWorkflowId(this.workflowId)
            .subscribe((response: WorkflowAttachmentItem[]) => {
                this.attachments = response;
                this.finishedLoading = true;
            });
    }

    /** Add Document to the Protocol
     */
    public deleteAttachment(dataItem: WorkflowAttachmentItem): void {
        this.apiWorkflowAttachmentService
            .deleteAttachmentByWorkflowAttachmentId(dataItem.id)
            .subscribe(() => {
                this.notificationService.showSuccess(
                    'Die Datei wurde erfolgreich gelöscht.'
                );
                this.loadAttachments();
            });
    }

    /** Add Document to the Protocol
     */
    public openInNewTab(dataItem: WorkflowAttachmentItem): void {
        const f = dataItem.fileId;
        // const f = dataItem.id.toString();

        this.fileservice.downloadPdf(f).subscribe((res) => {
            const fileURL = URL.createObjectURL(res.blob);
            window.open(fileURL, '_blank');
        });
    }

    public cellClickHandler(args: CellClickEvent): void {
        args.sender.focus();
    }

    // === Uploader =================================

    hasValidationErrors: boolean = false;

    //TODO

    uploadSaveUrl =
        ENVIRONMENT.apiBaseUrl +
        'WorkflowAttachment/AddAsync?workflowId=' +
        this.workflowId +
        '&addToAuditProtocol=true&addToNotificationListing=false&name=' +
        this.fileName; // should represent an actual API endpoint
    // uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

    myRestrictions: FileRestrictions = {
        allowedExtensions: ['.pdf'],
        maxFileSize: 10 * 1024 * 1024,
    };

    uploadFailed(event: ErrorEvent): void {
        this.hasValidationErrors = true;

        // console.log(event);

        // Check event.response is TYPE OF HttpErrorResponse
        if (
            event.response instanceof HttpErrorResponse &&
            event.response.status === 405
        ) {
            this.notificationService.showError(
                'Der Import wurde für dieses Engagement deaktiviert. Ein manueller Upload ist nicht möglich. Bitte das DMS benutzen.'
            );
        } else if (event.response instanceof HttpErrorResponse) {
            this.notificationService.showError(
                'Der Import konnte nicht durchgeführt werden. Details: ' +
                event.response.error
            );
        }
    }

    uploadSuccess(): void {
        this.hasValidationErrors = false;
        this.notificationService.showSuccess(
            'Der Import wurde erfolgreich durchgeführt.'
        );
        this.ngOnInit();
    }
    public closeForm(): void {
        this.active = false;
    }

    openFileUploadModal(): void {
        this.active = true;
        this.addForm.reset();
        this.addForm.patchValue({
            worklflowId: this.workflowId,
        });
        this.addForm.patchValue({
            addToAuditProtocol: true,
        });
        this.addForm.patchValue({
            addToNotificationListing: false,
        });

        this.fileUploadModal.showModal();
    }

    closeFileUploadModal(): void {
        this.fileUploadModal.close();
        this.loadAttachments();
    }

    closeUpdateForm(): void {
        this.updateActive = false;
        this.fileUploadModal.close();
        this.loadAttachments();
    }


    // == Uploader =============================================================================================================

    customUploader = {
        maxFileSize: 10 * 1024 * 1024,
        autoUpload: false,
        authToken: 'Bearer ' + localStorage.getItem('stare_token') || '',
        //TODO
        url:
            ENVIRONMENT.apiBaseUrl +
            'WorkflowAttachment/AddAsync?workflowId=' +
            this.workflowId +
            '&addToAuditProtocol=true&addToNotificationListing=false&name=' +
            this.fileName,
    };


    @ViewChild('fileUploadModal', { static: true })
    fileUploadModal!: HTMLDialogElement;

    // == Forms ================================================================
    public addForm: FormGroup = new FormGroup({
        worklflowId: new FormControl(-1, Validators.required),
        name: new FormControl(''),
        addToAuditProtocol: new FormControl(true),
        addToNotificationListing: new FormControl(true),
    });

    public editForm: FormGroup = new FormGroup({
        name: new FormControl(''),
        addToAuditProtocol: new FormControl(true),
        addToNotificationListing: new FormControl(true),
    });

    // == Komments Dropdown list ================================================================

    public comments: { text: string, value: string }[] = [
        {
            text: 'Abweichung >= 10€',
            value: 'Die Werte stimmen nicht mit der Steuererklärung überein. Der XXX lt. Berechnungslisten ist gleich XXX €. Bitte prüfen.',
        },
        {
            text: 'Abweichung < 10€',
            value: 'Der Unterschied beträgt weniger als 10 €. Der Wert ist in Ordnung.',
        },
        {
            text: 'Keine Abweichung',
            value: 'Die Werte wurden manuell überprüft. L.t. Steuererklärung sind die Werte korrekt.',
        },
        {
            text: 'GewSt Abweichung',
            value: 'Die Werte stimmen nicht mit der Steuererklärung überein. Die Besteuerungsgrundlagen wurden korrekt aus dem Grundlagenbescheid (GewStMBZerl) übernommen. Der Grundlagenbescheid enthält jedoch Abweichungen zur eingereichten Steuererklärung.',
        },
        {
            text: 'Verspätungszuschlag nicht festgesetzt',
            value: 'Wie dem Bescheid zu entnehmen ist: "Ihre Steuererklärung ist verspätet eingegangen. Einen Verspätungszuschlag habe ich dieses Mal nicht festgesetzt. Falls Sie zur Abgabe einer Steuererklärung verpflichtet sind, müssen Sie jedoch zukünftig mit der Festsetzung eines Verspätungszuschlags rechnen, wenn Sie Ihre Steuererklärung nicht oder nicht fristgemäß abgeben. Das gilt auch dann, wenn Sie eine Erstattung erwarten."',
        },
        {
            text: 'Verspätungszuschlag festgesetzt',
            value: 'Wie dem Bescheid zu entnehmen ist: "Ich habe einen Verspätungszuschlag festgesetzt, weil Ihre Steuererklärung oder Steueranmeldung erst am XX.XX.XXXX eingegangen ist. Die Abgabefrist ist aber bereits am XX.XX.XXXX abgelaufen.“ ',
        },
        {
            text: 'Abweichendes Wirtschaftsjahr',
            value: 'Wie dem Bescheid zu entnehmen ist: "Der Gewerbebetrieb hat seit dem XX.XX.XXXX ein abweichendes Wirtschaftsjahr vom XX.XX bis zum XX.XX."',
        },
        {
            text: 'Anlage',
            value: 'Der Bescheid enthält eine Anlage. Diese Anlage wurde manuell mit der Steuererklärung geprüft.',
        },
        {
            text: 'Bereits getilgt',
            value: 'Bereits getilgte Fälligkeiten sind nicht Teil der SDC Bescheidprüfung und ggf. gesondert zu überprüfen.',
        },
        {
            text: 'Steuernummer',
            value: 'Die Steuernummer stimmt nicht mit der Steuererklärung überein. Die anderen Werte sind in Ordnung. Bitte überprüfen Sie die abweichende Steuernummer.',
        },
        {
            text: 'Gewerbesteuervorauszahlungen',
            value: 'Die Höhe der Vorauszahlungen für 202X wurde nicht an die aktuell festgesetzte Gewerbesteuer von XXX € angepasst. Sofern kein Antrag auf Anpassung der Vorauszahlungen gestellt wurde, ist die Höhe nicht korrekt.',
        },
        {
            text: 'Fehlender Wert',
            value: 'Der Wert XXX befindet sich nicht auf dem Bescheid. Bitte prüfen.',
        },
        {
            text: 'Teilweise geprüft',
            value: 'Die Werte XXX sind nicht Teil der SDC Bescheidprüfung und ggf. gesondert zu überprüfen.',
        },
        {
            text: 'Out of Scope',
            value: "Der Bescheid ist 'Out of Scope'.",
        },
        {
            text: 'Verrechnungsbeträge',
            value: 'Der fällige Betrag wurde mit dem Guthaben aus dem Bescheid XXX verrechnet.',
        },
        {
            text: 'GewSt VZ',
            value: 'Der Steuerbetrag für das vorherige Jahr ist nicht aus dem Bescheid ersichtlich, daher wurde hier X eingetragen.',
        },
        {
            text: 'GewStMb VZ 50€ abgerundet',
            value: 'Der Gewerbesteuermessbetrag für Vorauszahlungszwecke wurde auf die nächsten 50€ abgerundet. Aufgrund der Anpassung zu Gunsten des Mandanten wird der Bescheid als richtig unterstellt.',
        },
        {
            text: 'Keine Zinsen',
            value: 'Wie dem Bescheid zu entnehmen ist: "Ich habe keine Zinsen festgesetzt, weil sie weniger als 10 € betragen."',
        },
        {
            text: 'Verrechnung',
            value: 'Der fällige Betrag wurde mit dem Guthaben aus dem Bescheid … vom gleichen Tag verrechnet.',
        },
        {
            text: 'Konto ist ausgeglichen',
            value: 'Wie dem Bescheid zu entnehmen ist: "Ihr Konto ist ausgeglichen.“',
        },
        {
            text: 'Zu wenig Ertrag',
            value: 'Wie dem Bescheid zu entnehmen ist: "Verbleibende Beträge von insgesamt weniger als XXX,- € werden weder erhoben noch erstattet, weil dadurch unverhältnismäßige Kosten entstehen würden.“',
        },
        {
            text: 'Nachträgliche Körperschaftsteuervorauszahlung',
            value: 'Die nachträgliche Körperschaftsteuervorauszahlung für 2024 wurde unter Berücksichtigung eines voraussichtlichen Jahressteuerbetrags von XXX € {nach Verlustabzug / vor Verlustabzug / nach Abzug der bisher festgesetzten Vorauszahlungen / nach Abzug der anzurechnenden Körperschaftsteuer} ermittelt.',
        },
        {
            text: 'Grundlage GewSt',
            value: 'Die Grundlage ist der Gewerbesteuerbescheid aus dem Jahr XXX.',
        },
    ];

    defaultComment = { text: ' Kommentartext hinzufügen', value: null };

    public onDropdownSelectChange(selectedOption: { text: string, value: string }): void {
        this.addTextToComment(selectedOption.value);
    }
    private addTextToComment(text: string): void {
        const comment =
            this.form.controls['document_mail_reasonNotAccepted'].value;
        const newcomment = comment ? comment + ' ' + text : text;
        this.form.patchValue({
            ['document_mail_reasonNotAccepted']: newcomment,
        });
    }

    // == Handlers =================================================================
    //not in use for now (edit not needed currently)
    // public editHandler(activeRow: NavigationRow): void {
    //     // if (activeRow?.dataItem) {
    //     //   this.editForm.reset(activeRow.dataItem);
    //     //   this.updateActive = true;
    //     // }
    // }

    public onCancel(e: PointerEvent): void {
        e.preventDefault();
        this.loadAttachments();
        this.closeForm();
    }

    //Parse the file name for upload
    public onFileSelect(e: SelectEvent): void {
        // console.log(this.fileName);

        this.addForm.reset();
        this.fileName = e.files[0].name;
        this.addForm.patchValue({
            name: e.files[0].name,
        });
        this.uploadSaveUrl =
            ENVIRONMENT.apiBaseUrl +
            'WorkflowAttachment/AddAsync?workflowId=' +
            this.workflowId +
            '&addToAuditProtocol=true&addToNotificationListing=false&name=' +
            this.fileName; // should represent an actual API endpoint
        // console.log(this.fileName);
    }

    //not in use for now (edit not needed currently)
    public onSave(e: PointerEvent): void {
        //reset disabled wbs codes

        e.preventDefault();
        // this.loadAttachments();
        this.closeForm();
    }

    public requestedByAdmin(): boolean {
        return this.dataService.requestedByAdmin();
    }

    public isUnlocked(): boolean {
        return this.dataService.GetAdminArchivUnlockFlag();
    }
}
