import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';

import { SortDescriptor } from "@progress/kendo-data-query";
import { UserForCreationItem, UserForUpdateItem, UserItem, UserLocal } from '../_models/user.model';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { ApiUserService } from 'app/_services/StareApiMaintenance/user.service';

import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';
import { ENVIRONMENT } from 'environments/environment';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
  standalone: false
})
export class AdminUsersComponent implements OnInit {

  private _user: UserLocal = JSON.parse(localStorage.getItem('user')!) as UserLocal;
  /** Displayed (filtered) data */
  public tableData = [] as UserItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as UserItem[];
  /** API request is finished */
  public finishedLoading = false;

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  // =============================================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiUserService: ApiUserService,
    private notificationService: WrapperNotificationService,
    private apiAuthService: ApiAuthentificationService,
    public tooltipService: TooltipService
  ) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.USER_MANAGEMENT.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

  }
  // == Resize Listener =============================================================================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load grid data ===================================================================================
  loadData(): void {
    this.finishedLoading = false;

    this.apiUserService.getAllUsers().subscribe({
      next: (res: UserItem[]) => {
        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });


  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 20;



  // Icons 
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "mail",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {



      filter.filters.push({
        field: "id",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "employeeNumber",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "mail",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "firstname",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "lastname",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }

  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }


  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;

  // == Forms ======================================================================

  public editForm: FormGroup = new FormGroup({
    employeeNumber: new FormControl("", Validators.required),
    mail: new FormControl("", [Validators.required, Validators.pattern("^\\w+([-+.']\\w+)*@(pwc.de|pwc.com|pwcinternal.com)$")]),
    firstname: new FormControl("", [Validators.required, Validators.pattern("^([^0-9]*)$")]),
    lastname: new FormControl("", [Validators.required, Validators.pattern("^([^0-9]*)$")]),
    isAdmin: new FormControl(false),
    canManageCommuneTaxRates: new FormControl(false),
    canManageSdcUsers: new FormControl(false),
  });

  // == Handlers =======================================================================
  public editHandler(args: NavigationRow): void {

    if (args?.dataItem && !args?.dataItem.isSdcUser) {
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.thisId = args.dataItem.id;
    }

    else if (args?.dataItem && args?.dataItem.isSdcUser) {
      this.notificationService.showError('Der Benutzer ist ein SDC-Benutzer und kann nicht bearbeitet werden. Verwenden Sie "SDC Benutzerverwaltung".');
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.thisId = 0;
  }

  public closeForm(): void {
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }



  // == CRUD Operations ======================================================================

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    if (this.isNew) {

      // Map UserAccessForCreationItem from Form
      let userAccessForCreationItem = {} as UserForCreationItem;

      userAccessForCreationItem = {
        employeeNumber: this.editForm.value.employeeNumber,
        firstname: this.editForm.value.firstname,
        lastname: this.editForm.value.lastname,
        mail: this.editForm.value.mail,
        isAdmin: this.editForm.value.isAdmin ? true : false,
        canManageCommuneTaxRates: this.editForm.value.canManageCommuneTaxRates ? true : false,
        canManageSdcUsers: false,
      };

      //console.log(userAccessForCreationItem);

      // Send to API
      this.apiUserService.addUser(userAccessForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Anwender wurde erfolgreich angelegt.');

        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Der Anwender existiert bereits und konnte nicht (erneut) angelegt werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Anlegen eines Anwenders.');
          } else if (err.status == 405) {
            this.notificationService.showError('Der Benutzer ist ein SDC-Benutzer und kann nicht bearbeitet werden. Verwenden Sie "SDC Benutzerverwaltung".');
          } else if (err.status == 406) {
            this.notificationService.showError('Es wird versucht, eigenen Rechte zu erweitern');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {

          if (this._user.mail === this.editForm.value.mail) {
            this.apiAuthService.setRedirection(ENVIRONMENT.primaryDomain + "/admin-users", this._user.clientEngagementKey);
            const success = new Promise<boolean>(() => {
              this.apiAuthService.changeClientandRedirect();
            });
            success.then(() => {
              // handle result here
              this.apiAuthService.resetRedirection();
            });
          } else {
            this.loadData();
          }
        }
      });

    }
    else {
      // Update
      let userForUpdateItem = {} as UserForUpdateItem;
      userForUpdateItem = {
        firstname: this.editForm.value.firstname,
        lastname: this.editForm.value.lastname,
        mail: this.editForm.value.mail,
        mailDigestMode: false,
        mailTimeOfSending: 0,
        isAdmin: this.editForm.value.isAdmin ? true : false,
        canManageCommuneTaxRates: this.editForm.value.canManageCommuneTaxRates ? true : false,
        canManageSdcUsers: false,
      };

      //console.log(userForUpdateItem);

      this.apiUserService.updateUser(this.thisId, userForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Anwender wurde erfolgreich aktualisiert.');

        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Der Anwender existiert bereits und konnte nicht aktualisiert werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Aktualisieren eines Anwenders.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Anwender wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
          } else if (err.status == 405) {
            this.notificationService.showError('Der Benutzer ist ein SDC-Benutzer und kann nicht bearbeitet werden. Verwenden Sie "SDC Benutzerverwaltung".');
          } else if (err.status == 406) {
            this.notificationService.showError('Es wird versucht, eigenen Rechte zu erweitern');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          if (this._user.mail === this.editForm.value.mail) {
            this.apiAuthService.setRedirection(ENVIRONMENT.primaryDomain + "/admin-users", this._user.clientEngagementKey);
            const success = new Promise<boolean>(() => {
              this.apiAuthService.changeClientandRedirect();
            });
            success.then(() => {
              // handle result here
              this.apiAuthService.resetRedirection();
            });
          } else {
            this.loadData();
          }
        }
      });

    }

    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == 0) {
      return;
    }

    this.apiUserService.deleteUser(this.thisId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Der Anwender wurde erfolgreich gelöscht.');

      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen eines Anwenders.');
        } else if (err.status == 410) {
          this.notificationService.showError('Der Anwender wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else if (err.status == 405) {
          this.notificationService.showError('The user is a SDC user and cannot be deleted. Use "SDC Benutzerverwaltung".');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        if (this._user.mail === this.editForm.value.mail) {
          this.apiAuthService.setRedirection(ENVIRONMENT.primaryDomain + "/admin-users", this._user.clientEngagementKey);
          const success = new Promise<boolean>(() => {
            this.apiAuthService.changeClientandRedirect();
          });
          success.then(() => {
            // handle result here
            this.apiAuthService.resetRedirection();
          });
        } else {
          this.loadData();
        }

      }
    });

    this.closeForm();
  }

  public onResetClientEngagement(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == 0) {
      return;
    }

    this.apiUserService.resetClientEngagementByUser(this.thisId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Das zuletzt ausgewählte Client Engagement wurde zurückgesetzt.');
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);

        this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);

      },
      complete: () => {
        // Nothing to do here
      }
    });

  }

}
