import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserLocal } from '../_models/user.model';
import { UserAccessItem } from '../_models/user-access.model';
import { ENVIRONMENT } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SDCUserAdminGuard {

    public myuser: UserLocal = {} as UserLocal;
    public myAccessRights: UserAccessItem[] = {} as UserAccessItem[];

    constructor() { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const jsonStr = localStorage.getItem('user') || '';
        if (jsonStr.length > 0) {
            const jsonObj = JSON.parse(jsonStr);
            this.myuser = jsonObj as UserLocal;
            if (this.myuser.isAdmin) {
                return true;
            }

        }

        if (this.myuser.canManageSdcUsers) {
            return true;
        } else {
            window.location.href = ENVIRONMENT.primaryDomain + '/tasks';
            return false;
        }

    }

}