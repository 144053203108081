import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { SortDescriptor } from '@progress/kendo-data-query';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
    SVGIcon,
    arrowRotateCwIcon,
    fileExcelIcon,
    filterClearIcon,
    formElementIcon,
    plusIcon,
} from '@progress/kendo-svg-icons';
import {
    CellClickEvent,
    ColumnMenuSettings,

    GridComponent,

    NavigationRow,
} from '@progress/kendo-angular-grid';
import { UserLocal } from 'app/_models/user.model';
import {
    DatevMetadatenForCreationItem,
    DatevMetadatenForUpdateItem,
    DatevMetadatenItem,
} from 'app/_models/datev-metadaten.model';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { ListsService } from 'app/_services/lists.service';
import { ApiDatevService } from 'app/_services/StareApi/datev.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { Observable, of } from 'rxjs';
import { DateTimeService } from 'app/_services/date-time.service';

@Component({
    selector: 'app-setting-datevmetadaten',
    templateUrl: './setting-datevmetadaten.component.html',
    styleUrls: ['./setting-datevmetadaten.component.scss'],
    standalone: false
})
export class SettingDatevmetadatenComponent implements OnInit {
    public datevMetadaten$: Observable<DatevMetadatenItem[]> = of([]);

    public getDataSVG: SVGIcon = formElementIcon;
    public instanceList: DropDownStringItem[] = [
        {
            labelDE: 'Datev Nord',
            value: 'N',
            labelEN: 'Datev Nord',
            isDefault: false,
            isDeleted: false,
        },
        {
            labelDE: 'Datev Süd',
            value: 'S',
            labelEN: 'Datev Süd',
            isDefault: false,
            isDeleted: false,
        },
        {
            labelDE: 'Datev West',
            value: 'W',
            labelEN: 'Datev West',
            isDefault: false,
            isDeleted: false,
        },
        {
            labelDE: 'Datev Ost',
            value: 'O',
            labelEN: 'Datev Ost',
            isDefault: false,
            isDeleted: false,
        },
        {
            labelDE: 'Datev Mitte',
            value: 'M',
            labelEN: 'Datev Mitte',
            isDefault: false,
            isDeleted: false,
        },
    ];

    public legalEntities: DropDownStringItem[] = [];
    public legalEntitiesSource: DropDownStringItem[] = [];

    public myuser: UserLocal = {} as UserLocal;

    /** Displayed (filtered) data */
    public tableData = [] as DatevMetadatenItem[];
    /** Original data from API request */
    public tableDataOriginal = [] as DatevMetadatenItem[];
    /** API request is finished */
    public finishedLoading = false;

    // == ToolTips ===========================================================
    @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
    // =======================================================================

    constructor(
        private titleService: Title,
        public translateService: TranslateService,
        private listsService: ListsService,
        private apiDatevService: ApiDatevService,
        private notificationService: WrapperNotificationService,
        public tooltipService: TooltipService,
        private dateTimeService: DateTimeService,
    ) { }

    ngOnInit(): void {
        this.translateService
            .get('APP.SETTINGS.DATEV_METADATEN.TAB')
            .subscribe((title: string) => {
                this.titleService.setTitle('STARE | ' + title);
            });

        const jsonStr = localStorage.getItem('user') || '';
        if (jsonStr.length > 0) {
            const jsonObj = JSON.parse(jsonStr);
            this.myuser = jsonObj as UserLocal;
        }
        this.listsService.GetLegalEntitiesList().subscribe((data) => {
            // Order data by labelDE. Ignore case and german umlauts
            data.sort((a, b) =>
                a.labelDE.localeCompare(b.labelDE, 'de', {
                    sensitivity: 'base',
                })
            );

            this.legalEntitiesSource = data as DropDownStringItem[];

            data.forEach((item: DropDownStringItem) => {
                if (item.isDeleted) {
                    item.labelDE = item.labelDE + ' (gelöscht)';
                }
            });
            this.legalEntities = this.legalEntitiesSource.slice();
        });
        this.loadData();
    }

    // == Resize the Grid =================================================================

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.pageHeight = window.innerHeight - 125;
        //console.log(this.pageHeight);
    }

    public pageHeight: number = window.innerHeight - 125;

    // == Load Data =============================================================================

    loadData(): void {
        this.finishedLoading = false;

        this.apiDatevService.getAllDatevmetadaten().subscribe({
            next: (res: DatevMetadatenItem[]) => {
                // BUG Fix 23692 legal entity col empty
                res.forEach((item: DatevMetadatenItem) => {
                    if (item.legalEntityKey) {
                      item.legalEntityKeyForDisplay = this.getlegalentityNamefromKey(item.legalEntityKey);
                    }
                    if (item.lastImportDate) {
                        item.lastImportDate = this.dateTimeService.convertUTCTimeToLocalTime(item.lastImportDate)!;
                    }
          
                  });
                this.tableData = res;
                this.tableDataOriginal = res;
                this.finishedLoading = true;
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);
                this.finishedLoading = true;
            },
        });
    }

    public getReferenceData(): void {
        // console.log('Get Datev ref Data');

        // getDatevReferenceData
        this.apiDatevService.getDatevReferenceData().subscribe({
            next: () => {
                // console.log(res);
                this.notificationService.showSuccess(
                    'Das Laden der DATEV Referenzdaten wurde in Auftrag gegeben. Der Import kann einige Minuten benötigen.'
                );
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);
                if (err.status == 400) {
                    this.notificationService.showError(
                        'Die Daten konnten nicht verarbeitet werden.'
                    );
                } else {
                    this.notificationService.showError(
                        `Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`
                    );
                }
            },
            complete: () => {
                // Nothing to do here
            },
        });
    }

    // == Grid Settings =======================================================================================================================================

    // Pagination

    public pageSize = 20;

    // Icons
    public excelSVG: SVGIcon = fileExcelIcon;
    public reloadSVG: SVGIcon = arrowRotateCwIcon;
    public addSVG: SVGIcon = plusIcon;
    public filterClearSVG: SVGIcon = filterClearIcon;

    //Settings for individual columns
    public menuSettings: ColumnMenuSettings = {
        lock: false,
        stick: false,
        view: 'tabbed',
        filter: true,
    };

    // Sorting
    public currentSort: SortDescriptor[] = [
        {
            field: 'clientnumber',
            dir: 'asc',
        },
    ];

    public sortChange(sort: SortDescriptor[]): void {
        this.currentSort = sort;
        //this.loadData();
    }

    // Export zu Excel
    // public exportToExcel(grid: GridComponent): void {
    //   grid.saveAsExcel();
    // }

    // == Filter ======================================================================================================================================

    @ViewChild("grid", { static: false })
    grid!: GridComponent;

    public currentFilter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };


    removeFilter(): void {
        this.currentFilter = {
            logic: 'or',
            filters: [],
        };
    }

    // == Modal ======================================================================================================================================

    /** Show Modal-Dialog. */
    public active: boolean = false;
    /** New DataItem will be added. */
    public isNew: boolean = false;
    /** This DataItem ID is showing in Modal-Dialog. */
    public thisId: number = -1;

    // == Form =========================================================================
    public editForm: FormGroup = new FormGroup({
        legalEntityKey: new FormControl('', Validators.required),
        auditnumber: new FormControl('', Validators.required),
        clientnumber: new FormControl('', Validators.required),
        basenumber: new FormControl(null),
        instance: new FormControl('', Validators.required),
        assessmentYearFrom: new FormControl('', Validators.required),
        datapath: new FormControl(''),
    });

    // == Handler =============================================================
    public editHandler(args: NavigationRow): void {
        if (args?.dataItem) {
            this.editForm.reset(args.dataItem);
            this.isNew = false;
            this.active = true;
            this.thisId = args.dataItem.id;
        }
    }

    public cellClickHandler(args: CellClickEvent): void {
        args.sender.focus();
    }

    public addHandler(): void {
        // Reset form fields
        this.editForm.reset();
        this.isNew = true;
        this.active = true;
        this.thisId = 0;
    }

    public closeForm(): void {
        this.active = false;
    }

    public onCancel(e: PointerEvent): void {
        e.preventDefault();
        this.closeForm();
    }

    // == Helpers ================================================================================

    getlegalentityReferenceNamefromGuid(guid: string): Promise<string> {
        return new Promise((resolve) => {
            const legalentityName = this.legalEntities.find(
                (item) => item.value === guid
            );
            if (legalentityName) {
                resolve(legalentityName.labelDE);
            } else {
                resolve(''); // or reject("Entity not found")
            }
        });
    }
    getlegalentityNamefromKey(key: string): string {
        const legalentityName = this.legalEntities.find((item) => (item.value == key));
        if (legalentityName) {
          if (legalentityName.isDeleted) {
            return legalentityName.labelDE + ' (gelöscht)';
          }
          return legalentityName?.labelDE;
        }
        else {
          return "";
        }
      }

    getlegalentityReferenceNameforDisplay(guid: string): string {
        const legalentityName = this.legalEntities.find(
            (item) => item.value === guid
        );
        if (legalentityName) {
            return legalentityName.labelDE;
        } else {
            return 'Unknown Entity';
        }
    }

    // == CRUD Operations ================================================================================

    public onSave(e: PointerEvent): void {
        e.preventDefault();

        if (this.isNew) {
            // Map creationItem from Form
            let itemForCreationItem = {} as DatevMetadatenForCreationItem;

            itemForCreationItem = {
                legalEntityKey: this.editForm.value.legalEntityKey,
                auditnumber: this.editForm.value.auditnumber,
                clientnumber: this.editForm.value.clientnumber,
                instance: this.editForm.value.instance,
                assessmentYearFrom: this.editForm.value.assessmentYearFrom,
                datapath: this.editForm.value.datapath,
                basenumber:
                    this.editForm.value.basenumber == null ||
                        this.editForm.value.basenumber == 0
                        ? null
                        : this.editForm.value.basenumber,
            };

            // Send to API
            this.apiDatevService
                .addDatevMetadaten(itemForCreationItem)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess(
                            'Die DATEV Metadaten wurde erfolgreich angelegt.'
                        );
                        this.loadData();
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                        if (err.status == 400) {
                            this.notificationService.showError(
                                'Die Daten konnten nicht verarbeitet werden.'
                            );
                        } else if (err.status == 406) {
                            this.notificationService.showError(
                                'Es existiert bereits ein identischer Eintrag für dieses oder ein vorheriges Jahr. Die Daten werden bereits importiert.'
                            );
                        } else if (err.status == 418) {
                            this.notificationService.showError(
                                'Das angegebene Jahr liegt außerhalb des erlaubten Bereichs.'
                            );
                        } else {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`
                            );
                        }
                    },
                    complete: () => {
                        // Nothing to do here
                    },
                });
        } else {
            // Update
            let itemForUpdateItem = {} as DatevMetadatenForUpdateItem;
            itemForUpdateItem = {
                legalEntityKey: this.editForm.value.legalEntityKey,
                auditnumber: this.editForm.value.auditnumber,
                clientnumber: this.editForm.value.clientnumber,
                instance: this.editForm.value.instance,
                assessmentYearFrom: this.editForm.value.assessmentYearFrom,
                datapath: this.editForm.value.datapath,
                basenumber:
                    this.editForm.value.basenumber == null ||
                        this.editForm.value.basenumber == 0
                        ? null
                        : this.editForm.value.basenumber,
            };

            // console.log(itemForUpdateItem);

            this.apiDatevService
                .updateDatevMetadaten(this.thisId, itemForUpdateItem)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess(
                            'Die DATEV Metadaten wurde erfolgreich aktualisiert.'
                        );
                        this.loadData();
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                        if (err.status == 400) {
                            this.notificationService.showError(
                                'Die Daten konnten nicht verarbeitet werden.'
                            );
                        } else if (err.status == 406) {
                            this.notificationService.showError(
                                'Es existiert bereits ein identischer Eintrag für dieses oder ein vorheriges Jahr. Die Daten werden bereits importiert.'
                            );
                        } else if (err.status == 418) {
                            this.notificationService.showError(
                                'Das angegebene Jahr liegt außerhalb des erlaubten Bereichs.'
                            );
                        } else {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`
                            );
                        }
                    },
                    complete: () => {
                        // Nothing to do here
                    },
                });
        }

        this.closeForm();
    }

    public onDelete(e: PointerEvent): void {
        e.preventDefault();

        if (this.thisId == 0) {
            return;
        }

        this.apiDatevService.deleteDatevMetadaten(this.thisId).subscribe({
            next: () => {
                this.notificationService.showSuccess(
                    'Die DATEV Metadaten wurde erfolgreich gelöscht.'
                );
                this.loadData();
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);
                this.notificationService.showError(
                    `Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`
                );
            },
            complete: () => {
                // Nothing to do here
            },
        });

        this.closeForm();
    }
}
