import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportScope } from 'app/_models/export-scope.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiClientEngagementService {

  constructor(
    private http: HttpClient) { }


  /**
   * API: Data Export.
   */
  dataExport(typeId: number): Observable<ExportScope> {
    return this.http.post<ExportScope>(ENVIRONMENT.apiBaseUrl + 'ClientEngagement/DataExport/' + typeId, HTTP_OPTIONS);
  }
}
