
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class XbrlParserService {

  constructor() { }

  /**
 *  Parses a single xbrl code form API notation (with .) to UI notation (with -)
*/
  xbrlAPIParseToUISingle(xbrlCode: string): string {
    //BUG 24262: reading 'split' error
    if (xbrlCode == null)
      return '';
    else return xbrlCode.split('.').join('_');
  }

  /**
*  Parses a single xbrl code form UI notation (with -) to API notation (with .)
*/
  xbrlUIParseToAPISingle(xbrlCode: string): string {

    return xbrlCode.split('_').join('.');

  }
}