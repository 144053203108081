<div class="stareTopic">
    {{ 'APP.DASHBOARD.SUBTITLE' | translate }}
</div>


<br>

<div class="grid-layout-container">
    <kendo-tilelayout [columns]="10" [rowHeight]="50" [resizable]="false" [reorderable]="false">
        <kendo-tilelayout-item [row]="1" [col]="1" [colSpan]="10" [rowSpan]="7" [title]="chartOptions.title!.text!">
            <kendo-tilelayout-item-body>
                <br>
                <apx-chart #chartObj [series]="chartOptions.series!" [chart]="chartOptions.chart!"
                    [xaxis]="commonOptions.xaxis!" [stroke]="commonOptions.stroke!" [colors]="chartOptions.colors!"
                    [legend]="commonOptions.legend!" [markers]="commonOptions.markers!" [grid]="commonOptions.grid!"
                    [yaxis]="chartOptions.yaxis!"></apx-chart>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item [row]="8" [col]="1" [colSpan]="2" [rowSpan]="3"
            [title]="'APP.DASHBOARD.KPIS.IN_PROGRESS' | translate">
            <div kendoTooltip position="right" [title]="'APP.DASHBOARD.KPIS.IN_PROGRESS_TOOLTIP' | translate"
                style="text-align: center; padding-top: 10px;">
                <kendo-tilelayout-item-body>

                    <div class='ap-panel-content' style="text-align: center; font-size: 60px; color: #415385">
                        {{ formatNumber(countCurrentWorkflows) }}<br><br>
                    </div>


                </kendo-tilelayout-item-body>
            </div>
        </kendo-tilelayout-item>

        <kendo-tilelayout-item [row]="8" [col]="3" [colSpan]="8" [rowSpan]="6"
            [title]="'APP.DASHBOARD.KPIS.FINISHED_WITH_TYPE' | translate">
            <kendo-tilelayout-item-body>
                <div class='ap-panel-content'>
                    <!-- Tabelle nach Bescheidarten -->
                    <table style="width: 100%; font-size: 16px;">
                        <!-- Immobiliensteuer -->
                        <tr>
                            <td rowspan="2" [vAlign]="'top'"><b>Grundsteuer</b></td>
                            <td>{{nameOfRateableValue}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalRateableValue)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>

                            <td kendoTooltip>{{nameOfPropertyTaxBaseAssessment}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalPropertyTaxBaseAssessment)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoTooltip>{{nameOfCombiRateablePropertyTaxBaseAssessment}}</td>
                            <td style="text-align: right;">
                                {{formatNumber(countTotalCombiRateableValuePropertyTaxBaseAssessment)}}
                            </td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                        </tr>
                        <tr>

                            <td>{{nameOfPropertyTax}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalPropertyTax)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td></td>
                            <td style="text-align: right;">&nbsp;</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td></td>
                            <td style="text-align: right;">&nbsp;</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <!-- Gewerbesteuer -->
                        <tr>
                            <td rowspan="2" [vAlign]="'top'"><b>Gewerbesteuer</b></td>
                            <td>{{nameOfTradeTaxBaseAmount}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalTradeTaxBaseAssessment)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>{{nameOfTradeTaxLossCarryForward}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalTradeTaxLossCarryForward)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>{{nameOfTradeTaxBreakdown}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalTradeTaxBreakdown)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td>{{nameOfTradeTax}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalTradeTax)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>{{nameOfTradeTaxAddCarryForward}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalTradeTaxAddCarryForward)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td></td>
                            <td style="text-align: right;">&nbsp;</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <!-- Körperschaftsteuer -->
                        <tr>
                            <td rowspan="3" [vAlign]="'top'"><b>Körperschaftsteuer</b></td>
                            <td kendoTooltip>
                                {{nameOfCorporateIncomeTax}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalCorporateIncomeTax)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoTooltip
                                title="Bescheid zum xx.xx.20xx über die gesonderte Feststellung von Besteuerungsgrundlagen nach § 27 Abs. 2 KStG und § 28 Abs. 1 KStG">
                                {{nameOfCorporateIncomeTaxSeparateAssessmentP27}}</td>
                            <td style="text-align: right;">
                                {{formatNumber(countTotalCorporateIncomeTaxSeparateAssessmentP27)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoTooltip
                                title="Vorauszahlungsbescheid über Körperschaftsteuer und Solidaritätszuschlag">
                                {{nameOfCorporateIncomeTaxPrePayment}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalCorporateIncomeTaxPrePayment)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td kendoTooltip
                                title="Bescheid über die gesonderte Feststellung des verbleibenden Zuwendungsvortrags auf den 31.12.20xx">
                                {{nameOfCorporateIncomeTaxAddCarryForward}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalCorporateIncomeTaxAddCarryForward)}}
                            </td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoTooltip
                                title="Bescheid über die gesonderte Feststellung des verbleibenden Verlustvortrags zur Körperschaftsteuer zum 31.12.20xx">
                                {{nameOfCorporateIncomeTaxLossCarryForward}}</td>
                            <td style="text-align: right;">
                                {{formatNumber(countTotalCorporateIncomeTaxLossCarryForward)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoToolTip
                                title="Bescheid für 20xx über die gesonderte und einheitliche Feststellung des dem Organträger zuzurechnenden Einkommens der Organgesellschaft und damit zusammenhängender anderer Besteuerungsgrundlagen nach § 14 Abs. 5 KStG - Ausfertigung für die Organgesellschaft">
                                {{nameOfCorporateIncomeTaxSaUControllingCompany}}</td>
                            <td style="text-align: right;">
                                {{formatNumber(countTotalCorporateIncomeTaxSaUControllingCompany)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td kendoTooltip
                                title="Bescheid für 20xx über die gesonderte und einheitliche Feststellung von Besteuerungsgrundlagen und des verrechenbaren Verlustes">
                                {{nameOfCorporateIncomeTaxSeparateAndUniformDetermination}}
                            </td>
                            <td style="text-align: right;">
                                {{formatNumber(countTotalCorporateIncomeTaxSeparateAndUniformDetermination)}}
                            </td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoTooltip title="Körperschaftsteuer (EBITDA)">
                                {{nameOfCorporateIncomeTaxEBITDA}}
                            </td>
                            <td style="text-align: right;">
                                {{formatNumber(countTotalCorporateIncomeTaxEBITDA)}}
                            </td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td></td>
                            <td style="text-align: right;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>

                        <!-- Weitere -->
                        <tr>
                            <td rowspan="1" [vAlign]="'top'"><b>Weitere</b></td>
                            <td>{{nameOfValueAddedTax}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalValueAddedTax)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td kendoTooltip>{{nameOfChamberOfCommerceAndIndustry}}</td>
                            <td style="text-align: right;">{{formatNumber(countTotalChamberOfCommerceAndIndustry)}}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td></td>
                            <td style="text-align: right;">&nbsp;</td>
                        </tr>

                        <!-- Einkommensteuer -->
                        <!-- <tr>
                            <td rowspan="1"><b>Einkommensteuer</b></td>
                            <td>Einkommensteuer</td>
                            <td style="text-align: right;">-</td>
                            <td>&nbsp;</td>
                            <td>Verlustvortrag</td>
                            <td style="text-align: right;">-</td>
                            <td>&nbsp;</td>
                            <td></td>
                            <td style="text-align: right;">-</td>
                        </tr>
                         -->


                    </table>
                    <!-- Ende: Tabelle nach Bescheidarten -->
                </div>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item [row]="11" [col]="1" [colSpan]="2" [rowSpan]="3"
            [title]="'APP.DASHBOARD.KPIS.FINISHED' | translate">
            <div kendoTooltip position="right" [title]="'APP.DASHBOARD.KPIS.FINISHED_TOOLTIP' | translate"
                style="text-align: center; padding-top: 10px;">
                <kendo-tilelayout-item-body>

                    <div class='ap-panel-content' style="text-align: center; font-size: 60px; color: #415385">
                        {{ formatNumber(countTotalWorkflows) }}<br><br>
                    </div>

                </kendo-tilelayout-item-body>
            </div>
        </kendo-tilelayout-item>
        <br>
        <kendo-tilelayout-item [row]="14" [col]="1" [colSpan]="10" [rowSpan]="7" [title]="chartOptions2.title!.text!">
            <kendo-tilelayout-item-body>
                <br>
                <apx-chart #chartObj2 [series]="chartOptions2.series!" [chart]="chartOptions2.chart!"
                    [xaxis]="commonOptions.xaxis!" [stroke]="commonOptions.stroke!" [colors]="chartOptions2.colors!"
                    [legend]="commonOptions.legend!" [markers]="commonOptions.markers!" [grid]="commonOptions.grid!"
                    [yaxis]="chartOptions2.yaxis!"></apx-chart>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
</div>