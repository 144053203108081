import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SVGIcon, arrowRightIcon } from '@progress/kendo-svg-icons';
import { SettingOption } from 'app/_models/setting-option-item.model';
import { UserLocal } from 'app/_models/user.model';
import { ListsService } from 'app/_services/lists.service';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';


@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
	standalone: false
})
export class SettingsComponent implements OnInit {


	public arrowRightIcon: SVGIcon = arrowRightIcon;
	public settingOptionsData = [] as SettingOption[];
	public layoutRows = Array(6).fill({ height: 120 });
	public myuser: UserLocal = {} as UserLocal;
	public legalEntitiesSource: DropDownStringItem[] = [];

	public idForExport = 4;
	public idForWorkflow = 5;


	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private listsService: ListsService,

	) {
		const jsonStr = localStorage.getItem('user') || '';
		if (jsonStr.length > 0) {
			const jsonObj = JSON.parse(jsonStr);
			this.myuser = jsonObj as UserLocal;
		}
		if (this.myuser.canManageCommuneTaxRates || this.myuser.isAdmin) {
			this.idForExport = 4;
		} else {
			this.idForExport = 3;
		}

		if (!this.myuser.canManageCommuneTaxRates && !this.myuser.isAdmin && !this.myuser.canDataExport) {
			this.idForWorkflow = 3;
		} else if ((!this.myuser.canManageCommuneTaxRates && !this.myuser.isAdmin) || (!this.myuser.canDataExport && !this.myuser.isAdmin)) {
			this.idForWorkflow = 4;
		} else {
			this.idForWorkflow = 5;
		}

		if (!this.myuser.isAdmin && !this.myuser.canDataImport) {
			this.idForWorkflow = 3;
		} else {
			this.idForWorkflow = 4;
		}
		this.getData();
		this.settingOptionsData.forEach(option => {

			// only if option.title not null and not empty
			if ((option.title) && (option.title !== '')) {

				this.translateService.get(option.title as string).subscribe((title: string) => {
					option.titleToDisplay = title;
				});
			}
			// only if option.tooltip not null and not empty
			if ((option.tooltip) && (option.tooltip !== '')) {

				this.translateService.get(option.tooltip as string).subscribe((tooltip: string) => {
					option.tooltipToDisplay = tooltip;
				});
			}
		})
		this.listsService.GetLegalEntitiesList().subscribe((data) => {

			this.legalEntitiesSource = data as DropDownStringItem[];
		});


	}

	ngOnInit(): void {

		this.translateService.get('APP.SETTINGS.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);

		});

		document.getElementById("arrow-2")?.setAttribute('style', 'position: absolute;  left: 900;');
		document.getElementById("arrow-1")?.setAttribute('style', 'position: absolute;  left: 600;');

	}

	public getData(): void {

		this.settingOptionsData = [
			{
				id: 1,
				col: 1,
				title: "APP.SETTINGS.LEGAL_ITEMS.TITLE",
				link: "/setting-legalentities",
				tooltip: "APP.SETTINGS.LEGAL_ITEMS.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 2,
				col: 1,
				title: "APP.SETTINGS.MAILING_METADATA.TITLE",
				link: "/setting-mailmetadata",
				tooltip: "APP.SETTINGS.MAILING_METADATA.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 3,
				col: 1,
				title: "APP.SETTINGS.TAX_RATE.TITLE",
				link: "/setting-commune",
				tooltip: "APP.SETTINGS.TAX_RATE.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canManageCommuneTaxRates || this.myuser.isAdmin
			},
			{
				id: this.idForExport,
				col: 1,
				title: "APP.SETTINGS.EXPORT.TITLE",
				link: "/setting-export",
				tooltip: "APP.SETTINGS.EXPORT.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canDataExport || this.myuser.isAdmin
			},

			{
				id: 1,
				col: 2,
				title: "APP.SETTINGS.DATEV_METADATEN.TITLE",
				link: "/setting-datevmetadaten",
				tooltip: "APP.SETTINGS.DATEV_METADATEN.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 2,
				col: 2,
				title: "APP.USER_PREFERENCES.TITLE",
				link: "/userpreferences",
				tooltip: "APP.USER_PREFERENCES.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 3,
				col: 2,
				title: "APP.SETTINGS.IMPORT.TITLE",
				link: "/setting-import",
				tooltip: "APP.SETTINGS.IMPORT.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canDataImport || this.myuser.isAdmin
			},
			{
				id: this.idForWorkflow,
				col: 2,
				title: "APP.SETTINGS.SETTINGS_WORKFLOW.TITLE",
				link: "/setting-workflow",
				tooltip: "APP.SETTINGS.SETTINGS_WORKFLOW.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canConfigureWorkflows || this.myuser.isAdmin
			},
		];

	}

}
