import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientMailItem, ClientMailItemForUpdateItem } from 'app/_models/client-mail.model';
import { WorkflowItem } from 'app/_models/workflow.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { XbrlParserService } from '../xbrl-parser.service';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiClientMailingService {


  constructor(
    private http: HttpClient,
    private xbrlParserService: XbrlParserService
  ) { }

  /**
 * API: Get Client Mail item by workflowId.
 * Never used
 */
  getClientMailById(id: number): Observable<ClientMailItem> {
    return this.http.get<ClientMailItem>(ENVIRONMENT.apiBaseUrl + 'ClientMailing/GetById/' + id, HTTP_OPTIONS).pipe(
      map((data: ClientMailItem) => {
        if (data.workflowItems) {
          for (const item of data.workflowItems) {
            if (item.validationMessageList) {
              for (const validationMessage of item.validationMessageList) {
                validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
              }
            }
          }
        }
        return data;
      }));
  }
  /**
* API: Refresh the Client Mail item.
*/
  resetMailText(id: number): Observable<ClientMailItem> {
    return this.http.put<ClientMailItem>(ENVIRONMENT.apiBaseUrl + 'ClientMailing/ResetMailText/' + id, HTTP_OPTIONS).pipe(
      map((data: ClientMailItem) => {
        if (data.workflowItems) {
          for (const item of data.workflowItems) {
            if (item.validationMessageList) {
              for (const validationMessage of item.validationMessageList) {
                validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
              }
            }
          }
        }
        return data;
      }));
  }

  /**
* API: Update a client mail  item.
*/
  updateClientMailById(id: number, item: ClientMailItemForUpdateItem): Observable<ClientMailItem> {
    return this.http.put<ClientMailItem>(ENVIRONMENT.apiBaseUrl + 'ClientMailing/UpdateMail/' + id, item, HTTP_OPTIONS).pipe(
      map((data: ClientMailItem) => {
        if (data.workflowItems) {
          for (const item of data.workflowItems) {
            if (item.validationMessageList) {
              for (const validationMessage of item.validationMessageList) {
                validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
              }
            }
          }
        }
        return data;
      }));
  }

  /**
* API: Get Client Mail item by workflowId (without file content)
*/
  getClientMailByWorkflowId(workflowId: number): Observable<ClientMailItem> {
    return this.http.get<ClientMailItem>(ENVIRONMENT.apiBaseUrl + 'ClientMailing/GetByWorkflowId/' + workflowId, HTTP_OPTIONS).pipe(
      map((data: ClientMailItem) => {
        if (data.workflowItems) {
          for (const item of data.workflowItems) {
            if (item.validationMessageList) {
              for (const validationMessage of item.validationMessageList) {
                validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
              }
            }
          }
        }
        return data;
      }));
  }

  /**
* API: Get all ClientMailing/GetAllUnsents.
*/
  getAllUnsent(): Observable<WorkflowItem[]> {
    return (this.http.get<WorkflowItem[]>(ENVIRONMENT.apiBaseUrl + 'ClientMailing/GetAllUnsents', HTTP_OPTIONS)).pipe(
      map((data: WorkflowItem[]) => {
        for (const item of data) {
          if (item.validationMessageList) {
            for (const validationMessage of item.validationMessageList) {
              validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
            }
          }
        }
        return data;
      }),
      catchError(this.handleError<WorkflowItem[]>('getAllUnsent', [])));
  }


  /**
* API: Create Mail with selected workflow ids .
*/
  postCreateMail(workflowIds: number[]): Observable<ClientMailItem> {
    return this.http.post<ClientMailItem>(ENVIRONMENT.apiBaseUrl + 'ClientMailing/CreateMail', workflowIds, HTTP_OPTIONS).pipe(
      map((data: ClientMailItem) => {
        if (data.workflowItems) {
          for (const item of data.workflowItems) {
            if (item.validationMessageList) {
              for (const validationMessage of item.validationMessageList) {
                validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
              }
            }
          }
        }
        return data;
      }));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`); // Log the error to the console or send it to a logging infrastructure  
      // Return an empty result to keep the app running  
      return of(result as T);
    };
  }

}
