import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

//import { AuthenticationService } from '../_services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        //private readonly authenticationService: AuthenticationService
    ) { }

    intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 999].includes(err.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                // this.authenticationService.logout();
                this.router.navigate(['/login']);
            }

            const error = err.error.message || err.statusText;
            console.error(error);
            return throwError(err);
        }))
    }
}