import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SettingOption } from 'app/_models/setting-option-item.model';
import { SVGIcon, arrowRightIcon } from '@progress/kendo-svg-icons';
import { UserLocal } from 'app/_models/user.model';

@Component({
	selector: 'app-user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.scss'],
	standalone: false
})
export class UserManagementComponent implements OnInit {

	public arrowRightIcon: SVGIcon = arrowRightIcon;
	public settingOptionsData = [] as SettingOption[];
	public layoutRows = Array(3).fill({ height: 120 });
	public myuser: UserLocal = {} as UserLocal;
	public idForSDCUsers = 3;

	constructor(
		private titleService: Title,
		public translateService: TranslateService
	) {
		const jsonStr = localStorage.getItem('user') || '';
		if (jsonStr.length > 0) {
			const jsonObj = JSON.parse(jsonStr);
			this.myuser = jsonObj as UserLocal;
		}
		if (!this.myuser.canManageOwnUser && !this.myuser.isAdmin) {
			this.idForSDCUsers = 1;
		} else {
			this.idForSDCUsers = 3;
		}
		this.getData();
		this.settingOptionsData.forEach(option => {

			// only if option.title not null and not empty
			if ((option.title) && (option.title !== '')) {

				this.translateService.get(option.title as string).subscribe((title: string) => {
					option.titleToDisplay = title;
				});
			}
			// only if option.tooltip not null and not empty
			if ((option.tooltip) && (option.tooltip !== '')) {

				this.translateService.get(option.tooltip as string).subscribe((tooltip: string) => {
					option.tooltipToDisplay = tooltip;
				});
			}
		})
	}

	ngOnInit(): void {
		this.translateService.get('APP.ADMINISTRATION.USER_MANAGEMENT_MENU.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});
		document.getElementById("arrow-1")?.setAttribute('style', 'position: absolute;  left: 600;');

	}

	public getData(): void {
		this.settingOptionsData = [
			{
				id: 1,
				col: 1,
				title: "APP.ADMINISTRATION.USER_MANAGEMENT.TITLE",
				link: "/admin-users",
				tooltip: "APP.ADMINISTRATION.USER_MANAGEMENT.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canManageOwnUser || this.myuser.isAdmin
			},
			{
				id: 2,
				col: 1,
				title: "APP.ADMINISTRATION.USER_RIGHTS.TITLE",
				link: "/admin-useraccess",
				tooltip: "APP.ADMINISTRATION.USER_RIGHTS.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canManageOwnUser || this.myuser.isAdmin
			},
			{
				id: this.idForSDCUsers,
				col: 1,
				title: "APP.ADMINISTRATION.SDC_USER_MANAGEMENT.TITLE",
				link: "/admin-sdc-users",
				tooltip: "APP.ADMINISTRATION.SDC_USER_MANAGEMENT.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: this.myuser.canManageSdcUsers || this.myuser.isAdmin
			},
		];
	}
}