import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ENVIRONMENT } from '../environments/environment';
import { UserLocal } from './_models/user.model';
import { naviListMappingPath, naviListMappingRoute, naviListMappingPathSDC, naviListMappingRouteSDC, naviListItemsDe, naviListItemsEng, naviListItemsNoAdminEng, naviListItemsNoAdminDe } from './app-routing.module';
import { SVGIcon, arrowRotateCcwIcon, bellIcon, logoutIcon, menuIcon } from '@progress/kendo-svg-icons';
import { DrawerItem, DrawerSelectEvent } from "@progress/kendo-angular-layout";
import { ListsService } from './_services/lists.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ApiAuthentificationService } from './_services/StareApi/auth.service';
import { StatePersistingService } from './_services/state-persisting.service';
import { ClickDropdownOptionItem } from './_models/click-dropdown-option.model';
import { DropDownStringItem } from './_models/dropdown-item-string.model';


export interface UserToken {
	token: string;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: false
})

export class AppComponent implements OnInit {

	public envBadge: string = ENVIRONMENT.envBadge;

	//Variables for Translation
	public client: string = "Client";
	public userSettings: string = "UI Settings";
	public resetTables: string = "Tabellen zurücksetzen";
	public menuIcon: SVGIcon = menuIcon;
	public bellIcon: SVGIcon = bellIcon;
	public pwcLogo = '../assets/images/PwC_Logo.png';

	public myuser: UserLocal = {} as UserLocal;
	public footerCopyrightFrom = new Date().getFullYear();
	newNavi = [] as DrawerItem[];

	// == Getters =====================================================
	get firstname(): string {
		if (this.myuser && this.myuser.firstname && this.myuser.firstname.length > 0) {
			return this.myuser.firstname;
		}
		else {
			return ' ';
		}
	}

	get lastname(): string {
		if (this.myuser && this.myuser.lastname && this.myuser.lastname.length > 0) {
			return this.myuser.lastname;
		}
		else {
			return ' ';
		}
	}




	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private router: Router,
		private route: ActivatedRoute,
		private listsService: ListsService,
		private persistanceService: StatePersistingService,
		private apiAuthService: ApiAuthentificationService,

	) {
		//@Inject(LOCALE_ID) private locale: string 
		const angularPlugin = new AngularPlugin();

		// *** Add the Click Analytics plug-in. ***
		const clickPluginInstance = new ClickAnalyticsPlugin();
		const clickPluginConfig = {
			autoCapture: true
		};

		const appInsights = new ApplicationInsights({
			config: {
				connectionString: ENVIRONMENT.appInsightsConnectionString,
				// *** If you're adding the Click Analytics plug-in, delete the next line. ***  
				//extensions: [angularPlugin],
				// *** Add the Click Analytics plug-in. ***
				extensions: [angularPlugin, clickPluginInstance],
				extensionConfig: {
					[angularPlugin.identifier]: { router: this.router },
					// *** Add the Click Analytics plug-in. ***
					[clickPluginInstance.identifier]: clickPluginConfig
				}
			}
		});
		appInsights.loadAppInsights();

	}

	ngOnInit(): void {
		const jsonStr = localStorage.getItem('user') || '';
		if (jsonStr.length > 0) {
			const jsonObj = JSON.parse(jsonStr);
			this.myuser = jsonObj as UserLocal;

			this.clientEngagemenList = this.myuser.clientEngagements.map((x) => {

				return { value: x.clientEngagementKey, labelDE: x.name!, labelEN: x.name!, isDefault: false, isDeleted: false }
				// return { value: x.clientEngagementKey, label: x.name }
			});

			// Sort clientENgagementList by label
			this.clientEngagemenList.sort((a, b) => (a.labelDE.toLowerCase() > b.labelDE.toLowerCase()) ? 1 : -1);

			this.clientEngagemenListSource = this.clientEngagemenList;

			// Find first element in clientEngagemenList where is myuser.clientEngagementKey
			this.selectedClientEngagement = this.clientEngagemenList.find(f => f.value == this.myuser.clientEngagementKey)!;
			this.selectedEngagementType = this.myuser.tenantSource;
		}

		//if user has accessed stare with link from email then redirect values must be set before login
		this.route.queryParams.subscribe(params => {
			const workflowId = params.w ?? 0;
			const actualStateId = params.s ?? 0;
			const clientEngagementKey = params.c ?? '';
			const fileId = params.f ?? '';
			// console.log('stste---' + actualStateId + '---workid---' + workflowId + '---client--' + clientEngagementKey + '---file--' + fileId);
			//email url link to assessment review or to download file
			if ((actualStateId != 0 && workflowId != 0 && clientEngagementKey != '') || (fileId != '' && clientEngagementKey != '')) {
				//on initial load no user exists
				let url = this.router.url;
				url = ENVIRONMENT.primaryDomain + url;
				//if redirect url is accessed without login, user is not present 
				if (this.myuser?.userId == undefined) {
					this.apiAuthService.setRedirection(url, clientEngagementKey.toLocaleLowerCase());
					this.router.navigate(['/login']);
				}
				else if (this.myuser?.clientEngagementKey != clientEngagementKey)
					this.apiAuthService.setRedirection(url, clientEngagementKey.toLocaleLowerCase());
			}
		});

		// Set selected index of navigation list
		this.router.events.subscribe((res) => {
			if (res instanceof NavigationEnd) {
				// get urlAfterRedirects and remove first slash
				const url = res.urlAfterRedirects.substring(1);
				if (this.selectedEngagementType == 1) {
					this.selectedIndex = naviListMappingPath.get(url) ?? -1;
					// console.log('url: ' + url + ' => ' + naviListMappingPath.get(url) + ' => ' + this.selectedIndex);

				} else {
					this.selectedIndex = naviListMappingPathSDC.get(url) ?? -1;
					// console.log('url: ' + url + ' => ' + naviListMappingPathSDC.get(url) + ' => ' + this.selectedIndex);

				}
				// 	this.selectedIndex = naviListMappingPath.get(url) ?? -1;		// -1 = no match
				// 	console.log('url: ' + url + ' => ' + naviListMappingPath.get(url) + ' => ' + this.selectedIndex);
			}
		});


		// Set language
		if (!localStorage.getItem('language')) {
			localStorage.setItem('language', 'de');
		}

		// Set hidden tasks
		if (!localStorage.getItem('show_hidden_tasks')) {
			localStorage.setItem('show_hidden_tasks', 'true');
		}
		this.translateService.addLangs(['en', 'de']);
		this.translateService.setDefaultLang('de');


		const language: string = (localStorage.getItem('language') == null) ? 'de' : localStorage.getItem('language')!;
		this.translateService.use(language != null && language.match(/^en$|^de$/) ? language : this.translateService.getDefaultLang());

		// let jsonStr = localStorage.getItem('user') || '';
		// if (jsonStr.length > 0) {
		// 	let jsonObj = JSON.parse(jsonStr);
		// 	this.myuser = jsonObj as UserLocal;

		// 	this.clientEngagemenList = this.myuser.clientEngagements.map((x) => {

		// 		return { value: x.clientEngagementKey, label: x.name }
		// 	});

		// 	// Find first element in clientEngagemenList where is myuser.clientEngagementKey
		// 	this.selectedClientEngagement = this.clientEngagemenList.find(f => f.value == this.myuser.clientEngagementKey);
		// 	this.selectedEngagementType = this.myuser.clientEngagements.find(f => f.clientEngagementKey == this.selectedClientEngagement.value)?.tenantSource;
		// }

		this.translateService.get('APP.NAME').subscribe((title: string) => {
			this.titleService.setTitle(title);
		});

		this.translateService.get('APP.CLIENT').subscribe((title: string) => {
			this.client = title;
		});
		this.translateService.get('APP.USER_PREFERENCES.USER_INTERFACE.TITLE').subscribe((title: string) => {
			this.userSettings = title;
		});
		this.translateService.get('BUTTON.RESET_TABLE').subscribe((title: string) => {
			this.resetTables = title;
			this.loadUserDropDown();
		});

		if (this.myuser.isAdmin && localStorage.getItem('language') == "de") {
			//Admin, deutsch, dms
			if (this.selectedEngagementType == 1) {
				this.newNavi = naviListItemsDe;


				this.newNavi.splice(1, 2);

				this.navList = this.newNavi;
			} else { this.navList = naviListItemsDe; }

		} else if (this.myuser.isAdmin && localStorage.getItem('language') == "en") {
			//Admin, englisch, dms
			if (this.selectedEngagementType == 1) {
				this.newNavi = naviListItemsEng;


				this.newNavi.splice(1, 2);

				this.navList = this.newNavi;
			} else { this.navList = naviListItemsEng; }

		} else if (!this.myuser.isAdmin && localStorage.getItem('language') == "de") {

			//Not Admin, deutsch, dms
			if (this.selectedEngagementType == 1) {
				this.newNavi = naviListItemsNoAdminDe;


				this.newNavi.splice(1, 2);

				this.navList = this.newNavi;
				//here need to check if user management active
				//important only if not an admin
				if (!this.myuser.canManageOwnUser && !this.myuser.canManageSdcUsers) {

					this.newNavi = this.navList;
					this.newNavi.splice(5, 1);
					this.navList = this.newNavi;
				}
			} else {
				this.navList = naviListItemsNoAdminDe;
				if (!this.myuser.canManageOwnUser && !this.myuser.canManageSdcUsers) {
					this.newNavi = this.navList;
					this.newNavi.splice(7, 1);
					this.navList = this.newNavi;
				}
			}



		} else if (!this.myuser.isAdmin && localStorage.getItem('language') == "en") {
			//Not Admin, englisch, dms
			if (this.selectedEngagementType == 1) {
				this.newNavi = naviListItemsNoAdminEng;


				this.newNavi.splice(1, 2);
				// this.newNavi.splice(1, 1);
				this.navList = this.newNavi;
				//here need to check if user management active
				//important only if not an admin

				if (!this.myuser.canManageOwnUser && !this.myuser.canManageSdcUsers) {
					this.newNavi = this.navList;
					this.newNavi.splice(6, 1);
					this.navList = this.newNavi;
				}
			} else {
				this.navList = naviListItemsNoAdminEng;
				if (!this.myuser.canManageOwnUser && !this.myuser.canManageSdcUsers) {
					this.newNavi = this.navList;
					this.newNavi.splice(7, 1);
					this.navList = this.newNavi;
				}
			}


		}

	}



	// == User / Avatar Drop Down ===================================================================================================================

	userDropDownList: ClickDropdownOptionItem[] = [];

	loadUserDropDown(): void {

		this.userDropDownList = [
			//{ value: 'item1', label: 'Keyboard shortcuts', iconName: 'keyboard-outline' },
			//{ value: 'usersettings', label: this.userSettings, iconName: 'setting-outline' },
			{
				value: 'table', label: this.resetTables, svgIcon: arrowRotateCcwIcon, click: (dataItem: ClickDropdownOptionItem): void => {
					this.onSelectItem(dataItem);
				}
			},
			{
				value: 'logout', label: 'Logout', svgIcon: logoutIcon, click: (dataItem: ClickDropdownOptionItem): void => {
					this.onSelectItem(dataItem);
				}
			},
		]
	}

	onSelectItem(dataItem: ClickDropdownOptionItem): void {

		if (dataItem.value == 'usersettings') {
			this.router.navigate(['/userpreferences']);
		}
		if (dataItem.value == 'logout') {
			this.router.navigate(['/logout']);
		}

		if (dataItem.value == 'table') {
			localStorage.removeItem('tasksGridSettings');
			localStorage.removeItem('tasksAllGridSettings');

			localStorage.removeItem('tasksAllForSDCGridSettings');
			localStorage.removeItem('archiveGridSettings');
			localStorage.removeItem('adminArchivGridSettings');
			window.location.reload();

		}
	}


	// == Client Engagement Drop Down ===============================================================================================================

	selectedEngagementType? = -1;
	selectedClientEngagement: DropDownStringItem = {} as DropDownStringItem;
	clientEngagemenList: DropDownStringItem[] = [];
	clientEngagemenListSource: DropDownStringItem[] = [];


	onSelectItemClient(event: DropDownStringItem): void {
		if (event.value == undefined) {
			return;
		} else {

			if (event.value != this.myuser.clientEngagementKey) {
				// console.log("Load new Client: " + event.value);

				this.listsService.ResetCacheLegalEntities();
				this.listsService.ResetCacheCommuneAGS();
				this.persistanceService.clearAllFilters();

				// See Login-Component also
				this.apiAuthService.getNewToken(event.value)
					.then(
						zz => {

							localStorage.setItem('stare_token', zz.token);

							// Get User-Info

							this.apiAuthService.getSessionInfoObservable().subscribe(data => {
								const _user = {} as UserLocal;
								_user.clientEngagementKey = data.clientEngagementKey;
								_user.clientEngagements = data.clientEngagements;
								_user.firstname = data.firstname;
								_user.tenantSource = data.tenantSource;
								_user.lastname = data.lastname;
								_user.userId = data.userId;
								_user.isAdmin = data.isAdmin;
								_user.canReopenWorkflows = data.canReopenWorkflows;
								_user.canManageOwnUser = data.canManageOwnUser;
								_user.mail = data.mail;
								_user.canManageCommuneTaxRates = data.canManageCommuneTaxRates;

								_user.isPreparer = data.isPreparer;
								_user.isReviewer = data.isReviewer;
								_user.isCITTeam = data.isCITTeam;

								_user.canManageSdcUsers = data.canManageSdcUsers;
								_user.canDataExport = data.canDataExport;
								_user.canDataImport = data.canDataImport;
								_user.canConfigureWorkflows = data.canConfigureWorkflows;


								// console.info(_user);

								localStorage.setItem('user', JSON.stringify(_user));


								this.persistanceService.updateAll();
								localStorage.removeItem('redirectKey');
								this.apiAuthService.resetRedirection();


								// this.router.navigate(['/tasks']);
								window.location.href = ENVIRONMENT.primaryDomain + '/tasks';		// with reload
							});
						}
					);
			}

		}
	}

	// == Handlers ================================================
	handleClientEngagementFilter(value: string): void {
		this.clientEngagemenList = this.clientEngagemenListSource.filter(
			(s) => s.labelDE.split('.').join("").toLowerCase().indexOf(value.split('.').join("").toLowerCase()) !== -1
		);
	}

	// == Sidebar ===================================================================================================================================


	// == NAVI ========================================================
	solid: boolean = false;
	simple: boolean = false;
	collapsed: boolean = false;
	suffixIcon: boolean = false;
	public expanded = false;
	selectedIndex: number = 0;  // this.commonService.sideBarIndex;
	selectedSubIndex: number = -1;
	navList: DrawerItem[] = [];


	redirectNav(event: DrawerSelectEvent): void {
		if (this.selectedEngagementType == 1) {
			const routeText = naviListMappingRoute.get(event.index);

			this.router.navigate(['' + routeText]);
		} else {
			const routeText = naviListMappingRouteSDC.get(event.index);
			// console.log(event.index);
			this.router.navigate(['' + routeText]);
		}
	}



}
