import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../environments/environment';
import { UserLocal } from '../_models/user.model';
import { ListsService } from 'app/_services/lists.service';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';

export interface UserToken {
  token: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false
})
export class LoginComponent implements OnInit {

  // private _token: string = '';
  private _user: UserLocal = {} as UserLocal;


  constructor(
    private listsService: ListsService,
    private apiAuthService: ApiAuthentificationService

  ) { }

  ngOnInit(): void {
    console.info("Init Login");


    if ((window.location.href.indexOf('#') > -1)) {

      // Get anchor string from URL (window.location.href)
      const anchor = window.location.href.split('#')[1];

      //console.info('(1) Get Guid Content: ' + anchor);

      // Get Token

      this.apiAuthService.verifyRequest(anchor).then(
        (zz) => {

          //console.info('(2) Set Token to local Storage');
          // this._token = zz.token;
          localStorage.setItem('stare_token', zz.token);
          this.listsService.ResetCacheLegalEntities();
          this.listsService.ResetCacheCommuneAGS();

          // Get User-Info
          console.info("(4) Login: Session Info");
          // this.http.get<any>(ENVIRONMENT.apiBaseUrl + 'Auth/GetSessionInfoAsync', HTTP_OPTIONS2).subscribe(data => {
          this.apiAuthService.getSessionInfoObservable().subscribe(data => {
            this._user.clientEngagementKey = data.clientEngagementKey;
            this._user.clientEngagements = data.clientEngagements;
            this._user.firstname = data.firstname;
            this._user.tenantSource = data.tenantSource;
            this._user.lastname = data.lastname;
            this._user.userId = data.userId;
            this._user.isAdmin = data.isAdmin;

            this._user.canReopenWorkflows = data.canReopenWorkflows;
            this._user.canManageCommuneTaxRates = data.canManageCommuneTaxRates;
            this._user.canManageOwnUser = data.canManageOwnUser;
            this._user.mail = data.mail;

            this._user.isPreparer = data.isPreparer;
            this._user.isReviewer = data.isReviewer;
            this._user.isCITTeam = data.isCITTeam;

            this._user.canManageSdcUsers = data.canManageSdcUsers;
            this._user.canDataExport = data.canDataExport;
            this._user.canDataImport = data.canDataImport;
            this._user.canConfigureWorkflows = data.canConfigureWorkflows;

            // console.info(this._user);

            localStorage.setItem('user', JSON.stringify(this._user));
            localStorage.setItem('stare_login_count', '0');


            // this.router.navigate(['/tasks']);
            const redirectTo = localStorage.getItem('redirectTo') ?? '';
            const redirectKey = localStorage.getItem('redirectKey') ?? '';
            if (redirectKey !== '' && redirectTo !== '') {

              // if user has access to the redirect client key and if url is valid , then redirect
              if (this.apiAuthService.clientKeyExistsInList(redirectKey, this._user.clientEngagements) && this.apiAuthService.isValidUrl(redirectTo)) {
                const success = new Promise<boolean>(() => {
                  this.apiAuthService.changeClientandRedirect();
                });

                success.then(() => {
                  // handle result here
                  this.apiAuthService.resetRedirection();
                });
              }
              else {
                //access denied
                window.location.href = ENVIRONMENT.primaryDomain + '/access-denied';

              }
            }
            else {
              window.location.href = ENVIRONMENT.primaryDomain + '/tasks';
              // console.log('empty key and url from local storage after login');
            }
          },
            (error) => {
              console.error("Error in getting session info: " + error.message);
              this.apiAuthService.logout().then(() => {
                window.location.href = ENVIRONMENT.primaryDomain + '/access-denied';
              }
              );
            });

        },
        (error) => {
          console.error("Error in verifyRequest: " + error.message);

          window.location.href = ENVIRONMENT.primaryDomain + '/access-denied';

        }

      );


    }
    else {
      // console.log("Perform login");



      const cnt = localStorage.getItem('stare_login_count');

      // Convert to number
      let cntNumber = Number(cnt);

      cntNumber++;
      localStorage.setItem('stare_login_count', cntNumber.toString());

      if (cntNumber > 3) {
        // Post logout. After this, redirect to access denied page
        this.apiAuthService.logout().then(() => {
          window.location.href = ENVIRONMENT.primaryDomain + '/access-denied';
        }
        );
      }
      else {
        window.location.href = ENVIRONMENT.apiBaseUrl + 'Auth/Login';
      }
    }

  }

}
