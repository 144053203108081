import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SortDescriptor } from "@progress/kendo-data-query";
import { ColumnMenuSettings, GridComponent, GridSize } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DateTimeService } from 'app/_services/date-time.service';
import { ExportScope } from 'app/_models/export-scope.model';
import { ApiDataExportScopeService } from 'app/_services/StareApiMaintenance/data-export-scope.service';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipService } from 'app/_services/tooltip.service';

@Component({
  selector: 'app-admin-export-scope',
  templateUrl: './admin-export-scope.component.html',
  styleUrl: './admin-export-scope.component.scss',
  standalone: false
})
export class AdminExportScopeComponent {
  /** Displayed (filtered) data */
  public tableData = [] as ExportScope[];
  /** Original data from API request */
  public tableDataOriginal = [] as ExportScope[];
  /** API request is finished */
  public finishedLoading = false;
  public smallSize: GridSize = "small";
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;



  // ==============================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiDataExportScopeService: ApiDataExportScopeService,
    private dateTimeService: DateTimeService,
    public tooltipService: TooltipService
  ) { this.allData = this.allData.bind(this); }


  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.EXPORT_SCOPE.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

  }

  // == Resize Listener =============================================================================================================

  public pageHeight: number = window.innerHeight - 125;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  // == Load grid data ===================================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.apiDataExportScopeService.getAllExportScopes(5000).subscribe({
      next: (res: ExportScope[]) => {

        // Descripbe the dataExportTypeLabelDE from the dataExportType
        if (res != null) {

          res.forEach((item: ExportScope) => {
            if (item.createdDate) {
              //console.log(item.createdDate);
              item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;

            }

          });


          res.forEach((item: ExportScope) => {
            if (item.dataExportType == 0) {
              item.dataExportTypeLabelDE = "NA";
            } else if (item.dataExportType == 1) {
              item.dataExportTypeLabelDE = "Anwendungsdaten";
            } else if (item.dataExportType == 2) {
              item.dataExportTypeLabelDE = "Bescheide";
            } else if (item.dataExportType == 4) {
              item.dataExportTypeLabelDE = "Engagementdaten";
            } else if (item.dataExportType == 8) {
              item.dataExportTypeLabelDE = "Zahlungsanweisungen";
            } else if (item.dataExportType == 16) {
              item.dataExportTypeLabelDE = "Datalake";
            } else if (item.dataExportType == 32) {
              item.dataExportTypeLabelDE = "Bescheiddaten";
            } else if (item.dataExportType == 64) {
              item.dataExportTypeLabelDE = "Referenzdaten";
            } else if (item.dataExportType == 65) {
              item.dataExportTypeLabelDE = "Importierte Bescheide";
            } else if (item.dataExportType == 66) {
              item.dataExportTypeLabelDE = "App Daten";
            } else if (item.dataExportType == 67) {
              item.dataExportTypeLabelDE = "Testing";
            }

          });
        }


        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });

  }

  // == Grid Settings =======================================================================================================================================
  // Pagination 

  public pageSize = 20;



  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  // == Filter ======================================================================================================================================
  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }
  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {



      filter.filters.push({
        field: "id",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "jobIdentifier",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "dataExportTypeLabelDE",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }



}
