import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { UserLocal } from '../_models/user.model';
import * as FileSaver from 'file-saver';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { SortDescriptor } from "@progress/kendo-data-query";
import { Title } from '@angular/platform-browser';
import { ListsService } from 'app/_services/lists.service';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiFileService } from 'app/_services/StareApi/file.service';
import { ApiClientEngagementService } from 'app/_services/StareApi/client-engagement.service';
import { SVGIcon, arrowRotateCwIcon, arrowUpIcon, downloadIcon, filterClearIcon } from '@progress/kendo-svg-icons';
import { WorkflowDataExport } from 'app/_models/workflow-data-export.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { ApiClientEngagementMaintenanceService } from 'app/_services/StareApiMaintenance/client-engagement-maintenance.service';




@Component({
  selector: 'app-setting-export',
  templateUrl: './setting-export.component.html',
  styleUrls: ['./setting-export.component.scss'],
  standalone: false
})
export class SettingExportComponent implements OnInit {

  public exportTypeList: DropDownListItem[] = [];
  private exportTypeId: number | undefined;

  public finishedLoading = false;


  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // ============================================================================

  constructor(
    public translateService: TranslateService,
    private dateTimeService: DateTimeService,
    private apiClientEngagementService: ApiClientEngagementService,
    private titleService: Title,
    private notificationService: WrapperNotificationService,
    private listsService: ListsService,
    private fileservice: ApiFileService,
    private apiClientEngagementMaintenanceService: ApiClientEngagementMaintenanceService,
    public tooltipService: TooltipService
  ) { }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.EXPORT.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadExportList();

    this.listsService.GetExportTypeList().subscribe(
      (data) => {
        this.exportTypeList = data;
        // console.log("exposttypes");
        // console.log(this.exportTypeList);
      }
    );
  }

  // == Resize the Grid =========================================================

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Grid Settings ===========================================================

  // Pagination 

  public pageSize = 20;

  // Icons

  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;
  public downloadSVG: SVGIcon = downloadIcon;
  public startExportSVG: SVGIcon = arrowUpIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting 
  public currentSort: SortDescriptor[] = [

  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {


      filter.filters.push({
        field: "fileId",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "filename",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "pageCount",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "filesize",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "sourceUrl",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "mailForSendingItemId",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "clientMailItemId",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }


  // == Display Page and Button ==================================================

  // @ViewChild('htmlBtnExport', { static: false }) btnExport: any;

  getUser(): UserLocal | null {

    const jsonStr = localStorage.getItem('user') || null;
    if (jsonStr == null) {
      return null;
    }
    const jsonObj = JSON.parse(jsonStr);
    const myuser = jsonObj as UserLocal;

    return myuser;
  }

  performDataExport(event: DropDownListItem): void {
    this.exportTypeId = event.value;
    //this.btnExport.disabled = true;

    if (this.exportTypeId == null || this.exportTypeId == 0) {
      this.notificationService.showError('Bitte wählen Sie einen Exporttyp aus.');
      //this.btnExport.disabled = false;
      return;
    }

    this.apiClientEngagementService.dataExport(this.exportTypeId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Der Datenexport wurde erfolgreich beauftragt: ' + event?.labelDE);

        //this.btnExport.disabled = false;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Der Zugriff auf diesen Export ist nur Administratoren gestattet.');

        }
        else {
          this.notificationService.showError('Beim Export ist ein Fehler aufgetreten. Bitte an den Support wenden.');
        }

        //this.btnExport.disabled = false;
      },
      complete: () => {
        // Nothing to do here
      }
    });
  }


  forceStartExport(): void {
    // console.log("Force Export Start");

    // getDatevReferenceData
    this.apiClientEngagementMaintenanceService.performDatalakeExport().subscribe({
      next: () => {
        // console.log(res);
        this.notificationService.showSuccess('Der Job zum Export zum Datalake wurde beauftragt.');
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 400) {
          this.notificationService.showError('Der Export konnte nicht beauftragt werden.');
        } else if (err.status == 405) {
          this.notificationService.showError('Der Export ist in den Engagement Einstellungen deaktiviert oder unvollständig hinterlegt.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

  }




  // == List with Data Export overview =========================================

  public attachmentsNodes: WorkflowDataExport[] = [];

  // attachmentsNodesTest = [

  //   {
  //     title: 'TestI',
  //     date: "01.02.2022",
  //     key: '2'
  //   },
  //   {
  //     title: 'Test4',
  //     date: "01.02.2022",
  //     key: '1'
  //   }
  // ];


  onClickEvent(item: NavigationRow): void {
    //    console.log(item);

    this.fileservice.downloadPdf(item.dataItem.fileId).subscribe(res => {

      FileSaver.saveAs(res.blob, res.filename);
      this.notificationService.showSuccess('Die Datei wurde erfolgreich heruntergeladen');

    });

  }



  loadExportList(): void {
    this.finishedLoading = false;
    this.fileservice.getAllDataExportsAsync().subscribe({
      next: (res: WorkflowDataExport[]) => {
        if (res.length > 0) {
          res.forEach((item) => {
            item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;
          });

          // Sort List by createdDate descending
          res.sort((a, b) => { return b.createdDate.getTime() - a.createdDate.getTime() });
          this.attachmentsNodes = res;
        }

      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
      }

    });
    this.finishedLoading = true;

  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }





}
