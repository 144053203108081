import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DatasetItem } from 'app/_models/dataset.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-income-tax-sa-ucontrolling-company1300',
  templateUrl: './doc-corporate-income-tax-sa-ucontrolling-company1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTaxSaUControllingCompany1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxSaUControllingCompanyForm1300;
  }



  // public documentCITSaUTaxForeignIncomePayloadLength: number = 0;

  //#region Form ======================================================================================================

  /** Rechtsmittel-Check, State 1300 */
  public corporateIncomeTaxSaUControllingCompanyForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    id_assessmentYear: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),
    documentPayload: new UntypedFormArray([]),
    document_corporateIncomeTaxSaUControllingCompany_addressee: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_copyFor: new UntypedFormControl(""),
    //  document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name : new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber: new UntypedFormControl(""),
    //  document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name : new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_controlledCompany_legalEntity: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_controllingCompany_legalEntity: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime: new UntypedFormControl(""),
    document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime: new UntypedFormControl(""),

    corporateIncomeTaxSaUControllingCompany_addressee: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_copyFor: new UntypedFormControl(""),
    //  corporateIncomeTaxSaUControllingCompany_controlledCompany_name : new UntypedFormControl(""), //Referenzfeld for document_corporateIncomeTaxSaUControllingCompany_controlledCompany_legalEntity
    corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber: new UntypedFormControl(""),
    //  corporateIncomeTaxSaUControllingCompany_controllingCompany_name : new UntypedFormControl(""), //Referenzfeld for document_corporateIncomeTaxSaUControllingCompany_controllingCompany_legalEntity
    corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime: new UntypedFormControl(""),
    corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
  });


  //#endregion


  /** creates initial payloads for Organ and sets the datasetId */
  createOrganPayload(datasetId: number): UntypedFormGroup {

    const foreignIncomePayload = new UntypedFormGroup({

      id_companyIdentifier: new UntypedFormControl(""),
      document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt: new UntypedFormControl(""),
      document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt: new UntypedFormControl(""),
      document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt: new UntypedFormControl(""),
      document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt: new UntypedFormControl(""),
      document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt: new UntypedFormControl(""),

      corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt: new UntypedFormControl(""),
      corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt: new UntypedFormControl(""),
      corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt: new UntypedFormControl(""),
      corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt: new UntypedFormControl(""),
      corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt: new UntypedFormControl(""),

      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),


    });

    // console.log("Adding non empty payload");
    // console.log(datasetId);
    return foreignIncomePayload;
  }

  //#endregion

  //initializes the payload form array
  //switch case for diff datatypes
  addSingularPayload(payload: DatasetItem): void {
    const payloadArray = this.documentPayloadGetter();
    if (payload.dataTypeName != 'DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome')
      return;
    this.documentPayloadArrayWithDatasetId.push(this.createOrganPayload(payload.id));
    //this.documentPayloadGetter().push(this.createOrganPayload(payload.id));
    payloadArray.push(this.createOrganPayload(payload.id));
    // console.info("Add" + payload.dataTypeName);
    // this.documentCITSaUTaxForeignIncomePayloadLength = this.documentCITSaUTaxForeignIncomePayloadLength + 940;


  }

  //Adds a Payload object to the UntypedFormArray based on the dataType of the payload (used with add button, we dont have the datasetId yet)
  // not used obsolete?
  addPayload(dataType: string): void {
    const payloadArray = this.documentPayloadGetter();

    this.addPayloadAsync(dataType, this.workflowId).subscribe({
      next: (data) => {
        const newPayload = data as FormFieldItem[];
        const newDatasetID = newPayload[0].datasetId;
        if (newDatasetID != null) {
          payloadArray.push(this.createOrganPayload(newDatasetID));
          // this.showSuccess('New Data added with ID' + newDatasetID);
          this.documentPayloadDatasetIdArray.push(newDatasetID);
          // this.documentCITSaUTaxForeignIncomePayloadLength = this.documentCITSaUTaxForeignIncomePayloadLength + 940;
        }
      },
      error: (err: HttpErrorResponse) => {
        // this.showError('Fehler bei Hinzufügen: ' + err.message)
        console.error(err);
      }

    });

  }



}
