import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { UserLocal } from '../_models/user.model';
import { FileRestrictions, ErrorEvent } from "@progress/kendo-angular-upload";
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { DataService } from 'app/_services/StareApi/data.service';
import { DataImportTemplateItem } from 'app/_models/data-exchange.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationMessageItem } from 'app/_models/validation-message.model';


@Component({
  selector: 'app-admin-import-commune',
  templateUrl: './admin-import-commune.component.html',
  styleUrls: ['./admin-import-commune.component.scss'],
  standalone: false
})
export class AdminImportCommuneComponent implements OnInit {

  validationErrors: string[] = [];
  hasValidationErrors: boolean = false;

  constructor(
    public translateService: TranslateService,
    private titleService: Title,
    private apiDataService: DataService,
    private notificationService: WrapperNotificationService
  ) { }

  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.IMPORT_COMMUNE.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
  }

  // == Getting User =============================================================
  getUserMailAddress(): string {

    const jsonStr = localStorage.getItem('user') || '';
    const jsonObj = JSON.parse(jsonStr);
    const myuser = jsonObj as UserLocal;

    return myuser.mail;
  }

  // == Kendo Upload ==============================================

  // maxFileSize: 10 * 1024 * 1024,
  // autoUpload: false,
  // authToken: 'Bearer ' + localStorage.getItem('stare_token') || '',

  uploadSaveUrl = ENVIRONMENT.apiBaseUrl + 'Data/BulkImportAsync?dataType=2'; // should represent an actual API endpoint
  // uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  myRestrictions: FileRestrictions = {
    allowedExtensions: [".csv"],
    maxFileSize: 10 * 1024 * 1024,
  };



  uploadFailed(event: ErrorEvent): void {
    this.hasValidationErrors = true;
    this.validationErrors = [];
    if (event.response instanceof HttpErrorResponse) {
      const messageItems = event.response.error;
      messageItems.map((item: ValidationMessageItem) => { this.validationErrors.push(item.messageDE) });
      this.notificationService.showError('Der Import konnte nicht durchgeführt werden. Details werden im unteren Bereich angezeigt.');
    }
  }

  uploadSuccess(): void {
    this.hasValidationErrors = false;
    this.notificationService.showSuccess('Der Datenimport wurde erfolgreich durchgeführt.');
  }


  // == Template =========================================
  getTemplateFile(): void {
    console.info("getTemplateFile");
    this.apiDataService.getBulkImportTemplateFileType2().subscribe({
      next: (res: DataImportTemplateItem) => {
        const blob = new Blob([res.csvContent], { type: 'text/csv' });
        FileSaver.saveAs(blob, 'Template_for_STARE_Import.csv');

        // var downloadURL = window.URL.createObjectURL(blob);
        // var link = document.createElement('a');
        // link.href = downloadURL;
        // link.download = "Template_for_STARE_Import.csv";
        // link.click();
      }

    })

  }

}
