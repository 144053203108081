import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { SortDescriptor } from "@progress/kendo-data-query";
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, editToolsIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { CommuneItem } from 'app/_models/commune.model';
import { CommuneTaxRateItem, CommuneTaxRateForUpdate } from 'app/_models/commune-tax-rate.model';
import { ApiCommuneService } from 'app/_services/StareApiMaintenance/commune.service';

import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipService } from 'app/_services/tooltip.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';



@Component({
  selector: 'app-settings-commune',
  templateUrl: './settings-commune.component.html',
  styleUrls: ['./settings-commune.component.scss'],
  standalone: false
})
export class SettingsCommuneComponent implements OnInit {

  /** Displayed (filtered) data */
  public tableData = [] as CommuneItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as CommuneItem[];
  /** Tax Rates recieved from Back end for a selected commune wich communeID */
  public taxRateDataForSelectedCommuneOriginal = [] as CommuneTaxRateItem[];
  /** Tax Rates recieved from Back end for a selected commune wich communeID */
  public taxRateDataForSelectedCommune = [] as CommuneTaxRateItem[];
  /** API request is finished */
  public finishedLoading = false;
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;


  // ================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private notificationService: WrapperNotificationService,
    private apiCommuneService: ApiCommuneService,
    public tooltipService: TooltipService
  ) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.TAX_RATE.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

  }

  // == Resize Listener =============================================================================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load grid data ===================================================================================

  loadData(): void {
    this.finishedLoading = false;
    this.apiCommuneService.getAllCommunes().subscribe({
      next: (res: CommuneItem[]) => {
        this.tableData = res;
        this.tableDataOriginal = res;
        this.tableDataOriginal.forEach((commune: CommuneItem) => {
          if (commune.federalState) {
            commune.federalStateForDisplay = this.federalStateAsString(commune.federalState);
          }
          if (commune.country) {
            commune.countryForDisplay = this.countrySourceAsString(commune.country);
          }
        });
        // this.tableDataOriginal.forEach((commune: CommuneItem) => {
        //   commune.taxRateCount = 0;
        //   if (commune.taxRates) {

        //     commune.taxRateCount = commune.taxRates.length;
        //     commune.displayTaxRate = this.createDisplayTaxRate(commune.taxRates);
        //     // this.getTaxRatesByCommuneIdAsync(commune.id).subscribe({
        //     //   next: (res: CommuneTaxRateItem[]) => {
        //     //     commune.taxRates = res;
        //     //   },
        //     //   error: (err: HttpErrorResponse) => {
        //     //     console.error(err);
        //     //     this.finishedLoading = true;
        //     //   }
        //     // });
        //   }
        // });

        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });
  }


  // == Filling TaxRate Array for Commune Item

  loadTaxRate(communeId: number): void {
    this.finishedLoading = false;

    this.apiCommuneService.getTaxRatesByCommuneIdAsync(communeId).subscribe({
      next: (res: CommuneItem) => {
        this.taxRateDataForSelectedCommune = res.taxRates ? res.taxRates : [];
        this.taxRateDataForSelectedCommuneOriginal = res.taxRates ? res.taxRates : [];
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });
  }



  // == Grid Settings =======================================================================================================================================

  // Pagination 
  public pageSize = 20;



  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;
  public editSVG: SVGIcon = editToolsIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "name",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // Sorting Tax
  public currentTaxSort: SortDescriptor[] = [
    {
      field: "year",
      dir: "desc",
    },
  ];

  public sortTaxChange(sort: SortDescriptor[]): void {
    this.currentTaxSort = sort;
    // this.loadData();
  }
  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  public currentTaxRateFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {

      // // Get all displayed columns from kendo grid
      // let columns = [];
      // columns = this.grid.columns.toArray();

      // // Convert columns to filter object
      // columns.forEach((column: { field: any; }) => {
      //   if ((column.field != undefined)) {

      //     // Exclude isAdmin (boolean) column from global filter
      //     if (column.field != "isAdmin") {

      filter.filters.push({
        field: "id",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "name",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "textBlockIssuerDE",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "textBlockIssuerEN",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "ags",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "postalCode",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "city",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "federalStateForDisplay",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "countryForDisplay",
        operator: "contains",
        value: value,
      });

      //     }
      //   }
      // });
    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    };
    this.currentTaxRateFilter = {
      logic: "or",
      filters: [],
    }
  }

  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** Show Modal-Dialog for TaxRate editing. */
  public activeTaxRate: boolean = false;
  /** Show Modal-Dialog for TaxRate display. */
  public activeTaxRateDisplay: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;
  /** AGS for easier form fillingin Modal-Dialog. */
  public currentAGSForTaxRate: number = -1;
  /** This TaxRate ID for delete. */
  public thisTaxRateId: number = -1;

  /** Array for federalStateSourceData with name and ids. */
  public federalStateSourceData = [
    { id: 'BW', name: 'Baden-Württemberg' },
    { id: 'BY', name: 'Bayern' },
    { id: 'BE', name: 'Berlin' },
    { id: 'BB', name: 'Brandenburg' },
    { id: 'HB', name: 'Bremen' },
    { id: 'HH', name: 'Hamburg' },
    { id: 'HE', name: 'Hessen' },
    { id: 'MV', name: 'Mecklenburg-Vorpommern' },
    { id: 'NI', name: 'Niedersachsen' },
    { id: 'NW', name: 'Nordrhein-Westfalen' },
    { id: 'RP', name: 'Rheinland-Pfalz' },
    { id: 'SL', name: 'Saarland' },
    { id: 'SN', name: 'Sachsen' },
    { id: 'ST', name: 'Sachsen-Anhalt' },
    { id: 'SH', name: 'Schleswig-Holstein' },
    { id: 'TH', name: 'Thüringen' },
  ];

  public countrySourceData = [
    { id: 'AF', name: 'Afghanistan' },
    { id: 'AX', name: 'Aland Islands' },
    { id: 'AL', name: 'Albania' },
    { id: 'DZ', name: 'Algeria' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AD', name: 'Andorra' },
    { id: 'AO', name: 'Angola' },
    { id: 'AI', name: 'Anguilla' },
    { id: 'AQ', name: 'Antarctica' },
    { id: 'AG', name: 'Antigua And Barbuda' },
    { id: 'AR', name: 'Argentina' },
    { id: 'AM', name: 'Armenia' },
    { id: 'AW', name: 'Aruba' },
    { id: 'AU', name: 'Australia' },
    { id: 'AT', name: 'Austria' },
    { id: 'AZ', name: 'Azerbaijan' },
    { id: 'BS', name: 'Bahamas' },
    { id: 'BH', name: 'Bahrain' },
    { id: 'BD', name: 'Bangladesh' },
    { id: 'BB', name: 'Barbados' },
    { id: 'BY', name: 'Belarus' },
    { id: 'BE', name: 'Belgium' },
    { id: 'BZ', name: 'Belize' },
    { id: 'BJ', name: 'Benin' },
    { id: 'BM', name: 'Bermuda' },
    { id: 'BT', name: 'Bhutan' },
    { id: 'BO', name: 'Bolivia' },
    { id: 'BA', name: 'Bosnia And Herzegovina' },
    { id: 'BW', name: 'Botswana' },
    { id: 'BV', name: 'Bouvet Island' },
    { id: 'BR', name: 'Brazil' },
    { id: 'IO', name: 'British Indian Ocean Territory' },
    { id: 'BN', name: 'Brunei Darussalam' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'BF', name: 'Burkina Faso' },
    { id: 'BI', name: 'Burundi' },
    { id: 'KH', name: 'Cambodia' },
    { id: 'CM', name: 'Cameroon' },
    { id: 'CA', name: 'Canada' },
    { id: 'CV', name: 'Cape Verde' },
    { id: 'KY', name: 'Cayman Islands' },
    { id: 'CF', name: 'Central African Republic' },
    { id: 'TD', name: 'Chad' },
    { id: 'CL', name: 'Chile' },
    { id: 'CN', name: 'China' },
    { id: 'CX', name: 'Christmas Island' },
    { id: 'CC', name: 'Cocos (Keeling) Islands' },
    { id: 'CO', name: 'Colombia' },
    { id: 'KM', name: 'Comoros' },
    { id: 'CG', name: 'Congo' },
    { id: 'CD', name: 'Congo, Democratic Republic' },
    { id: 'CK', name: 'Cook Islands' },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'CI', name: 'Cote D\'Ivoire' },
    { id: 'HR', name: 'Croatia' },
    { id: 'CU', name: 'Cuba' },
    { id: 'CY', name: 'Cyprus' },
    { id: 'CZ', name: 'Czech Republic' },
    { id: 'DK', name: 'Denmark' },
    { id: 'DJ', name: 'Djibouti' },
    { id: 'DM', name: 'Dominica' },
    { id: 'DO', name: 'Dominican Republic' },
    { id: 'EC', name: 'Ecuador' },
    { id: 'EG', name: 'Egypt' },
    { id: 'SV', name: 'El Salvador' },
    { id: 'GQ', name: 'Equatorial Guinea' },
    { id: 'ER', name: 'Eritrea' },
    { id: 'EE', name: 'Estonia' },
    { id: 'ET', name: 'Ethiopia' },
    { id: 'FK', name: 'Falkland Islands (Malvinas)' },
    { id: 'FO', name: 'Faroe Islands' },
    { id: 'FJ', name: 'Fiji' },
    { id: 'FI', name: 'Finland' },
    { id: 'FR', name: 'France' },
    { id: 'GF', name: 'French Guiana' },
    { id: 'PF', name: 'French Polynesia' },
    { id: 'TF', name: 'French Southern Territories' },
    { id: 'GA', name: 'Gabon' },
    { id: 'GM', name: 'Gambia' },
    { id: 'GE', name: 'Georgia' },
    { id: 'DE', name: 'Germany' },
    { id: 'GH', name: 'Ghana' },
    { id: 'GI', name: 'Gibraltar' },
    { id: 'GR', name: 'Greece' },
    { id: 'GL', name: 'Greenland' },
    { id: 'GD', name: 'Grenada' },
    { id: 'GP', name: 'Guadeloupe' },
    { id: 'GU', name: 'Guam' },
    { id: 'GT', name: 'Guatemala' },
    { id: 'GG', name: 'Guernsey' },
    { id: 'GN', name: 'Guinea' },
    { id: 'GW', name: 'Guinea-Bissau' },
    { id: 'GY', name: 'Guyana' },
    { id: 'HT', name: 'Haiti' },
    { id: 'HM', name: 'Heard Island & Mcdonald Islands' },
    { id: 'VA', name: 'Holy See (Vatican City State)' },
    { id: 'HN', name: 'Honduras' },
    { id: 'HK', name: 'Hong Kong' },
    { id: 'HU', name: 'Hungary' },
    { id: 'IS', name: 'Iceland' },
    { id: 'IN', name: 'India' },
    { id: 'ID', name: 'Indonesia' },
    { id: 'IR', name: 'Iran, Islamic Republic Of' },
    { id: 'IQ', name: 'Iraq' },
    { id: 'IE', name: 'Ireland' },
    { id: 'IM', name: 'Isle Of Man' },
    { id: 'IL', name: 'Israel' },
    { id: 'IT', name: 'Italy' },
    { id: 'JM', name: 'Jamaica' },
    { id: 'JP', name: 'Japan' },
    { id: 'JE', name: 'Jersey' },
    { id: 'JO', name: 'Jordan' },
    { id: 'KZ', name: 'Kazakhstan' },
    { id: 'KE', name: 'Kenya' },
    { id: 'KI', name: 'Kiribati' },
    { id: 'KR', name: 'Korea' },
    { id: 'KW', name: 'Kuwait' },
    { id: 'KG', name: 'Kyrgyzstan' },
    { id: 'LA', name: 'Lao People\'s Democratic Republic' },
    { id: 'LV', name: 'Latvia' },
    { id: 'LB', name: 'Lebanon' },
    { id: 'LS', name: 'Lesotho' },
    { id: 'LR', name: 'Liberia' },
    { id: 'LY', name: 'Libyan Arab Jamahiriya' },
    { id: 'LI', name: 'Liechtenstein' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LU', name: 'Luxembourg' },
    { id: 'MO', name: 'Macao' },
    { id: 'MK', name: 'Macedonia' },
    { id: 'MG', name: 'Madagascar' },
    { id: 'MW', name: 'Malawi' },
    { id: 'MY', name: 'Malaysia' },
    { id: 'MV', name: 'Maldives' },
    { id: 'ML', name: 'Mali' },
    { id: 'MT', name: 'Malta' },
    { id: 'MH', name: 'Marshall Islands' },
    { id: 'MQ', name: 'Martinique' },
    { id: 'MR', name: 'Mauritania' },
    { id: 'MU', name: 'Mauritius' },
    { id: 'YT', name: 'Mayotte' },
    { id: 'MX', name: 'Mexico' },
    { id: 'FM', name: 'Micronesia, Federated States Of' },
    { id: 'MD', name: 'Moldova' },
    { id: 'MC', name: 'Monaco' },
    { id: 'MN', name: 'Mongolia' },
    { id: 'ME', name: 'Montenegro' },
    { id: 'MS', name: 'Montserrat' },
    { id: 'MA', name: 'Morocco' },
    { id: 'MZ', name: 'Mozambique' },
    { id: 'MM', name: 'Myanmar' },
    { id: 'NA', name: 'Namibia' },
    { id: 'NR', name: 'Nauru' },
    { id: 'NP', name: 'Nepal' },
    { id: 'NL', name: 'Netherlands' },
    { id: 'AN', name: 'Netherlands Antilles' },
    { id: 'NC', name: 'New Caledonia' },
    { id: 'NZ', name: 'New Zealand' },
    { id: 'NI', name: 'Nicaragua' },
    { id: 'NE', name: 'Niger' },
    { id: 'NG', name: 'Nigeria' },
    { id: 'NU', name: 'Niue' },
    { id: 'NF', name: 'Norfolk Island' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'NO', name: 'Norway' },
    { id: 'OM', name: 'Oman' },
    { id: 'PK', name: 'Pakistan' },
    { id: 'PW', name: 'Palau' },
    { id: 'PS', name: 'Palestinian Territory, Occupied' },
    { id: 'PA', name: 'Panama' },
    { id: 'PG', name: 'Papua New Guinea' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'PE', name: 'Peru' },
    { id: 'PH', name: 'Philippines' },
    { id: 'PN', name: 'Pitcairn' },
    { id: 'PL', name: 'Poland' },
    { id: 'PT', name: 'Portugal' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'QA', name: 'Qatar' },
    { id: 'RE', name: 'Reunion' },
    { id: 'RO', name: 'Romania' },
    { id: 'RU', name: 'Russian Federation' },
    { id: 'RW', name: 'Rwanda' },
    { id: 'BL', name: 'Saint Barthelemy' },
    { id: 'SH', name: 'Saint Helena' },
    { id: 'KN', name: 'Saint Kitts And Nevis' },
    { id: 'LC', name: 'Saint Lucia' },
    { id: 'MF', name: 'Saint Martin' },
    { id: 'PM', name: 'Saint Pierre And Miquelon' },
    { id: 'VC', name: 'Saint Vincent And Grenadines' },
    { id: 'WS', name: 'Samoa' },
    { id: 'SM', name: 'San Marino' },
    { id: 'ST', name: 'Sao Tome And Principe' },
    { id: 'SA', name: 'Saudi Arabia' },
    { id: 'SN', name: 'Senegal' },
    { id: 'RS', name: 'Serbia' },
    { id: 'SC', name: 'Seychelles' },
    { id: 'SL', name: 'Sierra Leone' },
    { id: 'SG', name: 'Singapore' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SB', name: 'Solomon Islands' },
    { id: 'SO', name: 'Somalia' },
    { id: 'ZA', name: 'South Africa' },
    { id: 'GS', name: 'South Georgia And Sandwich Isl.' },
    { id: 'ES', name: 'Spain' },
    { id: 'LK', name: 'Sri Lanka' },
    { id: 'SD', name: 'Sudan' },
    { id: 'SR', name: 'Suriname' },
    { id: 'SJ', name: 'Svalbard And Jan Mayen' },
    { id: 'SZ', name: 'Swaziland' },
    { id: 'SE', name: 'Sweden' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'SY', name: 'Syrian Arab Republic' },
    { id: 'TW', name: 'Taiwan' },
    { id: 'TJ', name: 'Tajikistan' },
    { id: 'TZ', name: 'Tanzania' },
    { id: 'TH', name: 'Thailand' },
    { id: 'TL', name: 'Timor-Leste' },
    { id: 'TG', name: 'Togo' },
    { id: 'TK', name: 'Tokelau' },
    { id: 'TO', name: 'Tonga' },
    { id: 'TT', name: 'Trinidad And Tobago' },
    { id: 'TN', name: 'Tunisia' },
    { id: 'TR', name: 'Turkey' },
    { id: 'TM', name: 'Turkmenistan' },
    { id: 'TC', name: 'Turks And Caicos Islands' },
    { id: 'TV', name: 'Tuvalu' },
    { id: 'UG', name: 'Uganda' },
    { id: 'UA', name: 'Ukraine' },
    { id: 'AE', name: 'United Arab Emirates' },
    { id: 'GB', name: 'United Kingdom' },
    { id: 'US', name: 'United States' },
    { id: 'UM', name: 'United States Outlying Islands' },
    { id: 'UY', name: 'Uruguay' },
    { id: 'UZ', name: 'Uzbekistan' },
    { id: 'VU', name: 'Vanuatu' },
    { id: 'VE', name: 'Venezuela' },
    { id: 'VN', name: 'Viet Nam' },
    { id: 'VG', name: 'Virgin Islands, British' },
    { id: 'VI', name: 'Virgin Islands, U.S.' },
    { id: 'WF', name: 'Wallis And Futuna' },
    { id: 'EH', name: 'Western Sahara' },
    { id: 'YE', name: 'Yemen' },
    { id: 'ZM', name: 'Zambia' },
    { id: 'ZW', name: 'Zimbabwe' }
  ];

  public federalStateAsString(id: string): string {
    //Contains the id?

    if (this.federalStateSourceData.find((item: { id: string; }) => item.id == id) == undefined) {
      return 'Unbekannt';
    }
    else {
      return this.federalStateSourceData.find((item: { id: string; }) => item.id == id)?.name ?? "";
    }
  }

  public countrySourceAsString(id: string): string {
    //Contains the id?

    if (this.countrySourceData.find((item: { id: string; }) => item.id == id) == undefined) {
      return 'Unbekannt';
    }
    else {
      return this.countrySourceData.find((item: { id: string; }) => item.id == id)?.name ?? "";
    }
  }

  // == Forms ======================================================


  // for inline edit of tax rate
  public editTaxRateForm: FormGroup = new FormGroup({
    id: new FormControl(-1),
    ags: new FormControl(0, Validators.required),
    year: new FormControl(0, Validators.required),
    propertyTaxA: new FormControl(0),
    propertyTaxB: new FormControl(0),
    tradeRate: new FormControl(0, Validators.required),

  });

  // == Handlers ============================================================================

  // open modal  for inline display of tax rate
  public editTaxRateHandler(args: CommuneItem): void {

    //no delete
    console.log(args);
    this.loadTaxRate(args.id);
    this.isNew = false;
    this.activeTaxRateDisplay = true;
    //commune id
    this.thisId = args.id;
    this.currentAGSForTaxRate = args.ags;
  }

  // open modal for inline  add of tax rate 
  public addTaxRateHandler(args?: CommuneItem): void {
    //no delete

    this.editTaxRateForm.reset();
    this.isNew = true;
    this.activeTaxRate = true;
    //commune id
    if (args != null) {
      this.editTaxRateForm.patchValue({
        ags: args.ags
      })
      this.editTaxRateForm.patchValue({
        id: args.id
      })
    } else {
      this.editTaxRateForm.patchValue({
        ags: this.currentAGSForTaxRate
      })
      this.editTaxRateForm.patchValue({
        id: this.thisId
      })
    }
  }

  // open modal for add of tax rate during viewing of rates
  public updateTaxRateHandler(args: NavigationRow): void {
    this.editTaxRateForm.reset(args.dataItem);
    this.editTaxRateForm.patchValue({
      id: args.dataItem.id
    })
    this.isNew = false;
    this.activeTaxRate = true;
    //commune id already set when editTaxRateHandler() is called
    //this.thisId = args.dataItem.id;
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }


  // for inline eddit of tax rate
  public closeTaxRateForm(): void {

    this.activeTaxRate = false;
  }

  // for inline eddit of tax rate
  public closeTaxRateDisplay(): void {
    this.activeTaxRate = false;
    this.activeTaxRateDisplay = false;
    this.taxRateDataForSelectedCommune = [];

    this.taxRateDataForSelectedCommuneOriginal = [];
  }

  // for edit of tax rate
  public onCancelTaxRate(e: PointerEvent): void {

    e.preventDefault();

    this.closeTaxRateForm();
  }
  // for display of tax rate
  public onCancelTaxRateDisplay(e: PointerEvent): void {
    e.preventDefault();

    this.closeTaxRateForm();
    this.closeTaxRateDisplay();

  }


  // == CRUD Operations ======================================================================

  public onSaveTaxRate(e: PointerEvent): void {
    e.preventDefault();

    // Update will add if doesnt exist yet
    let taxRateForUpdateItem = {} as CommuneTaxRateForUpdate;
    taxRateForUpdateItem = {
      ags: this.editTaxRateForm.value.ags,
      year: this.editTaxRateForm.value.year,
      propertyTaxA: this.editTaxRateForm.value.propertyTaxA,
      propertyTaxB: this.editTaxRateForm.value.propertyTaxB,
      tradeRate: this.editTaxRateForm.value.tradeRate,
    };


    this.apiCommuneService.updateTaxRateAsync(taxRateForUpdateItem).subscribe({
      next: (result: CommuneItem) => {
        this.notificationService.showSuccess('Die Gemeinde wurde erfolgreich aktualisiert.');
        this.loadTaxRate(result.id);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 400) {
          this.notificationService.showError('Die Gemeinde existiert bereits und konnte nicht aktualisiert werden.');
        } else if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Aktualisieren einer Gemeinde.');
        } else if (err.status == 410) {
          this.notificationService.showError('Die Gemeinde wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeTaxRateForm();
  }


  // for inline eddit of tax rate
  public onDeleteTaxRate(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == -1) {
      return;
    }

    this.apiCommuneService.deleteTaxRate(this.editTaxRateForm.value.id).subscribe({
      next: () => {
        this.notificationService.showSuccess('Tax Rate wurde erfolgreich gelöscht.');
        this.loadTaxRate(this.thisId);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen einer Gemeinde.');
        } else if (err.status == 410) {
          this.notificationService.showError('Die Gemeinde wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeTaxRateForm();
  }

}
