import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { SortDescriptor } from "@progress/kendo-data-query";
import { UserAccessForCreationItem, UserAccessItem } from 'app/_models/user-access.model';
import { UserItem, UserLocal } from 'app/_models/user.model';
import { ClientEngagementForList } from 'app/_models/client-engagement.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { KendoDropDownItem } from 'app/_models/dropdown-item.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiUserAccessService } from 'app/_services/StareApiMaintenance/user-access.service';
import { ApiUserService } from 'app/_services/StareApiMaintenance/user.service';
import { ApiClientEngagementMaintenanceService } from 'app/_services/StareApiMaintenance/client-engagement-maintenance.service';

import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';
import { ENVIRONMENT } from 'environments/environment';

@Component({
  selector: 'app-admin-useraccess',
  templateUrl: './admin-useraccess.component.html',
  styleUrls: ['./admin-useraccess.component.scss'],
  standalone: false
})

export class AdminUseraccessComponent {

  private _user: UserLocal = JSON.parse(localStorage.getItem('user')!) as UserLocal;
  /** Displayed (filtered) data */
  public tableData = [] as UserAccessItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as UserAccessItem[];
  /** API request is finished */
  public finishedLoading = false;
  public allUsers = [] as UserItem[];
  public allClientEngagements = [] as ClientEngagementForList[];
  public allUsersDropDown: Array<KendoDropDownItem> = [];
  public allUsersDropDownFiltered: Array<KendoDropDownItem> = [];
  public allClientEngagementsDropDown: Array<{ text: string; value: string }> = [];

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // ================================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private dateTimeService: DateTimeService,
    private apiClientEngagementService: ApiClientEngagementMaintenanceService,
    private apiUserAccessService: ApiUserAccessService,
    private apiUserService: ApiUserService,
    private notificationService: WrapperNotificationService,
    private apiAuthService: ApiAuthentificationService,
    public tooltipService: TooltipService
  ) { this.allData = this.allData.bind(this); }


  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.USER_RIGHTS.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });


    this.loadData();
  }

  // == Resize Listener =============================================================================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load grid data ===================================================================================

  public loadData(): void {
    this.finishedLoading = false;
    this.apiUserAccessService.getAllAccessItems().subscribe((data: UserAccessItem[]) => {


      // Enrich with user data
      this.apiUserService.getAllUsers().subscribe((users: UserItem[]) => {
        this.allUsers = users;

        // Create dropdown list for users
        this.allUsersDropDown = [];
        users.forEach((user: UserItem) => {
          this.allUsersDropDown.push({ text: user.mail, value: user.id, employeeNumber: user.employeeNumber.toString() });
        });
        this.allUsersDropDownFiltered = this.allUsersDropDown;
        data.forEach((item: UserAccessItem) => {
          const user = users.find((user: UserItem) => user.id == item.userId);
          if (user) {
            item.firstname = user.firstname;
            item.lastname = user.lastname;
            item.mail = user.mail;
          }
        });

        // Convert date 
        data.forEach((item: UserAccessItem) => {
          item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;
        });

        // Enrich with client engagement data
        this.apiClientEngagementService.getAllClientEngagements().subscribe((clientEngagements: ClientEngagementForList[]) => {
          this.allClientEngagements = clientEngagements;

          // Create dropdown list for client engagements
          this.allClientEngagementsDropDown = [];
          clientEngagements.forEach((clientEngagement: ClientEngagementForList) => {
            this.allClientEngagementsDropDown.push({ text: clientEngagement.name ?? "-", value: clientEngagement.clientEngagementKey });
          });

          data.forEach((item: UserAccessItem) => {
            const clientEngagement = clientEngagements.find((clientEngagement: ClientEngagementForList) => clientEngagement.clientEngagementKey == item.clientEngagementKey);
            if (clientEngagement) {
              item.clientEngagementName = clientEngagement.name;
            }
          });

          this.tableData = data;
          this.tableDataOriginal = data;
          //console.log(data);

          this.finishedLoading = true;
        });
      });

    });
  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 
  public pageSize = 20;



  // Icons 
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "mail",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    //this.loadData();
  }

  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {

      // Get all displayed columns from kendo grid

      filter.filters.push({
        field: "id",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "userId",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "mail",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "clientEngagementKey",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "clientEngagementName",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "and",
      filters: [],
    }
  }

  public handleFilterChange(searchTerm: string): void {
    const normalizedQuery = searchTerm.toLowerCase();

    // search in all three fields diplayed in the popup table
    const filterExpression = (item: KendoDropDownItem) =>
      item.text.toLowerCase().includes(normalizedQuery) ||
      item.employeeNumber.toLowerCase().includes(normalizedQuery)

    this.allUsersDropDownFiltered = this.allUsersDropDown.filter(filterExpression);
  }

  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;

  // == Forms ==============================================================================
  public editForm: FormGroup = new FormGroup({
    userId: new FormControl(""),
    clientEngagementKey: new FormControl("", Validators.required),
    isPreparer: new FormControl(false),
    isReviewer: new FormControl(false),
    // isPreparerDeadline: new FormControl(false),
    // isReviewerDeadline: new FormControl(false),
    isCITTeam: new FormControl(false),
    canManageOwnUser: new FormControl(false),
    canDeleteWorkflows: new FormControl(false),
    canReopenWorkflows: new FormControl(false),

    canDataExport: new FormControl(false),
    canDataImport: new FormControl(false),
    canConfigureWorkflows: new FormControl(false),
  });

  // == Handlers ==========================================================================
  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.thisId = args.dataItem.id;
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.thisId = 0;
  }

  public closeForm(): void {
    this.active = false;

  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  // == CRUD Operations ======================================================================

  public onSave(e: PointerEvent): void {
    e.preventDefault();

    // Map UserAccessForCreationItem from form
    let userAccessForCreationItem = {} as UserAccessForCreationItem;
    userAccessForCreationItem = {
      userId: this.editForm.value.userId,
      clientEngagementKey: this.editForm.value.clientEngagementKey,
      isPreparer: this.editForm.value.isPreparer ? true : false,
      isReviewer: this.editForm.value.isReviewer ? true : false,
      isCITTeam: this.editForm.value.isCITTeam ? true : false,
      canDeleteWorkflows: this.editForm.value.canDeleteWorkflows ? true : false,
      canReopenWorkflows: this.editForm.value.canReopenWorkflows ? true : false,
      canManageOwnUser: this.editForm.value.canManageOwnUser ? true : false,

      canDataExport: this.editForm.value.canDataExport ? true : false,
      canDataImport: this.editForm.value.canDataImport ? true : false,
      canConfigureWorkflows: this.editForm.value.canConfigureWorkflows ? true : false,
    };


    this.apiUserAccessService.getAddAccessItems(userAccessForCreationItem).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die Berechtigung wurde erfolgreich eingetragen.');
        // this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 400) {
          this.notificationService.showError('Die Berechtigung existiert bereits und konnte nicht (erneut) angelegt werden.');
        } else if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Anlegen einer Berechtigung.');
        } else if (err.status == 410) {
          this.notificationService.showError('Der Anwender oder das Engagement konnte nicht gefunden werden.');
        } else if (err.status == 406) {
          this.notificationService.showError('Dieser Benutzer existiert bereits in der Benutzerliste und kann nicht als SDC Benutzer hinzugefügt werden.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {

        if (this._user.mail === this.editForm.value.mail) {
          this.apiAuthService.setRedirection(ENVIRONMENT.primaryDomain + "/admin-useraccess", this._user.clientEngagementKey);
          const success = new Promise<boolean>(() => {
            this.apiAuthService.changeClientandRedirect();
          });
          success.then(() => {
            // handle result here
            this.apiAuthService.resetRedirection();
          });
        } else {
          this.loadData();
        }

      }
    });

    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == 0) {
      return;
    }

    this.apiUserAccessService.getDeleteAccessItems(this.thisId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die Berechtigung wurde erfolgreich gelöscht.');

      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen einer Berechtigung.');
        } else if (err.status == 410) {
          this.notificationService.showError('Die Berechtigung wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde sie bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        if (this._user.mail === this.editForm.value.mail) {
          this.apiAuthService.setRedirection(ENVIRONMENT.primaryDomain + "/admin-useraccess", this._user.clientEngagementKey);
          const success = new Promise<boolean>(() => {
            this.apiAuthService.changeClientandRedirect();
          });
          success.then(() => {
            // handle result here
            this.apiAuthService.resetRedirection();
          });
        } else {
          this.loadData();
        }

      }
    });

    this.closeForm();
  }


}
