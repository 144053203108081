import { HttpClient, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkflowItem } from 'app/_models/workflow.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { XbrlParserService } from '../xbrl-parser.service';
import { WorkflowAttachmentForCreationItem, WorkflowAttachmentForUpdateItem, WorkflowAttachmentItem } from 'app/_models/workflow-attachment.model';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiWorkflowAttachmentService {


  constructor(
    private http: HttpClient,

    private xbrlParserService: XbrlParserService
  ) { }

  /**
    * Get all attachments for a given workflow id.
    * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
    */
  getAllAttachmentsByWorkflowId(id: number): Observable<WorkflowAttachmentItem[]> {
    return this.http.get<WorkflowAttachmentItem[]>(ENVIRONMENT.apiBaseUrl + 'WorkflowAttachment/GetAllAsync/' + id, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
  * API: Add a new attachment for a given workflow id.
  */
  addAttachment(item: WorkflowAttachmentForCreationItem): Observable<WorkflowItem> {
    return this.http.post<WorkflowItem>(ENVIRONMENT.apiBaseUrl + 'WorkflowAttachment/AddAsync', item, HTTP_OPTIONS).pipe(
      map((data: WorkflowItem) => {
        if (data.validationMessageList) {
          for (const item of data.validationMessageList) {
            item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
          }
        }
        return data;
      }));
  }

  /**
 * API: Update an attachment by workflow attachment id.
 */
  updateAttachmentByWorkflowAttachmentId(itemId: number, item: WorkflowAttachmentForUpdateItem): Observable<WorkflowItem> {
    return this.http.put<WorkflowItem>(`${ENVIRONMENT.apiBaseUrl}WorkflowAttachment/UpdateAsync/${itemId}`, item, HTTP_OPTIONS).pipe(
      map((data: WorkflowItem) => {
        if (data.validationMessageList) {
          for (const item of data.validationMessageList) {
            item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
          }
        }
        return data;
      }));
  }

  /**
 * API: Delete an attachment by workflow attachment id.
 */
  deleteAttachmentByWorkflowAttachmentId(itemId: number): Observable<HttpResponseBase> {
    return this.http.delete<HttpResponseBase>(ENVIRONMENT.apiBaseUrl + 'WorkflowAttachment/RemoveAsync/' + itemId, HTTP_OPTIONS);
  }
}

