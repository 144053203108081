import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { DateTimeService } from 'app/_services/date-time.service';
import { SortDescriptor } from "@progress/kendo-data-query";
import { UserLocal } from 'app/_models/user.model';
import { ClientEngagementForCreation, ClientEngagementForUpdate, ClientEngagementItem } from 'app/_models/client-engagement.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { WbsItem } from 'app/_models/wbs.model';
import { ApiClientEngagementMaintenanceService } from 'app/_services/StareApiMaintenance/client-engagement-maintenance.service';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';

import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';

@Component({
  selector: 'app-admin-clientengagement',
  templateUrl: './admin-clientengagement.component.html',
  styleUrls: ['./admin-clientengagement.component.scss'],
  standalone: false
})

export class AdminClientEngagementComponent {

  private _user: UserLocal = {} as UserLocal;
  /** Displayed (filtered) data */
  public tableData = [] as ClientEngagementItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as ClientEngagementItem[];
  public wbsDataOriginal = [] as WbsItem[];
  public wbsDataEngagement = [] as WbsItem[];
  /** API request is finished */
  public finishedLoading = false;
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;



  // ================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private dateTimeService: DateTimeService,
    private notificationService: WrapperNotificationService,
    private apiClientEngagement: ApiClientEngagementMaintenanceService,
    private apiAuthService: ApiAuthentificationService,
    public tooltipService: TooltipService
  ) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });

    this.loadData();
  }

  // == Resize Listener =============================================================================================================
  public pageHeight: number = window.innerHeight - 125;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  // == Load grid data ===================================================================================

  public loadData(): void {
    this.finishedLoading = false;
    this.apiClientEngagement.getAllClientEngagements().subscribe((data: ClientEngagementItem[]) => {


      // Convert date 
      data.forEach((item: ClientEngagementItem) => {
        if (item.createdDate) {
          item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;
        }
        if (item.updatedDate) {
          item.updatedDate = this.dateTimeService.convertUTCTimeToLocalTime(item.updatedDate);
        }
        if (item.provisioningCompletedDate) {
          item.provisioningCompletedDate = this.dateTimeService.convertUTCTimeToLocalTime(item.provisioningCompletedDate);
        }
        if (item.tenantSource) {
          item.tenantSourceAsString = this.tenantSourceAsString(item.tenantSource);
        }
      });

      // Enrich with client engagement data
      this.apiClientEngagement.getAllWBS().subscribe((wbs: WbsItem[]) => {
        wbs.forEach((wbsItem: WbsItem) => {
          const clientEngagement = data.find((clientEngagement: ClientEngagementItem) => clientEngagement.clientEngagementKey == wbsItem.clientEngagementKey);
          if (clientEngagement) {
            // Concatenate all client engagements

            let postfix = "";
            if (wbsItem.isDeactivated) {
              postfix = " (deaktiviert)";
            }

            clientEngagement.wbsListAsString = clientEngagement.wbsListAsString ? clientEngagement.wbsListAsString + ", " + wbsItem.wbs + postfix : wbsItem.wbs + postfix;

          }
        });
        this.wbsDataOriginal = wbs;
        this.tableData = data;
        this.tableDataOriginal = data;
        this.finishedLoading = true;
      });

    });

  }

  // == Grid Settings ===========================================================================

  // Pagination 
  public pageSize = 20;



  // Icons 
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "name",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
  }


  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {



      filter.filters.push({
        field: "clientEngagementKey",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "name",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "wbsListAsString",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "tenantSourceAsString",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }


  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog: For adding */
  public active: boolean = false;
  /** Show Modal-Dialog: For update */
  public activeUpdate: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisClientEngagementKey: string = '';

  /** Array for tenantSource with name and ids. Id -> Backend TenantSourceEnum */
  public tenantSourceData = [
    { id: 1, name: 'DMS' },
    { id: 3, name: 'SDC' },
  ];

  public tenantSourceAsString(id: number): string {
    //Contains the id?

    if (this.tenantSourceData.find((item: { id: number; }) => item.id == id) == undefined) {
      return 'Unbekannt';
    }
    else {
      return this.tenantSourceData.find((item: { id: number; }) => item.id == id)?.name ?? "";
    }
  }

  // == Forms ======================================================

  /** For add */
  public editForm: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    wbs: new FormControl("", Validators.required),
    engagementManagerMail: new FormControl("", [Validators.required, Validators.pattern("^\\w+([-+.']\\w+)*@(pwc.de|pwc.com|pwcinternal.com)$")]),
    legalEntityName: new FormControl("", Validators.required),
    tenantSource: new FormControl(1, Validators.required),
    exportToDatalake: new FormControl(false),
    blobName: new FormControl(""),
    blobContainerName: new FormControl(""),
    manualUploadAllowed: new FormControl(true),

    isForTesting: new FormControl(true),
    hasSdu: new FormControl(false),

  });

  /** For Update */
  public updateForm: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    tenantSource: new FormControl(1, Validators.required),
    exportToDatalake: new FormControl(false),
    blobName: new FormControl(""),
    blobContainerName: new FormControl(""),
    manualUploadAllowed: new FormControl(true),
    isForTesting: new FormControl(true),
    hasSdu: new FormControl(false),
  });

  // == Handlers ============================================================================
  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      this.editForm.reset(args.dataItem);
      this.updateForm.reset(args.dataItem);
      this.isNew = false;
      this.active = this.isNew;
      this.activeUpdate = !this.isNew;
      this.thisClientEngagementKey = args.dataItem.clientEngagementKey;

      if (this.activeUpdate) {
        this.wbsDataEngagement = this.wbsDataOriginal.filter((wbsItem: WbsItem) => wbsItem.clientEngagementKey == this.thisClientEngagementKey);

        // Sort by wbs
        this.wbsDataEngagement.sort((a: WbsItem, b: WbsItem) => {
          if (a.wbs.toLocaleUpperCase() < b.wbs.toLocaleUpperCase()) { return -1; }
          if (a.wbs.toLocaleUpperCase() > b.wbs.toLocaleUpperCase()) { return 1; }
          return 0;
        });
      }
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.updateForm.reset();
    this.isNew = true;
    this.active = this.isNew;
    this.activeUpdate = !this.isNew;
    this.thisClientEngagementKey = '';
  }

  public closeForm(): void {
    this.active = false;
    this.activeUpdate = false;

  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  // == CRUD Operations ======================================================================

  public onSave(e: PointerEvent): void {
    e.preventDefault();

    if (this.isNew) {

      // Map clientEngagementForCreationItem from form
      let clientEngagementForCreationItem = {} as ClientEngagementForCreation;

      clientEngagementForCreationItem = {
        name: this.editForm.value.name,
        wbs: this.editForm.value.wbs,
        engagementManagerMail: this.editForm.value.engagementManagerMail,
        legalEntityName: this.editForm.value.legalEntityName,
        tenantSource: this.editForm.value.tenantSource,
        exportToDatalake: this.editForm.value.exportToDatalake ?? false,
        blobName: this.editForm.value.blobName,
        blobContainerName: this.editForm.value.blobContainerName,
        manualUploadAllowed: this.editForm.value.manualUploadAllowed ?? true,
        isForTesting: this.updateForm.value.isForTesting ?? true,
        hasSdu: this.updateForm.value.hasSdu ?? true
        //accessForUserId: this.editForm.value.accessForUserId,

      };

      this.apiClientEngagement.getAddClientEngagement(clientEngagementForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Das Engagement wurde erfolgreich eingetragen.');
          // this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 400) {
            this.notificationService.showError('Das Engagement existiert bereits und konnte nicht (erneut) angelegt werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Anlegen eines Engagements.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Anwender oder das Engagement konnte nicht gefunden werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          this.apiAuthService.getSessionInfoObservable().subscribe(data => {
            this._user.clientEngagementKey = data.clientEngagementKey;
            this._user.clientEngagements = data.clientEngagements;
            this._user.firstname = data.firstname;
            this._user.tenantSource = data.tenantSource;
            this._user.lastname = data.lastname;
            this._user.userId = data.userId;
            this._user.isAdmin = data.isAdmin;

            this._user.canReopenWorkflows = data.canReopenWorkflows;
            this._user.canManageCommuneTaxRates = data.canManageCommuneTaxRates
            this._user.canManageOwnUser = data.canManageOwnUser;
            this._user.mail = data.mail;


            this._user.isPreparer = data.isPreparer;
            this._user.isReviewer = data.isReviewer;
            this._user.isCITTeam = data.isCITTeam;

            this._user.canManageSdcUsers = data.canManageSdcUsers;
            this._user.canDataExport = data.canDataExport;
            this._user.canDataImport = data.canDataImport;
            this._user.canConfigureWorkflows = data.canConfigureWorkflows;

            localStorage.setItem('user', JSON.stringify(this._user));
            window.location.reload();
          });

        }
      });
    } else {
      // Update

      // Map clientEngagementForUpdateItem from form
      let clientEngagementForUpdateItem = {} as ClientEngagementForUpdate;

      clientEngagementForUpdateItem = {
        name: this.updateForm.value.name,
        tenantSource: this.updateForm.value.tenantSource,
        exportToDatalake: this.updateForm.value.exportToDatalake ?? false,
        blobName: this.updateForm.value.blobName,
        blobContainerName: this.updateForm.value.blobContainerName,
        manualUploadAllowed: this.updateForm.value.manualUploadAllowed ?? true,

        isForTesting: this.updateForm.value.isForTesting ?? true,

        hasSdu: this.updateForm.value.hasSdu ?? true
      };

      this.apiClientEngagement.getUpdateClientEngagement(this.thisClientEngagementKey, clientEngagementForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Das Engagement wurde erfolgreich aktualisiert.');
          // this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 400) {
            this.notificationService.showError('Das Engagement nicht geändert werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Ändern eines Engagements.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Anwender oder das Engagement konnte nicht gefunden werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {

          this.apiAuthService.getSessionInfoObservable().subscribe(data => {
            this._user.clientEngagementKey = data.clientEngagementKey;
            this._user.clientEngagements = data.clientEngagements;
            this._user.firstname = data.firstname;
            this._user.tenantSource = data.tenantSource;
            this._user.lastname = data.lastname;
            this._user.userId = data.userId;
            this._user.isAdmin = data.isAdmin;

            this._user.canReopenWorkflows = data.canReopenWorkflows;
            this._user.canManageCommuneTaxRates = data.canManageCommuneTaxRates
            this._user.canManageOwnUser = data.canManageOwnUser;
            this._user.mail = data.mail;

            this._user.isPreparer = data.isPreparer;
            this._user.isReviewer = data.isReviewer;
            this._user.isCITTeam = data.isCITTeam;

            this._user.canManageSdcUsers = data.canManageSdcUsers;
            this._user.canDataExport = data.canDataExport;
            this._user.canDataImport = data.canDataImport;
            this._user.canConfigureWorkflows = data.canConfigureWorkflows;

            localStorage.setItem('user', JSON.stringify(this._user));
            window.location.reload();
          });
        }
      });

    }
    this.closeForm();

  }

  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisClientEngagementKey == '') {
      return;
    }

    this.apiClientEngagement.getDeleteClientEngagement(this.thisClientEngagementKey).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die Löschung wurde beauftragt und dauert einige Minuten. Bitte prüfe die Liste in Kürze.');
        // this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen eines Engagements.');
        } else if (err.status == 406) {
          this.notificationService.showError('Das zu löschende Engagement darf nicht das Aktive sein. Bitte auf ein anderes Engagement zuerst wechseln.');
        } else if (err.status == 410) {
          this.notificationService.showError('Die Engagement wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde es bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        this.apiAuthService.getSessionInfoObservable().subscribe(data => {
          this._user.clientEngagementKey = data.clientEngagementKey;
          this._user.clientEngagements = data.clientEngagements;
          this._user.firstname = data.firstname;
          this._user.tenantSource = data.tenantSource;
          this._user.lastname = data.lastname;
          this._user.userId = data.userId;
          this._user.isAdmin = data.isAdmin;
          this._user.canReopenWorkflows = data.canReopenWorkflows;
          this._user.canManageCommuneTaxRates = data.canManageCommuneTaxRates
          this._user.canManageOwnUser = data.canManageOwnUser;
          this._user.mail = data.mail;

          this._user.isPreparer = data.isPreparer;
          this._user.isReviewer = data.isReviewer;
          this._user.isCITTeam = data.isCITTeam;

          this._user.canManageSdcUsers = data.canManageSdcUsers;
          this._user.canDataExport = data.canDataExport;
          this._user.canDataImport = data.canDataImport;
          this._user.canConfigureWorkflows = data.canConfigureWorkflows;

          localStorage.setItem('user', JSON.stringify(this._user));
          window.location.reload();
        });


      }
    });

    this.closeForm();
  }


}
