<div class="stareTopic">
    {{ 'APP.MAIL_EDITOR.TITLE' | translate }} (Viewer)
</div>
<div>

    <div style="text-align: right; width: 900px; padding-left: 10px;">

        <!-- <button kendoButton (click)="onReset()" kendoTooltip #btnReset
            title="Der E-Mail Text wird zurückgesetzt und neu erstellt.">
            Text neu erstellen
        </button> &nbsp;
        <button kendoButton type="submit" [disabled]="!editorForm.valid && isEditable" (click)="onSubmit()"
            kendoTooltip #btnSave title="Speichert den aktuellen Zustand der E-Mail.">
            Speichern
        </button> &nbsp;&nbsp;|&nbsp;&nbsp; -->

        <button kendoButton type="submit" (click)="onClickCancelButton()" [svgIcon]="backSVG" kendoTooltip
            title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.BACK_TO_TASKS' | translate }}">
        </button> &nbsp;&nbsp;|&nbsp;&nbsp;
        <button kendoButton type="submit" (click)="onExecute()" kendoTooltip #btnExecute
            title="Versendet die E-Mail erneut.">
            Erneut senden
        </button> &nbsp;
    </div>
    <br>

    <form class="k-form k-form-md k-form-horizontal" style="width: 800px" [formGroup]="editorForm">



        <kendo-formfield orientation="horizontal">
            <kendo-label class="plainText" labelCssClass="k-form-label" for="to" id="toLabel"
                [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.TO.LABEL' | translate"></kendo-label>
            <kendo-textbox type="text" id="to" formControlName="to" autocomplete="off" [readonly]="true">
            </kendo-textbox>
            <kendo-formerror>
                {{
                'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.TO.ERROR.REQUIRED' |
                translate
                }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
            <kendo-label class="plainText" labelCssClass="k-form-label" for="cc"
                [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.CC.LABEL' | translate"></kendo-label>
            <kendo-textbox id="cc" formControlName="cc" type="text" autocomplete="off"
                [readonly]="true"></kendo-textbox>
            <kendo-formerror>
                {{
                'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.CC.ERROR.REQUIRED' |
                translate
                }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
            <kendo-label class="plainText" labelCssClass="k-form-label" id="subLabel" for="subject"
                [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.SUBJECT.LABEL' | translate"></kendo-label>
            <kendo-textbox type="text" id="subject" formControlName="subject" autocomplete="off"
                [readonly]="true"></kendo-textbox>
            <kendo-formerror>
                {{
                'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.SUBJECT.ERROR.REQUIRED' |
                translate
                }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">

            <kendo-editor id="body" class="payload" formControlName="body" [resizable]="resizable" [iframe]="false"
                [readonly]="true">
                <kendo-toolbar #toolbar [overflow]="false">
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-colorpicker kendoEditorForeColor
                        [paletteSettings]="paletteSettings"></kendo-toolbar-colorpicker>
                    <!-- <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist> -->
                    <kendo-toolbar-dropdownlist kendoEditorFontSize [data]="fontData"></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                </kendo-toolbar>

            </kendo-editor>
        </kendo-formfield>


        <br />
        <kendo-formfield showHints="always" showErrors="always" orientation="horizontal">

            <ng-template #toolTipGridTemplate let-anchor>
                <span>{{ anchor.nativeElement.innerText}}</span>
            </ng-template>
            <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td" style="width: 900px"
                (mouseover)="showTooltip($event)" [ngStyle]="{'height.px': pageHeight}">
                <kendo-grid #grid [kendoGridBinding]="attachments" [loading]="!finishedLoading" scrollable="none"
                    [hideHeader]="true" (dblclick)="onClickEvent(grid.activeRow)" (cellClick)="cellClickHandler($event)"
                    [navigable]="true" [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true">
                    <kendo-grid-messages [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                        [noRecords]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.NO_ATTACHMENTS.LABEL' | translate">
                    </kendo-grid-messages>
                    <kendo-grid-column field="filename" [width]="900"
                        [title]="'APP.SETTINGS.LOG.TABLE.HEADER.FILENAME' | translate"></kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-formfield>
        <br>


        <div *ngIf="isFormLoading" class="k-i-loading"></div>
        <br>
    </form>
    <br>
    <br>
    <br>
</div>