import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
//import { ApiService } from "../api.service";



import { MyTaskItem } from '../_models/task.model';
import { WorkflowComment } from '../_models/workflow-comment.model';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ENVIRONMENT } from '../../environments/environment';
import { FileRestrictions, ErrorEvent } from "@progress/kendo-angular-upload";



import { WbsItem } from '../_models/wbs.model';
import {
	PageChangeEvent,
	MultipleSortSettings,
	ColumnMenuSettings,
	GridComponent,
	GridSize,
	NavigationRow,
	CellClickEvent,
	RowClassArgs,
	ColumnBase
} from '@progress/kendo-angular-grid';
import { process, State } from "@progress/kendo-data-query";
import { SVGIcon, fileExcelIcon, gearIcon, arrowRotateCwIcon, uploadIcon, filterClearIcon, cancelIcon, checkOutlineIcon, exclamationCircleIcon, xOutlineIcon } from '@progress/kendo-svg-icons';

import { GridSettings } from 'app/_models/kendo-grid-settings.model';
import { StatePersistingService } from "../_services/state-persisting.service";
import { ColumnSettings } from 'app/_models/kendo-column-settings.model';
import { UserLocal } from 'app/_models/user.model';
import { formatDate } from '@angular/common';

import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { ButtonThemeColor } from '@progress/kendo-angular-buttons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { DateTimeService } from 'app/_services/date-time.service';
import { ApiWbsService } from 'app/_services/StareApi/wbs.service';
import { ApiQualityCheckService } from 'app/_services/StareApiMaintenance/quality-check.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';

import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';
import { ClientEngagementForList } from 'app/_models/client-engagement.model';




@Component({
	selector: 'app-task-all',
	templateUrl: './tasks-all.component.html',
	styleUrls: ['./tasks-all.component.scss'],
	standalone: false
})


export class TasksAllComponent implements OnInit, OnDestroy {


	public currentUser = null as UserLocal | null;
	public currentDateAsString = (new Date()).toISOString();

	clientEngagemenList: ClientEngagementForList[] = [];



	private selectedWbsId: number = -1;
	private qualityCheckStatus: number = -1;
	public active: boolean = false;
	public finishedLoading = false;
	public smallSize: GridSize = "small";

	// == Table Data ===========================================

	public tableTasksDataRaw = [] as MyTaskItem[];
	public tableTasksDataFiltered = [] as MyTaskItem[];



	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private router: Router,
		private apiUIViewerService: UIViewerService,
		private dateTimeService: DateTimeService,
		private persistingService: StatePersistingService,
		private notificationService: WrapperNotificationService,
		private apiWbsService: ApiWbsService,
		private apiQualityCheckService: ApiQualityCheckService,
		private apiAuthService: ApiAuthentificationService

	) {
		//Excel export binding
		//this.allData = this.allData.bind(this);

		this.persistingService.checkAndUpdateIfAvailable("tasksAllGridSettings", this.gridSettings);
		const gridSettings: GridSettings<MyTaskItem> =
			this.persistingService.get("tasksAllGridSettings");

		//after checkAndUpdateIfAvailable, gridSettings is never null
		this.gridSettings = this.mapGridSettings(gridSettings);

		// if (gridSettings !== null) {
		// 	this.gridSettings = this.mapGridSettings(gridSettings);
		// } else {
		// 	this.persistingService.set('tasksAllGridSettings', this.gridSettings);
		// }
		//Setting translated column headers

		this.gridSettings.columnsConfig.forEach(column => {

			// only if column.title not null and not empty
			if ((column.title) && (column.title !== '')) {

				this.translateService.get(column.title as string).subscribe((title: string) => {
					column.titleToDisplay = title;
				});
			}
		})

	}

	ngOnInit(): void {
		this.translateService.get('APP.TASKS.TITLE_ALL').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});

		this.gridSettings.columnsConfig.forEach(column => {

			// only if column.title not null and not empty
			if ((column.title) && (column.title !== '')) {

				this.translateService.get(column.title as string).subscribe((title: string) => {
					column.titleToDisplay = title;
				});
			}
		})
		this.loadTasks();
		this.setQualityCheckButtonIcon();

		// Load User from Local Storage
		const jsonStr = localStorage.getItem('user') || '';
		if (jsonStr.length > 0) {
			const jsonObj = JSON.parse(jsonStr);
			this.currentUser = (jsonObj as UserLocal)
		}

		this.clientEngagemenList = this.currentUser?.clientEngagements || [];
	}

	ngOnDestroy(): void { }

	// == Resize the Grid ========================================================================
	@HostListener("window:resize", ["$event"])
	onResize(): void {
		this.pageHeight = window.innerHeight - 125;

	}

	public pageHeight: number = window.innerHeight - 125;
	// End of resizing the grid

	// == Load Data =============================================================================

	//Load all tasks to fill the table
	loadTasks(): void {
		//console.log("element.documentDate");
		this.finishedLoading = false;
		if (!this.showHiddenTasks) {
			this.apiUIViewerService.getAllTasksOverall(false, false)

				//this.apiService.getMyTasks(this.showHiddenTasks, false)
				.subscribe((response: MyTaskItem[]) => {
					//	console.log(response);  // Debug
					// Convert field documentDate and acutualStateDate from response to Date type
					response.forEach((element: MyTaskItem) => {


						if (element.documentDate) {
							element.documentDate = this.dateTimeService.convertUTCTimeToLocalTime(element.documentDate);


							element.documentDatePlus30 = new Date(element.documentDate!);

							element.documentDatePlus30?.setDate(element.documentDate!.getDate() + 30);

						}
						if (element.actualStateDate) {
							element.actualStateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.actualStateDate);
						}
						if (element.createDate) {
							element.createDate = this.dateTimeService.convertUTCTimeToLocalTime(element.createDate)!;
						}
						if (element.updateDate) {
							element.updateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.updateDate);
						}
						if (element.deadlineDate) {
							element.deadlineDate = this.dateTimeService.convertUTCTimeToLocalTime(element.deadlineDate);
						}
						if (element.clientEngagementKey) {
							element.clientEngagementForDisplay = this.getClientEngagementNamefromGuid(element.clientEngagementKey);
						}

						if (element.workflowCommentList) {
							element.workflowCommentList.forEach((comment: WorkflowComment) => {
								if (comment.displayDate) {
									comment.displayDate = this.dateTimeService.convertUTCTimeToLocalTime(comment.displayDate)!;
								}

							});
						}
						element.commentsCount = element.workflowCommentList?.length || 0;

					});

					this.tableTasksDataRaw = response;
					this.tableTasksDataRaw.forEach((task: MyTaskItem) => {
						if (task.workflowCommentList) {
							task.displayComments = this.createDisplayComment(task.workflowCommentList);
						}
					});

					this.tableTasksDataFiltered = this.tableTasksDataRaw;
					const skip = this.gridSettings.state.skip ? this.gridSettings.state.skip : -1;
					if (this.tableTasksDataFiltered.length < skip || skip == -1) {
						this.persistingService.updateSetting('tasksAllGridSettings');
					}
					this.gridSettings.gridData = process(this.tableTasksDataFiltered, this.gridSettings.state).data;


					this.finishedLoading = true;
				},
					(error: HttpErrorResponse) => {
						console.error(error);
					});
		} else {
			this.apiUIViewerService.getAllTasksOverall(true, false)
				//this.apiService.getMyTasks(this.showHiddenTasks, false)
				.subscribe((response: MyTaskItem[]) => {
					//	console.log(response);  // Debug
					// Convert field documentDate and acutualStateDate from response to Date type
					response.forEach((element: MyTaskItem) => {

						if (element.documentDate) {
							element.documentDate = this.dateTimeService.convertUTCTimeToLocalTime(element.documentDate);


							element.documentDatePlus30 = new Date(element.documentDate!);

							element.documentDatePlus30?.setDate(element.documentDate!.getDate() + 30);
						}
						if (element.actualStateDate) {
							element.actualStateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.actualStateDate);
						}
						if (element.createDate) {
							element.createDate = this.dateTimeService.convertUTCTimeToLocalTime(element.createDate)!;
						}
						if (element.updateDate) {
							element.updateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.updateDate);
						}
						if (element.deadlineDate) {
							element.deadlineDate = this.dateTimeService.convertUTCTimeToLocalTime(element.deadlineDate);
						}

						// console.log(element.clientEngagementKey);
						// console.log(this.clientEngagemenList);
						if (element.clientEngagementKey) {
							element.clientEngagementForDisplay = this.getClientEngagementNamefromGuid(element.clientEngagementKey);
							// console.log(element.clientEngagementForDisplay);
						}

						if (element.workflowCommentList) {
							element.workflowCommentList.forEach((comment: WorkflowComment) => {
								if (comment.displayDate) {
									comment.displayDate = this.dateTimeService.convertUTCTimeToLocalTime(comment.displayDate)!;
								}

							});
						}
						element.commentsCount = element.workflowCommentList?.length || 0;

					});

					this.tableTasksDataRaw = response;
					this.tableTasksDataRaw.forEach((task: MyTaskItem) => {
						if (task.workflowCommentList) {
							task.displayComments = this.createDisplayComment(task.workflowCommentList);
						}
					});

					this.tableTasksDataFiltered = this.tableTasksDataRaw;
					const skip = this.gridSettings.state.skip ? this.gridSettings.state.skip : -1;
					if (this.tableTasksDataFiltered.length < skip || skip == -1) {
						this.persistingService.updateSetting('tasksAllGridSettings');
					}

					this.gridSettings.gridData = process(this.tableTasksDataFiltered, this.gridSettings.state).data;

					this.finishedLoading = true;
				},
					(error: HttpErrorResponse) => {
						console.error(error);
					});
		}
	}

	// == Excel Export ==============================================================================
	// Compounding data for Export to Excel
	// public allData(): ExcelExportData {
	// 	const result: ExcelExportData = {
	// 		data: process(this.tableTasksDataFiltered, {
	// 			filter: this.gridSettings.state.filter,
	// 			sort: this.gridSettings.state.sort,
	// 		}).data,
	// 	};

	// 	return result;
	// }

	// public exportToExcel(grid: GridComponent): void {
	// 	grid.saveAsExcel();
	// }
	// == Grid Settings ===================================================================================

	//Pagination



	//on Page Change
	public pageChange(event: PageChangeEvent): void {
		this.gridSettings.state.skip = event.skip;
		//this.loadTasks();		// not neccessary
	}
	//Settings for individual columns 
	public menuSettings: ColumnMenuSettings = {
		lock: false,
		stick: false,
		view: 'tabbed',
		filter: true

	};

	//sorting
	public sortSettings: MultipleSortSettings = {
		mode: "multiple",
		initialDirection: "asc",
		allowUnsort: true,
		showIndexes: true,
	};

	// Icon for excel export
	public excelSVG: SVGIcon = fileExcelIcon;
	public reloadSVG: SVGIcon = arrowRotateCwIcon;
	public uploadSVG: SVGIcon = uploadIcon;
	public filterClearSVG: SVGIcon = filterClearIcon;
	public settingsClearSVG: SVGIcon = gearIcon;

	// == Quality Check ===================================================================

	// Default Quality Check Icon
	public qualityCheckButtonSVG: SVGIcon = cancelIcon;
	// Undefiniert und Nicht Anwendbar
	public undefinedQCButtonSVG: SVGIcon = cancelIcon;
	// Erfolgreich
	public okQCButtonSVG: SVGIcon = checkOutlineIcon;
	// Warnung
	public warningQCButtonSVG: SVGIcon = exclamationCircleIcon;
	// Fehler
	public errorQCButtonSVG: SVGIcon = xOutlineIcon;
	// Default Quality Check Icon Color
	public qualityCheckButtonColor: ButtonThemeColor = "light";
	// Tooltip string
	public qualityCheckTooltip: string = "Tooltip";

	setQualityCheckButtonIcon(): void {
		this.translateService.get('APP.QUALITY_CHECK.STATES.RESULT').subscribe((title: string) => {
			this.qualityCheckTooltip = title;
		});

		this.apiQualityCheckService.getQualityCheckResult().subscribe((response: number) => {
			this.qualityCheckStatus = response;
			switch (this.qualityCheckStatus) {
				case 0:
					this.qualityCheckButtonSVG = this.undefinedQCButtonSVG;
					this.translateService.get('APP.QUALITY_CHECK.STATES.UNDEFINED').subscribe((title: string) => {
						this.qualityCheckTooltip = this.qualityCheckTooltip + title;
					});
					break;
				case 1:
					this.qualityCheckButtonSVG = this.undefinedQCButtonSVG;
					this.translateService.get('APP.QUALITY_CHECK.STATES.NOT_APPLICABLE').subscribe((title: string) => {
						this.qualityCheckTooltip = this.qualityCheckTooltip + title;
					});
					break;
				case 2:
					this.qualityCheckButtonSVG = this.okQCButtonSVG;
					this.qualityCheckButtonColor = "success";
					this.translateService.get('APP.QUALITY_CHECK.STATES.OK').subscribe((title: string) => {
						this.qualityCheckTooltip = this.qualityCheckTooltip + title;
					});
					break;
				case 3:
					this.qualityCheckButtonSVG = this.warningQCButtonSVG;
					this.qualityCheckButtonColor = "warning";
					this.translateService.get('APP.QUALITY_CHECK.STATES.WARNING').subscribe((title: string) => {
						this.qualityCheckTooltip = this.qualityCheckTooltip + title;
					});
					break;
				case 4:
					this.qualityCheckButtonSVG = this.errorQCButtonSVG;
					this.qualityCheckButtonColor = "error";
					this.translateService.get('APP.QUALITY_CHECK.STATES.ERROR').subscribe((title: string) => {
						this.qualityCheckTooltip = this.qualityCheckTooltip + title;
					});
					break;

				default:

			}

		}, (error: HttpErrorResponse) => {
			console.error(error);
		});

	}

	goToQualityCheck(): void {
		this.router.navigate(['/quality-check']);
	}


	// == Uploader =============================================================================================================

	@ViewChild('fileUploadModal', { static: true })
	fileUploadModal!: HTMLDialogElement;


	// == File Upload ============================================================
	selectedWBS: WbsItem = {
		id: -1,
		wbs: "string",
		isDeactivated: false,
		engagementManagerMail: "string",
		clientEngagementKey: "string",
		sourceUrlPart: "string"
	};
	wbsList = [] as WbsItem[];



	onSelectWbsItem(event: WbsItem): void {

		this.selectedWBS = event;
		this.selectedWbsId = this.selectedWBS.id;
		this.uploadSaveUrl = ENVIRONMENT.apiBaseUrl + 'Workflow/AddAsync?wbsId=' + this.selectedWbsId; // should represent an actual API endpoint

	}


	// === Uploader =================================

	hasValidationErrors: boolean = false;

	uploadSaveUrl = ENVIRONMENT.apiBaseUrl + 'Workflow/AddAsync?wbsId=' + this.selectedWbsId; // should represent an actual API endpoint
	// uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
	noWBSSelected = true;
	myRestrictions: FileRestrictions = {
		allowedExtensions: [".pdf"],
		maxFileSize: 10 * 1024 * 1024,
	};

	uploadFailed(event: ErrorEvent): void {
		this.hasValidationErrors = true;

		// console.log(event);

		// Check event.response is TYPE OF HttpErrorResponse
		if ((event.response instanceof HttpErrorResponse) && (event.response.status === 405)) {

			this.notificationService.showError('Der Import wurde für dieses Engagement deaktiviert. Ein manueller Upload ist nicht möglich. Bitte das DMS benutzen.');
		}
		else {
			this.notificationService.showError('Der Import konnte nicht durchgeführt werden. Details: ' + event.response.body);
		}
	}

	uploadSuccess(): void {
		this.hasValidationErrors = false;
		this.notificationService.showSuccess('Der Import wurde erfolgreich durchgeführt.');
	}
	public closeForm(): void {
		this.active = false;
	}

	openFileUploadModal(): void {
		this.active = true;
		// Get WBS Codes
		this.apiWbsService.getAllWBS().subscribe((response: WbsItem[]) => {
			this.wbsList = response.filter(item => item.isDeactivated === false).sort((a, b) => (a.wbs.toLocaleLowerCase() > b.wbs.toLocaleLowerCase()) ? 1 : -1);  // Sort by WBS Code
			// this.wbsList = response.map((x) => {

			// 	return { value: x.id, label: x.wbs, disabled: x.isDeactivated }
			// });


			// Find first element in wbsList
			const item = this.wbsList.find(d => d.isDeactivated === false);
			this.selectedWBS = item != null ? item : this.selectedWBS;

			this.selectedWbsId = this.selectedWBS.id;
			this.uploadSaveUrl = ENVIRONMENT.apiBaseUrl + 'Workflow/AddAsync?wbsId=' + this.selectedWbsId;
			this.noWBSSelected = this.selectedWbsId == -1 ? true : false;
			// console.log("this.selectedWBS on open");
			// console.log(this.selectedWBS);
			// console.log(this.selectedWBS.id);
		});


		this.fileUploadModal.showModal();
	}

	closeFileUploadModal(): void {
		this.fileUploadModal.close();
		this.loadTasks();
	}


	// == Hidden Tasks ===========================================================================
	public showHiddenTasks: boolean = localStorage.getItem('show_hidden_tasks') === "true" ? true : false;

	public showDescription: boolean = localStorage.getItem('show_tasks_description') !== null ? localStorage.getItem('show_tasks_description') === 'true' : true;

	public rowClass = (context: RowClassArgs): { gray: boolean } => {
		if (context.dataItem.isHidden) {
			return { gray: true };
		} else {
			return { gray: false };
		}
	};


	// == Filter ===============================================================================
	public tableSearch = '';



	removeFilter(): void {
		this.gridSettings.state.filter = {
			logic: 'and',
			filters: []
		};
		this.persistingService.set('tasksAllGridSettings', this.gridSettings);
	}


	@ViewChild("grid", { static: false })
	grid!: GridComponent;

	public onFilter(value: string, grid: GridComponent): void {

		const filter: CompositeFilterDescriptor = {
			logic: "or",
			filters: [],
		}

		// Check if value is empty
		if (value == "") {
			filter.filters = [];
		} else {

			// Get all displayed columns from kendo grid
			let columns = [];
			columns = this.grid.columns.toArray();

			// Convert columns to filter object
			columns.forEach((column: { title: string; }) => {
				if ((column.title != undefined)) {
					const setting = this.gridSettings.columnsConfig.find((item: ColumnSettings) => item.titleToDisplay === column.title);
					// Exclude isAdmin (boolean) column from global filter
					if ((setting && (setting.field != "documentDate") && (setting.field != "documentDatePlus30") &&
						(setting.field != "comment") &&
						(setting.field != "createDate") &&
						(setting.field != "updateDate") &&
						(setting.field != "deadlineDate") &&
						(setting.field != "deadlineDate"))) {

						filter.filters.push({
							field: setting.field,
							operator: "contains",
							value: value,
						});
					}
				}
			});
		}

		this.gridSettings.state.filter = filter;

		//this.tableTasksDataFiltered = filterBy(this.tableTasksDataRaw, filter);
		this.gridSettings.state.skip = 0;
		this.saveGridSettings(grid);
	}

	private mapDateFilter = (descriptor: CompositeFilterDescriptor) => {
		const filters = descriptor.filters || [];

		filters.forEach((filter: FilterDescriptor | CompositeFilterDescriptor) => {
			if ((filter as CompositeFilterDescriptor) && (filter as CompositeFilterDescriptor).filters) {
				this.mapDateFilter((filter as CompositeFilterDescriptor));
			} else if ((filter as FilterDescriptor).field === "FirstOrderedOn" && (filter as FilterDescriptor).value) {
				(filter as FilterDescriptor).value = this.dateTimeService.convertUTCTimeToLocalTime(new Date((filter as FilterDescriptor).value))!;
			}
		});
	}



	// == Persist State =============================================================================

	// == base grid Settings for Persist State ===========================
	public gridSettings: GridSettings<MyTaskItem> = {

		creationDate: new Date(this.currentDateAsString),
		items: null,
		backendFilterValue: null,
		state: {
			skip: 0,
			take: 20,
			sort: [
			]
			,
			filter: {
				logic: 'and',
				filters: []
			},

		},
		gridData: process(this.tableTasksDataFiltered, {
			skip: 0,
			take: 20,
			filter: {
				logic: 'and',
				filters: []
			},
		}).data,
		columnsConfig: [
			{
				field: 'workflowId',
				title: "APP.TASKS.TABLE.HEADER.WORKFLOW_ID",
				titleToDisplay: '',
				filterable: true,

				sortable: true,
				filter: "text",
				format: "",
				hidden: false,
				width: 80,
				orderIndex: 1,

			},
			{
				field: 'filename',
				title: "APP.TASKS.TABLE.HEADER.FILE_NAME",
				filterable: true,
				filter: "text",
				format: "",
				sortable: true,
				hidden: false,
				width: 141,
				orderIndex: 2
			},
			{
				field: 'documentDate',
				title: 'APP.TASKS.TABLE.HEADER.DOCUMENT_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",
				format: "dd.MM.yyyy",
				width: 176,
				hidden: false,
				sortable: true,
				orderIndex: 3
			}, {
				field: 'documentDatePlus30',
				title: 'APP.TASKS.TABLE.HEADER.DOCUMENT_DATE_30',
				filterable: true,
				titleToDisplay: '',
				filter: "date",

				sortable: true,
				format: "dd.MM.yyyy",
				width: 176,
				hidden: false,
				orderIndex: 4
			},
			// {
			// 	field: 'deadlineNumber',
			// 	title: "APP.TASKS.TABLE.HEADER.DEADLINE_NUMBER",
			// 	filterable: true,
			// 	titleToDisplay: '',
			// 	filter: "text",
			// 	format: "",
			// 	sortable: true,
			// 	hidden: false,
			// 	width: 173,
			// 	orderIndex: 5
			// },
			{
				field: 'clientEngagementForDisplay',
				title: "APP.TASKS.TABLE.HEADER.CLIENT_ENGAGEMENT",
				filterable: true,
				titleToDisplay: '',
				filter: "text",
				format: "",
				sortable: true,
				hidden: false,
				width: 173,
				orderIndex: 5
			},
			{
				field: 'actualStateName',
				title: 'APP.TASKS.TABLE.HEADER.ACTUAL_STATE_NAME',
				filterable: true,
				titleToDisplay: '',
				filter: "text",
				format: "{0:c}",
				sortable: true,
				width: 171,
				hidden: false,
				orderIndex: 6
			},
			{
				field: 'comment',
				title: "APP.TASKS.TABLE.HEADER.COMMENT",
				filterable: false,
				titleToDisplay: '',
				filter: "boolean",
				format: "",
				sortable: false,
				width: 157,
				hidden: false,
				orderIndex: 7
			},

			{
				field: 'typeOfTaxAssessment',
				title: 'APP.TASKS.TABLE.HEADER.TYPE_OF_TAX_ASSESSMENT',
				filterable: true,
				filter: "text",
				sortable: true,
				hidden: true,
				width: 149,
				orderIndex: 8
			},
			{
				field: 'legalEntityName',
				title: "APP.TASKS.TABLE.HEADER.LEGAL_ENTITY_NAME",
				filterable: true,
				titleToDisplay: '',
				sortable: true,
				filter: "text",
				format: "{0:c}",
				hidden: true,
				width: 161,
				orderIndex: 9
			},
			{
				field: 'createDate',
				title: 'APP.TASKS.TABLE.HEADER.CREATE_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",
				sortable: true,
				format: "dd.MM.yyyy",
				hidden: true,
				width: 196,
				orderIndex: 10
			},
			{
				field: 'updateDate',
				title: 'APP.TASKS.TABLE.HEADER.UPDATE_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",
				sortable: true,
				format: "dd.MM.yyyy",
				width: 212,
				hidden: true,
				orderIndex: 11
			},
			{
				field: 'deadlineDate',
				title: 'APP.TASKS.TABLE.HEADER.DEADLINE_DATE',
				filterable: true,
				titleToDisplay: '',
				filter: "date",
				format: "dd.MM.yyyy",
				width: 212,
				sortable: true,
				hidden: true,
				orderIndex: 12
			},
			{
				field: 'commentsCount',
				title: 'APP.TASKS.TABLE.HEADER.COMMENTS_COUNT',
				filterable: false,
				titleToDisplay: '',
				sortable: false,
				filter: "text",
				format: "",
				hidden: true,
				width: 100,
				orderIndex: 13
			},

		]
	};

	removeSettings(): void {
		localStorage.removeItem('tasksAllGridSettings');
		window.location.reload();
	}


	//Persisting service 
	public dataStateChange(state: State, grid: GridComponent): void {
		this.gridSettings.state = state;
		this.gridSettings.gridData = process(this.tableTasksDataFiltered, state).data;
		this.saveGridSettings(grid);

	}

	public get savedStateExists(): boolean {
		return !!this.persistingService.get("tasksAllGridSettings");
	}

	public saveGridSettings(grid: GridComponent): void {

		const columns = grid.columns;
		const colSettings = this.gridSettings.columnsConfig;
		colSettings.forEach((col: ColumnSettings) => {
			const column = columns.toArray().find((item: ColumnBase) => item.title === col.titleToDisplay);
			if (column) {
				col.width = column.width;
				col._width = column['_width'];
				col.hidden = column.hidden;
				col.orderIndex = column.orderIndex;
			}
		});
		// console.log(columns);
		//add only the required column properties to save local storage space
		const gridConfig = {
			creationDate: new Date(this.currentDateAsString),
			items: this.gridSettings.items,
			backendFilterValue: this.gridSettings.backendFilterValue,
			state: this.gridSettings.state,
			gridData: this.gridSettings.gridData,
			columnsConfig: colSettings
		};
		this.persistingService.set('tasksAllGridSettings', gridConfig);
	}



	public mapGridSettings(gridSettings: GridSettings<MyTaskItem>): GridSettings<MyTaskItem> {
		const state = gridSettings.state;
		if (state.filter) {
			this.mapDateFilter(state.filter);
		}

		return {

			creationDate: gridSettings.creationDate,
			items: gridSettings.items,
			backendFilterValue: gridSettings.backendFilterValue,
			state,
			columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
			gridData: process(this.tableTasksDataFiltered, state).data,
		};
	}




	// == Open File =======================================================================================

	openFile(event: NavigationRow): void {
		if (event != null && !event.dataItem.isHidden) {

			// Get ClientEngagementKey from localStorage of the user
			const jsonStr = localStorage.getItem('user') || '';
			let clientEngagementKey = '';
			if (jsonStr.length > 0) {
				const jsonObj = JSON.parse(jsonStr);
				clientEngagementKey = (jsonObj as UserLocal).clientEngagementKey
			}
			if (event.dataItem.clientEngagementKey != clientEngagementKey) {
				let url = '';
				if (event.dataItem.workflowType == 12) {
					url = ENVIRONMENT.primaryDomain + '/mail-editor?w=' + event.dataItem.workflowId + '&s=' + event.dataItem.actualStateId + '&c=' + event.dataItem.clientEngagementKey;
				} else {
					url = ENVIRONMENT.primaryDomain + '/assessment-review?w=' + event.dataItem.workflowId + '&s=' + event.dataItem.actualStateId + '&c=' + event.dataItem.clientEngagementKey;
				}
				this.apiAuthService.setRedirection(url, event.dataItem.clientEngagementKey);
				this.router.navigate(['/login']);
				return;
			}
			// clientEngagementKey is the selected CE
			if (event.dataItem.workflowType == 12) {
				this.router.navigate(
					['/mail-editor'],
					{
						queryParams: {
							'w': event.dataItem.workflowId,
							's': event.dataItem.actualStateId,
							'c': clientEngagementKey
						}
					}
				);
			}
			else {

				//this.router.navigate(['/assessment-review', event.selectedRows[0].dataItem.workflowId, event.selectedRows[0].dataItem.actualStateId, 'sdfdsf']);


				this.router.navigate(
					['/assessment-review'],
					{
						queryParams: {
							'w': event.dataItem.workflowId,
							's': event.dataItem.actualStateId,
							'c': clientEngagementKey
						}
					}
				);
			}
		}

	}


	// == Handlers =====================================================================
	public cellClickHandler(args: CellClickEvent): void {
		if (!args.dataItem.isHidden) {
			// console.log(args);
			args.sender.focus();
		}
	}


	// == Helpers ============================================================



	public createDisplayComment(workflowCommentList: WorkflowComment[]): string {
		let displayComments = "";

		// filter the comments

		workflowCommentList = workflowCommentList.filter((x) => x.isDeleted === false);

		// sort the comments
		workflowCommentList.sort((a, b) => (a.displayDate < b.displayDate) ? 1 : -1)

		const maxComments = 3;

		// compound the comments
		for (let i = 0; i < maxComments; i++) {
			if (workflowCommentList[i] != null) {
				displayComments = displayComments + workflowCommentList[i].displayUsername + " - " + formatDate(workflowCommentList[i].displayDate, 'dd.MM.yyyy, HH:mm', 'de') + "" + ":\n\r" + workflowCommentList[i].comment + "\n\r\n\r";

			}
		}

		// add the number of hidden comments
		if (workflowCommentList.length > maxComments) {
			if (workflowCommentList.length - maxComments === 1) {
				displayComments = displayComments + "... und " + (workflowCommentList.length - maxComments) + " weiterer Kommentar.";
			}
			else {
				displayComments = displayComments + "... und " + (workflowCommentList.length - maxComments) + " weitere Kommentare.";
			}
		}

		return displayComments; //.slice(0, -4);
	}

	getClientEngagementNamefromGuid(guid: string): string | null {
		const clientEngagementName = this.clientEngagemenList.find((item) => (item.clientEngagementKey == guid));
		if (clientEngagementName) {
			return clientEngagementName.name;
		}
		else {
			return "";
		}
	}



}

