import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DatasetItem } from 'app/_models/dataset.model';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';


@Component({
  selector: 'app-doc-corporate-income-tax-separate-and-uniform-determination',
  templateUrl: './doc-corporate-income-tax-separate-and-uniform-determination.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTaxSeparateAndUniformDeterminationComponent extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxSeparateAndUniformDeterminationForm;
  }


  // public documentCITSaUCompanyPayloadLength: number = 0;




  //#region Form ======================================================================================================

  public corporateIncomeTaxSeparateAndUniformDeterminationForm = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    reference_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    id_assessmentYear: new UntypedFormControl(""),
    documentPayload: new UntypedFormArray([]),
    document_corporateIncomeTaxGuE_incomeCommercial: new UntypedFormControl(""),
    document_corporateIncomeTaxGuE_incomeCurrent: new UntypedFormControl(""),
    document_corporateIncomeTaxGuE_profitTotalTradeBalance: new UntypedFormControl(""),
    document_corporateIncomeTaxGuE_profitSpecialBusinessAssets: new UntypedFormControl(""),
    document_corporateIncomeTaxGuE_tradeTaxBase: new UntypedFormControl(""),
    document_corporateIncomeTaxGuE_shares: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),

    corporateIncomeTaxGuE_incomeCommercial: new UntypedFormControl(""),
    corporateIncomeTaxGuE_incomeCurrent: new UntypedFormControl(""),
    corporateIncomeTaxGuE_profitTotalTradeBalance: new UntypedFormControl(""),
    corporateIncomeTaxGuE_profitSpecialBusinessAssets: new UntypedFormControl(""),
    corporateIncomeTaxGuE_tradeTaxBase: new UntypedFormControl(""),
    corporateIncomeTaxGuE_shares: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
    id_notificationName: new UntypedFormControl(""),
  });


  /** creates payloads for GUE and sets the datasetId */
  createGUEPayload(datasetId: number): UntypedFormGroup {

    const companyPayload = new UntypedFormGroup({

      id_companyIdentifier: new UntypedFormControl(""),
      id_legalEntityKey: new UntypedFormControl(""),
      //  document_corporateIncomeTaxGuE_company_typeOfParticipation : new UntypedFormControl(""), // Task 24630

      document_corporateIncomeTaxGuE_company_typeOfParticipationInteger: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_distribution: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_incomeCommercial: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_incomeCurrent: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_profitTotalTradeBalance: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_profitSpecialBusinessAssets: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_tradeTaxBase: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_sharesTradeTaxBase: new UntypedFormControl(""),
      // document_corporateIncomeTaxGuE_company_billableLossPreviousYear: new UntypedFormControl(""),
      // document_corporateIncomeTaxGuE_company_nonDeductibleLoss: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_billableLoss: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_overallBalanceSheet: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_supplementaryBalanceSheet: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_totalCapital: new UntypedFormControl(""),
      document_corporateIncomeTaxGuE_company_commercialEquity: new UntypedFormControl(""),
      // document_corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability: new UntypedFormControl(""), Task 27081
      // document_corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice: new UntypedFormControl(""),


      //  corporateIncomeTaxGuE_company_typeOfParticipation : new UntypedFormControl(""), // Task 24630
      corporateIncomeTaxGuE_company_typeOfParticipationInteger: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_distribution: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_incomeCommercial: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_incomeCurrent: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_profitTotalTradeBalance: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_profitSpecialBusinessAssets: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_tradeTaxBase: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_sharesTradeTaxBase: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_billableLossPreviousYear: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_nonDeductibleLoss: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_billableLoss: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_overallBalanceSheet: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_supplementaryBalanceSheet: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_totalCapital: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_commercialEquity: new UntypedFormControl(""),
      // corporateIncomeTaxGuE_company_offsettableAmountOfExternalLiability: new UntypedFormControl(""),
      corporateIncomeTaxGuE_company_taxableProfitLossInSubsequentNotice: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),
      dataTypeName: new UntypedFormControl('DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany', { initialValueIsDefault: true }),


    });
    // console.log("Adding non empty payload GuE");
    // console.log(datasetId);
    return companyPayload;
  }

  //creates initial payloads for tax breakdown commune and sets the datasetId
  createDocumentDynamicFieldsPayload(datasetId: number): UntypedFormGroup {
    const documentDynamicFieldsPayload = new UntypedFormGroup({
      document_dynamic_name: new UntypedFormControl(""),
      document_dynamic_value: new UntypedFormControl(""),
      document_dynamic_referenceValue: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, {
        initialValueIsDefault: true,
      }),
      dataTypeName: new UntypedFormControl('DocumentDynamicFields', {
        initialValueIsDefault: true,
      })
    });
    return documentDynamicFieldsPayload;
  }

  //initializes the payload form array
  //switch case for diff datatypes
  addSingularPayload(payload: DatasetItem): void {
    const payloadArray = this.documentPayloadGetter();
    if (payload.dataTypeName == 'DocumentDynamicFields') {
      this.documentPayloadArrayWithDatasetId.push(this.createDocumentDynamicFieldsPayload(payload.id));

      payloadArray.push(this.createDocumentDynamicFieldsPayload(payload.id));
    } else if (payload.dataTypeName == 'DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany') {

      this.documentPayloadArrayWithDatasetId.push(this.createGUEPayload(payload.id));
      //this.documentPayloadGetter().push(this.createGUEPayload(payload.id));
      payloadArray.push(this.createGUEPayload(payload.id));
      // console.info("Add" + payload.dataTypeName);
      // this.documentCITSaUCompanyPayloadLength = this.documentCITSaUCompanyPayloadLength + 2220;
    } else {
      return;
    }

  }


  addPayload(dataType: string): void {
    const payloadArray = this.documentPayloadGetter();

    this.addPayloadAsync(dataType, this.workflowId).subscribe({
      next: (data) => {
        const newPayload = data as FormFieldItem[];
        const newDatasetID = newPayload[0].datasetId;
        if (newDatasetID != null) {
          if (dataType == 'DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany') {

            payloadArray.push(this.createGUEPayload(newDatasetID));
          } else if (dataType == 'DocumentDynamicFields') {
            payloadArray.push(this.createDocumentDynamicFieldsPayload(newDatasetID));
          }
          // this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
          this.documentPayloadDatasetIdArray.push(newDatasetID);
          // this.documentCITSaUCompanyPayloadLength = this.documentCITSaUCompanyPayloadLength + 2220;
        }
      },
      error: (err: HttpErrorResponse) => {
        // this.showError('Fehler bei Hinzufügen: ' + err.message)
        console.error(err);
      }

    });

  }



}
