import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StareInputFieldsService } from 'app/_services/stare-input-fields.service';
import { UntypedFormGroup } from '@angular/forms';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { ValidationDeactivationTemporaryItem } from 'app/_models/validation-deactivation.model';
import { TaxNoticeNameItem } from 'app/_models/tax-notice-name.model';
import { EnumDataTypeStrings, ListsService } from 'app/_services/lists.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-stare-toggle-dropdown-notice-name',
  templateUrl: './stare-toggle-dropdown-notice-name.component.html',
  styleUrl: './stare-toggle-dropdown-notice-name.component.scss',
  standalone: false
})

export class StareToggleDropdownNoticeNameComponent {
  @Output() onFieldFocus = new EventEmitter();
  //Sends value to disable to doc-base for saving
  @Output() onReferenceDisable = new EventEmitter();


  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() xbrlNameToggle: string = '';
  @Input() xbrlNameDropdown: string = '';
  @Input() datasetId: number = -1;
  @Input() isReadOnly: boolean = false;
  @Input() selection: number = 1;
  //(DMS vs. SDC Workflow)
  @Input() workflowType: number = -1;
  @Input() documentType: string = "";
  //ex. Kassenzeicht und Steuernummer
  @Input() overrideLabel: string = '';
  @Input() isArchiv: boolean = false;
  public label: string = '';
  public class: string = '';

  public fieldClass: string = '';

  public filterable: boolean = true;
  public listHeight: number = 500;

  public dataForDisplay: TaxNoticeNameItem[] = [];
  public dataOriginal: TaxNoticeNameItem[] = [];



  @Input() dataOptionsSource: DropDownListItem[] = [];

  public labelTrue: string = '';
  public labelFalse: string = '';
  public classToggle: string = '';
  public currentValue: boolean = false;
  public dataforDisplayToggle: DropDownListItem[] = [];
  //public decimals: number = 0;
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;


  public defaultItem: TaxNoticeNameItem = {
    id: 0,
    baseName: 'Select item',
    nameEN: 'Select item',
    nameDE: 'Bitte auswählen...',
    isDefault: false,
    isDeleted: false,

    withTaxationBase: false,
    withAnnex: false,
    withSeparationDivisions: false,
    isControllingCompany: false,
    isControlledCompany: false,
    isKStVV_P8: false,
    isKStVZ_AssessmentYear: false,
    isGewSTMB_WithAddCarryForward: false,
    isPrePayment: false,
    isUSt_AsNotification: false,
    isUSt_Interest: false,
    isUSt_Delayed: false

  };



  constructor(

    public stareInputFieldsService: StareInputFieldsService,
    public listService: ListsService,
    private dataService: DataService,
    public tooltipService: TooltipService,
    private listsService: ListsService,

  ) {

  }
  onDestroy$: Subject<boolean> = new Subject();

  ngOnInit(): void {

    // this.dataForDisplay = [];
    this.class = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';
    this.classToggle = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';

    this.fieldClass = this.isReadOnly ? 'field-disabled' : 'field-enabled';
    this.dataService.formLoaded$.subscribe(formLoaded => {
      if (formLoaded) {
        this.label = this.overrideLabel ? this.overrideLabel : this.stareInputFieldsService.getXbrlLabel(this.xbrlNameDropdown);
        this.dataforDisplayToggle = this.dataOptionsSource;
        this.labelTrue = this.dataforDisplayToggle[0].labelDE;
        this.labelFalse = this.dataforDisplayToggle[1].labelDE;
        this.selection = this.form.controls[this.xbrlNameToggle].value;
        this.loadEntities();
        if (this.form.controls[this.xbrlNameToggle].value == 1) {
          this.currentValue = false;
          //this.form.controls[this.xbrlNameToggle].setValue(this.dataforDisplayToggle[0].value);
        } else {
          this.currentValue = true;
          //this.form.controls[this.xbrlNameToggle].setValue(this.dataforDisplayToggle[1].value);
        }

        this.dataOriginal = [];
        this.listService.GetDocumentNames(<EnumDataTypeStrings>this.documentType).pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
          data.forEach(item => {
            // console.log(item);
            this.dataOriginal.push(this.onDocumentNameParameterChange(item));
            // console.log(item);
          });

          this.prepareDataForDisplay();
          // this.dataForDisplay = this.dataOriginal;
          // this.onChange(this.currentValue);
          if (this.form.controls[this.xbrlNameDropdown].value === "" || this.form.controls[this.xbrlNameDropdown].value == null || this.form.controls[this.xbrlNameDropdown].value == undefined) {
            this.onChange(this.currentValue);
          }
        });

      }
      // }


    });

    if (this.xbrlNameDropdown === 'document_auditResult' || this.xbrlNameDropdown === 'id_notificationName') {
      this.filterable = false;
    }

  }

  ngOnDestroy(): void {
    // this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  public requestedByAdmin(): boolean {
    return this.dataService.requestedByAdmin();
  }
  public isUnlocked(): boolean {
    return this.dataService.GetAdminArchivUnlockFlag();
  }

  public prepareDataForDisplay(): void {
    // console.log(this.dataOriginal[0].baseName);
    if (this.dataOriginal[0].baseName === "USt" || this.dataOriginal[0].baseName === "KSt" || this.dataOriginal[0].baseName === "KSt OT OG" || this.dataOriginal[0].baseName === "GewSt MB" || this.dataOriginal[0].baseName === "GewSt Zerlegung") {
      this.prefilterOptions();
    } else {
      this.dataForDisplay = this.dataOriginal;
    }
    this.defaultItem = this.dataForDisplay.some(x => x.isDefault) ? (this.dataForDisplay.filter(x => x.isDefault))[0] : this.dataForDisplay[0];
    this.dataForDisplay = this.dataForDisplay.filter(x => x != this.defaultItem);
  }

  /** User click on input field --> highlight PDF red rectangle */
  public onFocusInputXbrl(): void {
    //console.log(this.xbrlName);
    //console.log(this.datasetId);
    this.onFieldFocus.emit(document.activeElement);
  }

  // public fillData(): void {
  //   this.dataNumberOptions = this.dataNumberOptionsSource;

  //   this.dataForDisplay = this.dataNumberOptions;

  // }

  // handleFilter(value: string): void {
  //   // this.dataOptions = this.dataOptionsSource.filter(
  //   //   (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   // );
  //   // this.dataForDisplay = this.dataNumberOptionsSource.filter(
  //   //   (s) => s.nameDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   // );
  // }

  /** Triggered when the form fields are clicked on and should be focused on
*/
  public onReferenceDisableReciever(activation: boolean): void {
    this.onReferenceDisable.emit({ datasetId: this.datasetId, xbrl: this.xbrlNameDropdown, activation: activation } as ValidationDeactivationTemporaryItem);
  }

  public onDocumentNameParameterChange(item: TaxNoticeNameItem): TaxNoticeNameItem {

    const output = JSON.parse(JSON.stringify(item));
    // console.log(output);
    if (output.nameDE.includes('{assessmentYear}')) {
      if (this.form.controls['id_assessmentYear'].value) {
        output.nameDE = output.nameDE.replace('{assessmentYear}', this.form.controls['id_assessmentYear'].value);
        output.nameEN = output.nameEN.replace('{assessmentYear}', this.form.controls['id_assessmentYear'].value);
      }
    }
    if (output.nameDE.includes('{legalEntityName}')) {
      if (this.form.controls['id_legalEntityKey'].value) {
        //console.log(this.form.controls['id_legalEntityKey'].value);
        // console.log(this.getlegalentityReferenceNamefromGuid(this.form.controls['id_legalEntityKey'].value));
        output.nameDE = output.nameDE.replace('{legalEntityName}', " " + this.getlegalentityReferenceNamefromGuid(this.form.controls['id_legalEntityKey'].value));
        output.nameEN = output.nameEN.replace('{legalEntityName}', " " + this.getlegalentityReferenceNamefromGuid(this.form.controls['id_legalEntityKey'].value));
      }
    }
    if (output.nameDE.includes('{cutOffDate}')) {
      if (this.form.controls['document_cutOffDate'].value) {
        output.nameDE = output.nameDE.replace('{cutOffDate}', this.form.controls['document_cutOffDate'].value.toLocaleDateString('de-DE'));
        output.nameEN = output.nameEN.replace('{cutOffDate}', this.form.controls['document_cutOffDate'].value.toLocaleDateString('de-DE'));
      }
    }
    if (output.nameDE.includes('{cutOffDateYear}')) {
      if (this.form.controls['document_cutOffDate'].value) {
        output.nameDE = output.nameDE.replace('{cutOffDateYear}', this.form.controls['document_cutOffDate'].value.getFullYear());
        output.nameEN = output.nameEN.replace('{cutOffDateYear}', this.form.controls['document_cutOffDate'].value.getFullYear());
      }
    }
    if (output.nameDE.includes('{legalEntityName}')) {
      if (this.form.controls['document_cutOffDate'].value) {
        output.nameDE = output.nameDE.replace('{legalEntityName}', '');
        output.nameEN = output.nameEN.replace('{legalEntityName}', '');
      }
    }

    this.form.patchValue({
      ['id_notificationName']: this.form.controls['id_notificationName'].value,
    });

    return output;
  }

  prefilterOptions(): void {


    if (this.dataOriginal.length > 0) {
      if (this.dataOriginal[0].baseName === "USt") {
        // Umsatzsteuer item.isUSt_AsNotification
        if (this.selection == 2) {
          this.dataForDisplay = this.dataOriginal.filter(x => x.isUSt_AsNotification);
        } else {
          this.dataForDisplay = this.dataOriginal.filter(x => !x.isUSt_AsNotification);
        }
      } else if (this.dataOriginal[0].baseName === "KSt") {
        // KSt item.withTaxationBase
        if (this.selection == 2) {
          this.dataForDisplay = this.dataOriginal.filter(x => x.withTaxationBase);
        } else {
          this.dataForDisplay = this.dataOriginal.filter(x => !x.withTaxationBase);
        }
      } else if (this.dataOriginal[0].baseName === "KSt OT OG") {
        // KSt OT OG item.isControllingCompany
        if (this.selection == 2) {
          this.dataForDisplay = this.dataOriginal.filter(x => !x.isControllingCompany);
        } else {
          this.dataForDisplay = this.dataOriginal.filter(x => x.isControllingCompany);
        }
      } else if (this.dataOriginal[0].baseName === "GewSt MB" || this.dataOriginal[0].baseName === "GewSt Zerlegung") {
        // GewSt MB und GewSt Zerlegung item.isPrePayment
        if (this.selection == 2) {
          this.dataForDisplay = this.dataOriginal.filter(x => x.isPrePayment);
        } else {
          this.dataForDisplay = this.dataOriginal.filter(x => !x.isPrePayment);
        }
      }
    }
  }

  public onChange(value: boolean): void {
    // console.log(value);
    if (value == false) {
      this.form.controls[this.xbrlNameToggle].setValue(this.dataforDisplayToggle[0].value);
      this.selection = 1;
      this.prefilterOptions();
      this.form.controls[this.xbrlNameDropdown].setValue(this.dataForDisplay[0].id);
    } else {
      this.form.controls[this.xbrlNameToggle].setValue(this.dataforDisplayToggle[1].value);
      this.selection = 2;
      this.prefilterOptions();
      this.form.controls[this.xbrlNameDropdown].setValue(this.dataForDisplay[0].id);
    }

    this.prepareDataForDisplay();
  }

  getlegalentityReferenceNamefromGuid(guid: string): string {
    const legalentityName = this.legalEntities.find(
      (item) => item.value == guid
    );
    if (legalentityName) {
      return legalentityName?.labelDE;
    } else {
      return '';
    }
  }

  public legalEntities: DropDownStringItem[] = [];

  public loadEntities(): void {
    this.listsService.GetLegalEntitiesList().subscribe((data) => {
      // Order data by labelDE. Ignore case and german umlauts

      this.legalEntities = data as DropDownStringItem[];

    });


  }
}
