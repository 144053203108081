<div class="stareTopic">
    {{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.TITLE' | translate }}
</div>



<p class="plainText">{{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.BODY1' | translate: {'mail': getUserMailAddress()} }}

    <button kendoButton (click)="getTemplateFile()" type="button" size="small" style="display: inline;">
        <span class="plainText">{{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.TEMPLATE_NAME' | translate }}</span>
    </button>
    <span class="plainText">{{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.BODY2' | translate }}</span>

</p>

<p>
    <span class="plainText">{{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.UPLOAD.TITLE' | translate }}
    </span>
    <br>
    <span class="plainText">{{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.UPLOAD.INSTRUCTIONS' | translate }}
    </span>
</p>

<kendo-upload [saveUrl]="uploadSaveUrl" saveMethod="POST" [withCredentials]="true" [restrictions]="myRestrictions"
    [autoUpload]="false" (error)="uploadFailed($event)" (success)="uploadSuccess()">
    <kendo-upload-messages [dropFilesHere]="'UPLOADER_MENU.DROP_FILES' | translate"
        [select]="'UPLOADER_MENU.SELECT' | translate" [uploadSelectedFiles]="'UPLOADER_MENU.UPLOAD' | translate"
        [clearSelectedFiles]="'UPLOADER_MENU.CLEAR' | translate"
        [invalidFileExtension]="'UPLOADER_MENU.INVALID_TYPE' | translate"
        [fileStatusFailed]="'UPLOADER_MENU.STATUS_FAILED' | translate"
        [fileStatusUploaded]="'UPLOADER_MENU.STATUS_UPLOADED' | translate"
        [headerStatusUploaded]="'UPLOADER_MENU.HEADER_STATUS_UPLOADED' | translate">
    </kendo-upload-messages>
</kendo-upload>


<br>
<br>
<br>
<div *ngIf="hasValidationErrors">
    <span class="plainText">{{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.ERROR.TITLE' | translate }}
    </span>
    <div class='plainText'>
        {{ 'APP.ADMINISTRATION.IMPORT_COMMUNE.ERROR.DESCRIPTION' | translate }}

        <ul>
            <li *ngFor="let item of validationErrors; let i=index" style="color: red">{{item}}</li>
        </ul>

    </div>
</div>
<br>