import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CellClickEvent, EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { MailMetadataClientItem } from 'app/_models/mail-metadata-client.model';
import { MailMetadataItem } from 'app/_models/mail-metadata.model';
import { ApiLegalentityService } from 'app/_services/StareApi/legalentity.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';

@Component({
  selector: 'app-mailmetadata-client-details',
  templateUrl: './mailmetadata-client-details.component.html',
  styleUrl: './mailmetadata-client-details.component.scss',
  standalone: false
})
export class MailmetadataClientDetailsComponent implements OnInit {

  @Input() public mailmetadata: MailMetadataItem | undefined;

  public clientdetails: MailMetadataClientItem[] = [];
  public finishedLoading: boolean = false;
  public genderList: DropDownListItem[] = [
    { labelDE: '<generische Anrede>', value: 0, labelEN: '<None>', isDefault: true, isDeleted: false },      // See Backend for mapping the gender values
    { labelDE: 'Männlich', value: 1, labelEN: 'Male', isDefault: false, isDeleted: false },
    { labelDE: 'Weiblich', value: 2, labelEN: 'Female', isDefault: false, isDeleted: false },

  ];

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  // ============================================================================

  constructor(
    private apiLegalEntityService: ApiLegalentityService,
    private notificationService: WrapperNotificationService,
    public tooltipService: TooltipService) { }

  public ngOnInit(): void {
    /*load client contacts for the given mailmetadata id*/

    this.loadChildData();
  }


  // == Load Grid Data =======================================================================================================
  loadChildData(): void {
    this.finishedLoading = false;

    /*load client contacts for the given mailmetadata id*/
    if (this.mailmetadata != undefined)
      this.apiLegalEntityService.getMailMetadatClientDetailsById(this.mailmetadata.id).subscribe({
        next: (res: MailMetadataClientItem[]) => {

          // Change gender value to 0 when null
          res.forEach((element) => {
            if (element.gender == null) {
              element.gender = 0;
            }
          });

          this.clientdetails = res;
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
        }
      });
    this.finishedLoading = true;

  }

  // == Modal ============================================================================
  @ViewChild("clientcontactgrid", { static: false })
  clientcontactgrid!: GridComponent;
  /** Show Modal-Dialog. */
  public childActive: boolean = false;
  /** New DataItem will be added. */
  public isChildNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisChildId: number = -1;

  // == Forms ===========================================================================

  // Custom Validator Function
  customValidator(form: AbstractControl): { [key: string]: boolean } | null {
    const gender = form.get('gender')?.value ?? 0;
    const firstname = form.get('firstname')?.value ?? '';
    const lastname = form.get('lastname')?.value ?? '';

    // console.log('Validator check:', { gender, firstname, lastname });

    // Rule 1: If gender == 0, firstname and lastname must be empty (no whitespace allowed)
    if (gender == 0) {
      if (firstname?.trim() !== '' || lastname?.trim() !== '') {
        // console.log('Invalid: firstname or lastname should be empty when gender is 0');
        return { invalidNames: true };
      }
    }

    // Rule 2: If gender > 0, firstname and lastname must contain characters (not only whitespace)
    if (gender > 0) {
      if (firstname?.trim() === '' && lastname?.trim() === '') {
        // console.log('Invalid: firstname and lastname should not be empty when gender is greater than 0');
        return { emptyNames: true };
      }
    }

    // Rule 3: If firstname or lastname contain characters, gender must be > 0
    if (firstname?.trim() !== '' || lastname?.trim() !== '') {
      if (gender <= 0) {
        // console.log('Invalid: gender must be greater than 0 when firstname or lastname contain characters');
        return { invalidGender: true };
      }
    }

    return null;
  }


  public editChildForm: FormGroup = new FormGroup({
    //mailAddress: new FormControl("", [Validators.required, Validators.pattern("^[^@\s]+@[^@\s]+\.[^@\s]+$")]),
    mailAddress: new FormControl("", [Validators.required, Validators.email]),
    firstname: new FormControl(""),
    lastname: new FormControl(""),
    gender: new FormControl("0"),
    useInCc: new FormControl(false),

  }, { validators: this.customValidator });

  // == Handlers =======================================================================
  public editClientContactHandler(args: EditEvent): void {
    // console.log('child edit form event');
    // console.log(args.dataItem);
    this.editChildForm.reset(args.dataItem);
    this.isChildNew = false;
    this.childActive = true;
    this.thisChildId = args.dataItem.id;
    // console.log('child edit form event end');
  }

  public childCellClickHandler(args: CellClickEvent): void {
    args.sender.focus();

  }

  public addClientContactHandler(): void {

    // Reset form fields
    this.editChildForm.reset();
    this.isChildNew = true;
    this.childActive = true;
    this.thisChildId = 0;
  }

  public closeChildForm(): void {
    this.childActive = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeChildForm();
  }

  // == CRUD Operations ======================================================================
  public onChildDelete(e: RemoveEvent): void {
    //e.preventDefault();
    if (e.dataItem?.id == undefined) {
      return;
    }

    this.apiLegalEntityService.deleteMailMetadataClientItem(e.dataItem.id).subscribe({
      next: () => {
        this.notificationService.showSuccess('Der Kontakt wurde erfolgreich gelöscht.');
        this.loadChildData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen eines Kontakts.');
        } else if (err.status == 410) {
          this.notificationService.showError('Der Eintrag wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeChildForm();
  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    if (this.mailmetadata == undefined)
      return;

    if (this.isChildNew && this.mailmetadata != undefined) {

      // Map UserAccessForCreationItem from Form
      let templateForCreationItem = {} as MailMetadataClientItem;

      templateForCreationItem = {
        mailAddress: this.editChildForm.value.mailAddress,
        firstname: this.checkEmptyOrNull(this.editChildForm.value.firstname),
        lastname: this.checkEmptyOrNull(this.editChildForm.value.lastname),
        gender: this.editChildForm.value.gender == 0 ? null : this.editChildForm.value.gender,
        useInCc: this.editChildForm.value.useInCc ?? false,
        mailMetadataId: this.mailmetadata.id
      };

      // Send to API
      this.apiLegalEntityService.addMailMetadataClientItem(templateForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Mandantenkontakt wurde erfolgreich angelegt.');
          this.loadChildData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Der Mandantenkontakt existiert bereits und konnte nicht (erneut) angelegt werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Anlegen eines Mandantenkontakts.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });

    }
    else {
      // Update
      let templateForUpdateItem = {} as MailMetadataClientItem;
      templateForUpdateItem = {
        mailAddress: this.editChildForm.value.mailAddress,
        firstname: this.checkEmptyOrNull(this.editChildForm.value.firstname),
        lastname: this.checkEmptyOrNull(this.editChildForm.value.lastname),
        gender: this.editChildForm.value.gender == 0 ? null : this.editChildForm.value.gender,
        useInCc: this.editChildForm.value.useInCc ?? false,
        mailMetadataId: this.mailmetadata.id
      };



      this.apiLegalEntityService.updateMailMetadataClientItem(this.thisChildId, templateForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Mandantenkontakt wurde erfolgreich aktualisiert.');
          this.loadChildData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Der Mandantenkontakt und konnte nicht aktualisiert werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Aktualisieren eines Mandantenkontakt.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Mandantenkontakt wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });


    }

    this.closeChildForm();
  }


  /** Funktion, die überprüft, ob der Wert null oder eine leere Zeichenkette ist */
  checkEmptyOrNull(value?: string | null): string | null {
    if (value === null || value === '' || value === undefined || (typeof value === 'string' && value.trim() === '')) {
      return null;
    }
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  }

}




