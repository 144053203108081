
import { WbsItem } from "./wbs.model";

export interface WbsSettingItem {
    id: number;
    title: string;
    wbsId: number;
    wbsCustomer: WbsItem;
    dataTypeName: string;

    //not in the backend model
    dataTypeNameDE: string;

    onlyDigitize: boolean;
    sendTaskMail: boolean;
    editorsAllowed: boolean;
    // withDeadlineManagement: boolean;
    legalEntityFromReferenceData: boolean;

    /** NOT IN USE */
    legalEntityFromWbsForCorporateIncomeTax: boolean;

    automaticProcessing: boolean;
    addCalculatedValues: boolean;
    createClientMail: boolean;
    percentageToReview: number | null;
    thresholdItems: IThresholdDto[];
    sendSignOutMail: boolean;
    sendSignOutMailAddress: string | null;

    // from the backend model
    createdDate: Date;
    createdUserId: number;
    updatedDate: Date | null;
    updatedUserId: number | null;
    deletedDate: Date | null;
    deletedUserId: number | null;

    isDeleted: boolean;

}

export interface WbsSettingForCreationItem {
    title: string;
    wbsId: number;
    dataTypeName: string;
    onlyDigitize: boolean;
    sendTaskMail: boolean;
    editorsAllowed: boolean;
    // withDeadlineManagement: boolean;
    legalEntityFromReferenceData: boolean;
    automaticProcessing: boolean;
    addCalculatedValues: boolean;
    createClientMail: boolean;
    percentageToReview: number | null;
    sendSignOutMail: boolean;
    sendSignOutMailAddress: string | null;


    /** NOT IN USE */
    //legalEntityFromWbsForCorporateIncomeTax: boolean;
}

export interface WbsSettingForUpdateItem {
    title: string;
    onlyDigitize: boolean;
    sendTaskMail: boolean;
    editorsAllowed: boolean;
    // withDeadlineManagement: boolean;
    legalEntityFromReferenceData: boolean;
    automaticProcessing: boolean;
    addCalculatedValues: boolean;
    percentageToReview: number | null;
    createClientMail: boolean;
    sendSignOutMail: boolean;
    sendSignOutMailAddress: string | null;

    /** NOT IN USE */
    ///** NOT IN USE */legalEntityFromWbsForCorporateIncomeTax: boolean;
}

export class IThresholdDto {

    id: number = -1;
    dataTypeName: string = "";
    xbrlName: string = "";
    thresholdValue: number = 0;

    /** (1) MaterialityThresholds, (2) ThresholdValues
     * 
     * (1) Wesentlichkeitsgrenze: Abweichung zwischen Ist- und Soll-Wert als absoluten Betrag (d.h. keine Anteilswerte). Pro DatatypeName und pro XBRL.
     * 
     * (2) Schwellenwert: Überschreitung eines Ist-Wert um an angegebenen Wert (als absoluten Betrag)
     */
    typeOfThreshold: number = 1;
}

/** One item of a threshold */
export class IThresholdForCreationDto {
    dataTypeName: string = "";
    xbrlName: string = "";
    thresholdValue: number = 0;
    /** (1) MaterialityThresholds, (2) ThresholdValues
     * 
     * (1) Wesentlichkeitsgrenze: Abweichung zwischen Ist- und Soll-Wert als absoluten Betrag (d.h. keine Anteilswerte). Pro DatatypeName und pro XBRL.
     * 
     * (2) Schwellenwert: Überschreitung eines Ist-Wert um an angegebenen Wert (als absoluten Betrag)
     */
    typeOfThreshold: number = 1;
}

export class IThresholdForCreationDtoList {
    thresholds: IThresholdForCreationDto[] = [];
}
